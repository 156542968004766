import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import { Observer } from "mobx-react-lite";
import { Card, Chip, Grid, Snackbar } from "@material-ui/core";
import { StoreContext } from "../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import { decode } from "html-entities";
import MuiAlert from "@material-ui/lab/Alert";
import DividerWithText from "../../Utils/Divider/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import TextTranslation from "../../Config/TextTranslation";
import Colors from "../../Config/Colors";
import axios from "axios";

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

var persianDigits = "۰۱۲۳۴۵۶۷۸۹";
var persianMap = persianDigits.split("");

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

let grouped;

const UserArticleSelected = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let para = useParams(); // getting params from previous article screen
  const store = useContext(StoreContext);
  const classes = useStyles();
  const [data, setData] = useState({});
  const [category, setCategory] = useState([]); // category state
  const [loader, setLoader] = useState(true); // loader state
  const [isPublic, setIsPublic] = useState(); // public state
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState("");

  //alert close function
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const id = () => {
    // form data
    var GetArticleViewFormData = new FormData();
    GetArticleViewFormData.append("article_title", para.name);
    GetArticleViewFormData.append("article_uid", para.id);

    axios({
      method: "post",
      url: "https://api.minhaj.digital/get_article_data.php",
      data: GetArticleViewFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    })
      .then(function (response) {
        //handle success
        console.log(response.data.MDE_API[0]);
        setData(response.data.MDE_API[0]);
        setCategory(response.data.MDE_API[0].Category);
        setIsPublic(parseInt(response.data.MDE_API[0].Is_Published));
        grouped = groupBy(response.data.MDE_API[0].Data, "topic");
        console.log(grouped);
        setLoader(false);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const groupBy = (arr, criteria) =>
    arr.reduce((obj, item) => {
      // Check if the criteria is a function to run on the item or a property of it
      let key =
        typeof criteria === "function" ? criteria(item) : item[criteria];
      // If the key doesn't exist yet, create it
      if (!obj.hasOwnProperty(key)) obj[key] = [];
      // Push the value to the object
      obj[key].push(item);
      // Return the object to the next item in the loop
      return obj;
    }, {});

  // mark article as public API
  const markArticlePublic = (title) => {
    const pubicArticleForm = new FormData();
    pubicArticleForm.append("article_title", title);
    axios({
      method: "post",
      url: "https://api.minhaj.digital/make_article_public.php",
      data: pubicArticleForm,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    })
      .then((res) => {
        //handle success
        console.log("mark public", res.data);
        id();

        if (res.data.MDE_API[0].success === "1") {
          setOpen(true);
          setSuccess(0);
        } else {
          setOpen(true);
          setSuccess(1);
        }
      })
      .catch((err) => {
        //handle error
        console.log(err);
      });
  };

  // mark article as Unpublic API
  const markArticleUnPublic = (title) => {
    const unPubicArticleForm = new FormData();
    unPubicArticleForm.append("article_title", title);
    axios({
      method: "post",
      url: "https://api.minhaj.digital/make_article_unpublic.php",
      data: unPubicArticleForm,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    })
      .then((res) => {
        //handle success
        console.log("mark Unpublic", res.data);
        id();        
        if (res.data.MDE_API[0].success === "0") {
          setOpen(true);
          setSuccess(1);
        } else {
          setOpen(true);
          setSuccess(0);
        }
      })
      .catch((err) => {
        //handle error
        console.log(err);
      });
  };

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    id();
    return () => ac.abort();
  }, []);

  return (
    <Observer>
      {() => (
        <>
          <CommonNavbar />
          <Grid
            container
            spacing={0}
            justify="center"
            alignItems="center"
            directioin="column"
          >
            {/*  alert Snackbar */}
            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={success === 0 ? "success" : "success"}
              >
                {success === 0 ? "Article is un-public" : "Article is public"}
              </Alert>
            </Snackbar>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              {loader ? (
                <Grid
                  container
                  spacing={0}
                  justify="center"
                  alignItems="center"
                  style={{ minHeight: "100vh" }}
                >
                  <Grid>
                    <CircularProgress
                      className={classes.loader}
                      size={50}
                      thickness={3}
                    />
                  </Grid>
                </Grid>
              ) : (
                <>
                  <div className="mt-3 mb-3">
                    <h6
                      className="mx-3 mt-5"
                      style={
                        store.langIndex == 0
                          ? {
                              color: Colors.primary,
                              fontFamily: "Raleway, sans-serif",
                            }
                          : store.langIndex == 1
                          ? {
                              color: Colors.primary,
                              textAlign: "right",
                              fontFamily: "JameelNoori",
                              fontSize: 22,
                            }
                          : {
                              color: Colors.primary,
                              textAlign: "right",
                              fontFamily: "Arabic",
                              fontSize: 22,
                            }
                      }
                    >
                      {TextTranslation.subject[store.langIndex]}
                      <span
                        className="mx-2"
                        style={
                          store.langIndex == 0
                            ? {
                                color: Colors.black,
                                fontFamily: /[a-zA-Z]/g.test(data.Title)
                                  ? "Raleway,sans-serif"
                                  : "JameelNoori",
                              }
                            : store.langIndex == 1
                            ? {
                                color: Colors.black,
                                textAlign: "right",
                                fontFamily: /[a-zA-Z]/g.test(data.Title)
                                  ? "Raleway,sans-serif"
                                  : "JameelNoori",
                                fontSize: 20,
                              }
                            : {
                                color: Colors.black,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 20,
                              }
                        }
                      >
                        &nbsp;{data.Title}
                      </span>
                    </h6>
                    <div
                      className="d-flex p-3"
                      style={
                        store.langIndex == 0
                          ? { direction: "ltr" }
                          : { direction: "rtl" }
                      }
                    >
                      {category ? (
                        <>
                          <h6
                            className="mx-3 pt-1"
                            style={
                              store.langIndex == 0
                                ? {
                                    color: Colors.primary,
                                    fontFamily: "Raleway, sans-serif",
                                  }
                                : store.langIndex == 1
                                ? {
                                    color: Colors.primary,
                                    textAlign: "right",
                                    fontFamily: "JameelNoori",
                                    fontSize: 22,
                                  }
                                : {
                                    color: Colors.primary,
                                    textAlign: "right",
                                    fontFamily: "Arabic",
                                    fontSize: 22,
                                  }
                            }
                          >
                            {TextTranslation.categoryList[store.langIndex]}
                          </h6>
                          {category.map((data, index) => (
                            <Chip
                              className="mx-1"
                              key={index}
                              label={
                                store.langIndex == 0 ? (
                                  <span
                                    style={{
                                      fontFamily: "Raleway, sans-serif",
                                    }}
                                  >
                                    {data.cat_eng}
                                  </span>
                                ) : store.langIndex == 1 ? (
                                  <span
                                    style={{
                                      fontFamily: "JameelNoori",
                                      fontSize: 18,
                                    }}
                                  >
                                    {data.cat_ur}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      fontFamily: "Arabic",
                                    }}
                                  >
                                    {data.cat_ar}
                                  </span>
                                )
                              }
                            />
                          ))}
                        </>
                      ) : null}
                    </div>

                    <div>
                      <Card
                        style={{
                          boxShadow: "1px 2px 10px 0 #e32636",
                          marginTop: "1%",
                        }}
                      >
                        {Object.keys(grouped).map((x, index) => (
                          <div key={index}>
                            <h5
                              className="text-center mt-3 mx-2"
                              style={
                                store.langIndex == 0
                                  ? {
                                      color: Colors.primary,
                                      fontFamily: /[a-zA-Z]/g.test(x)
                                        ? "Raleway,sans-serif"
                                        : "JameelNoori",
                                    }
                                  : store.langIndex == 1
                                  ? {
                                      color: Colors.primary,
                                      fontSize: 24,
                                      fontFamily: /[a-zA-Z]/g.test(x)
                                        ? "Raleway,sans-serif"
                                        : "JameelNoori",
                                    }
                                  : {
                                      color: Colors.primary,
                                      fontSize: 24,
                                      fontFamily: "Arabic",
                                    }
                              }
                            >
                              {x}
                            </h5>

                            <div>
                              {grouped[x].map((y, index) => (
                                <div style={{ marginBottom: 4 }} key={index}>
                                  <div
                                    className="pt-4 mt-4 mx-2"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row-reverse",
                                    }}
                                  >
                                    <h4
                                      className="text-right mx-3 mt-3 mb-3"
                                      style={{
                                        fontFamily: "AlQalam",
                                        lineHeight: "40px",
                                      }}
                                    >
                                      {decode(y.ar_text_erab)
                                        .replace(
                                          "<img src=https://minhaj.digital/beta.version/en/images/waqf-lazim_mim_sm.png>",
                                          decode("&#x6D8")
                                        )
                                        .replace(
                                          "<img src=http://www.irfan-ul-quran.com/quran/images/waqf-lazim_mim_sm.png>",
                                          decode("&#x6D8")
                                        )}
                                      &nbsp;
                                      <span
                                        style={{
                                          fontFamily: "AlQalam1",
                                        }}
                                      >
                                        {decode("&#1757;") + y.ayat_no
                                          .toString()
                                          .replace(/\d/g, function (m) {
                                            return persianMap[parseInt(m)];
                                          })
                                          }
                                      </span>
                                    </h4>
                                  </div>

                                  <h6
                                    className="mx-2 px-2"
                                    style={
                                      store.langIndex == 0
                                        ? {
                                            textAlign: /[a-zA-Z]/g.test(
                                              y.translation
                                            )
                                              ? "left"
                                              : "right",
                                            fontFamily: /[a-zA-Z]/g.test(
                                              y.translation
                                            )
                                              ? "Raleway,sans-serif"
                                              : "JameelNoori",
                                          }
                                        : {
                                            textAlign: /[a-zA-Z]/g.test(
                                              y.translation
                                            )
                                              ? "left"
                                              : "right",

                                            fontFamily: /[a-zA-Z]/g.test(
                                              y.translation
                                            )
                                              ? "Raleway,sans-serif"
                                              : "JameelNoori",
                                            lineHeight: /[a-zA-Z]/g.test(
                                              y.translation
                                            )
                                              ? "30px"
                                              : "40px",

                                            fontSize: /[a-zA-Z]/g.test(
                                              y.translation
                                            )
                                              ? 16
                                              : 20,
                                          }
                                    }
                                  >
                                    {y.translation}
                                  </h6>

                                  <DividerWithText>
                                    <span
                                      style={{
                                        fontFamily: "Raleway,sans-serif",
                                        fontSize: 14,
                                      }}
                                    >
                                      {y.ayat_no} :&nbsp;{y.sura_no} ،&nbsp;
                                      {y.sura_arname}
                                    </span>
                                  </DividerWithText>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </Card>
                    </div>

                    <div
                      className="pt-1 mt-1 pb-1 mb-1 d-flex"
                      style={
                        store.langIndex == 0
                          ? { direction: "ltr" }
                          : { direction: "rtl", textAlign: "right" }
                      }
                    >
                      <p
                        style={
                          store.langIndex == 0
                            ? {
                                fontFamily: "Raleway, sans-serif",
                                color: Colors.primary,
                              }
                            : store.langIndex == 1
                            ? {
                                fontFamily: "JameelNoori",
                                color: Colors.primary,
                                fontSize: 22,
                              }
                            : {
                                fontFamily: "Arabic",
                                color: Colors.primary,
                                fontSize: 22,
                              }
                        }
                      >
                        {TextTranslation.remark[store.langIndex]}
                      </p>

                      <p
                        className="mx-3"
                        style={
                          store.langIndex == 0
                            ? {
                                fontFamily: "Raleway, sans-serif",
                                color: Colors.black,
                              }
                            : store.langIndex == 1
                            ? {
                                fontFamily: "JameelNoori",
                                color: Colors.black,
                                fontSize: 20,
                              }
                            : {
                                fontFamily: "Arabic",
                                color: Colors.black,
                                fontSize: 20,
                              }
                        }
                      >
                        {data.Remarks}
                      </p>
                    </div>

                    <div
                      className="mt-3"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {isPublic == 0 ? (
                        <div
                          style={{
                            margin: "auto",
                            marginTop: "1%",
                            backgroundColor: Colors.black,
                            color: Colors.white,
                            width: "170px",
                            height: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => markArticlePublic(data.Title)}
                        >
                          <span
                            style={
                              store.langIndex == 0
                                ? {
                                    fontFamily: "Raleway, sans-serif",
                                    fontSize: 14,
                                  }
                                : store.langIndex == 1
                                ? {
                                    fontFamily: "JameelNoori",
                                    fontSize: 18,
                                  }
                                : { fontFamily: "Arabic", fontSize: 18 }
                            }
                          >
                            {" "}
                            {TextTranslation.mark_pub[store.langIndex]}
                          </span>
                        </div>
                      ) : (
                        <div
                          style={{
                            margin: "auto",
                            marginTop: "1%",
                            backgroundColor: Colors.black,
                            color: Colors.white,
                            width: "170px",
                            height: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          onClick={() => markArticleUnPublic(data.Title)}
                        >
                          <span
                            style={
                              store.langIndex == 0
                                ? {
                                    fontFamily: "Raleway, sans-serif",
                                    fontSize: 14,
                                  }
                                : store.langIndex == 1
                                ? {
                                    fontFamily: "JameelNoori",
                                    fontSize: 18,
                                  }
                                : { fontFamily: "Arabic", fontSize: 18 }
                            }
                          >
                            {TextTranslation.mark_unpub[store.langIndex]}
                          </span>
                        </div>
                      )}

                      <div
                        style={{
                          margin: "auto",
                          marginTop: "1%",
                          backgroundColor: Colors.green,
                          color: Colors.white,
                          width: "170px",
                          height: "50px",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={
                            store.langIndex == 0
                              ? {
                                  fontFamily: "Raleway, sans-serif",
                                  fontSize: 14,
                                }
                              : store.langIndex == 1
                              ? {
                                  fontFamily: "JameelNoori",

                                  fontSize: 18,
                                }
                              : { fontFamily: "Arabic", fontSize: 18 }
                          }
                        >
                          {TextTranslation.print_save[store.langIndex]}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Observer>
  );
};

export default UserArticleSelected;
