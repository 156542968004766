import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors";
import { Observer } from "mobx-react-lite";
import { StoreContext } from "../../store/store";
import { Grid, Menu } from "@material-ui/core";
import Colors from "../../Config/Colors";
import Avatar from "@material-ui/core/Avatar";
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import TextTranslation from "../../Config/TextTranslation";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuIcon from "@material-ui/icons/Menu";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import HistoryIcon from "@material-ui/icons/History";
import PostAddIcon from "@material-ui/icons/PostAdd";
import PublicIcon from "@material-ui/icons/Public";
import axios from "axios";
import "./MainNavbar.css";
import Url from "../../Config/Url";


const useStyles = makeStyles((theme) => ({
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: Colors.primary,
    textTransform: "capitalize",
    fontFamily: "Raleway, sans-serif",
  },
  loader: {
    color: Colors.primary,
  },
}));

const MainNavbar = () => {
  const currentLocation = useLocation();

  const history = useHistory();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [loader, setLoader] = useState(true);
  const getParamUrl = useParams();
  const [value, setValue] = useState(
    getParamUrl.initial ? getParamUrl.initial : "Quran"
  );
  const [selectedvalue, setselectedValue] = useState(
    getParamUrl.selectedSearch ? getParamUrl.selectedSearch : "205"
  );


  const id = localStorage.getItem("@uuid");
  store.setId(id);

  console.log('getParamUrl', getParamUrl.selectedSearch);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cellno, setCellNo] = useState("");
  const [bookmarks, setBookmarks] = useState();
  const [articles, setArticles] = useState();
  const [searchhistory, setSearchHistory] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menu, setMenu] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const searchbar = () => {
    store.setSmallSearch(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // user info API
  const user_info = () => {
    axios({
      method: "post",
      url: `${Url.link}/get_userinfo.php?`,
      headers: { "Content-Type": "multipart/form-data", uuid: store.id },
    }).then((res) => {
      setName(res.data.MDE_API[0].MSG[0].user_name);
      setEmail(res.data.MDE_API[0].MSG[0].user_email);
      setCellNo(res.data.MDE_API[0].MSG[0].user_cellno);

      setLoader(false);
    });
  };
  // bookmark info API
  const bookmarks_info = () => {
    axios({
      method: "post",
      url: `${Url.link}/countall_bookmarks.php?`,
      headers: { "Content-Type": "multipart/form-data", uuid: store.id },
    }).then((res) => {
      setBookmarks(res.data.MDE_API[0].MSG[0]);
    });
  };

  // articles info API
  const articles_info = () => {
    axios({
      method: "post",
      url: `${Url.link}/count_articles.php?`,
      headers: { "Content-Type": "multipart/form-data", uuid: store.id },
    }).then((res) => {
      setArticles(res.data.MDE_API[0].MSG[0]);
    });
  };

  // articles info API
  const history_info = () => {
    axios({
      method: "post",
      url: `${Url.link}/count_history.php?`,
      headers: { "Content-Type": "multipart/form-data", uuid: store.id },
    }).then((res) => {
      setSearchHistory(res.data.MDE_API[0].MSG[0]);
    });
  };
  const toggleMenu = () => {
    console.log(menu);
    if (menu == false) {
      setMenu(true)
    } else if (menu == true) {
      setMenu(false)
    }

  }

  useEffect(() => {

    const ac = new AbortController();
    user_info();
    bookmarks_info();
    articles_info();
    history_info();
    return () => ac.abort();
  }, []);

  return (

    <Observer>
      {() => (
        <div className="header col h-100 p-0" >
          {store.id ? (
            <>

              <div className="h-100 d-flex w-100" >
                <div className="navbar-header col mr-4 d-block d-md-none pt-2">
                  <button type="button" className="navbar-toggle collapsed border-0" onClick={toggleMenu}>
                    <i class="fa fa-bars" aria-hidden="true"></i>
                  </button>
                </div>
                <div className="d-none d-md-flex">
                  <div className="mt-auto text-center mb-auto" style={{ padding: "inherit" }} >
                    <Link
                      to="/features"
                      className={(classes.link, "headlink-padding")}
                      style={{
                        color:
                          currentLocation.pathname == "/features"
                            ? "red" :
                            "none",
                        fontFamily:
                          store.langIndex == 0
                            ? "Raleway, sans-serif"
                            : store.langIndex == 1
                              ? "JameelNoori"
                              : "Arabic",

                        fontSize:
                          store.langIndex == 0
                            ? 19
                            : store.langIndex == 1
                              ? 17
                              : 16,
                      }}
                    >
                      {' ' + TextTranslation.features[store.langIndex] + '  '}
                    </Link>
                  </div>

                  <div className=" mt-auto text-center mb-auto">
                    <Link
                      to="/aboutus"
                      className={(classes.link, "headlink-padding")}
                      style={{
                        color:
                          currentLocation.pathname == "/features"
                            ? "red" :
                            "none",
                        fontFamily:
                          store.langIndex == 0
                            ? "Raleway, sans-serif"
                            : store.langIndex == 1
                              ? "JameelNoori"
                              : "Arabic",
                        fontSize:
                          store.langIndex == 0
                            ? 19
                            : store.langIndex == 1
                              ? 17
                              : 16,
                      }}
                    >
                      {' ' + TextTranslation.about_us[store.langIndex] + ' '}
                    </Link>
                  </div> <div className="mt-auto text-center mb-auto" style={{ padding: "inherit" }} >
                    <Link
                      to="/aboutauthor"
                      className={(classes.link, "headlink-padding")}
                      style={{
                        color:
                          currentLocation.pathname == "/features"
                            ? "red" :
                            "none",
                        fontFamily:
                          store.langIndex == 0
                            ? "Raleway, sans-serif"
                            : store.langIndex == 1
                              ? "JameelNoori"
                              : "Arabic",

                        fontSize:
                          store.langIndex == 0
                            ? 19
                            : store.langIndex == 1
                              ? 17
                              : 16,
                      }}
                    >
                      {' ' + TextTranslation.about_author[store.langIndex] + ' '}
                    </Link>
                  </div><div className=" mt-auto text-center mb-auto">
                    <Link
                      to="/aboutencyclopedia"
                      className={(classes.link, "headlink-padding")}

                      style={{
                        color:
                          currentLocation.pathname == "/features"
                            ? "red" :
                            "none",
                        fontFamily:
                          store.langIndex == 0
                            ? "Raleway, sans-serif"
                            : store.langIndex == 1
                              ? "JameelNoori"
                              : "Arabic",
                        fontSize:
                          store.langIndex == 0
                            ? 19
                            : store.langIndex == 1
                              ? 17
                              : 16,
                      }}
                    >
                      {' ' + TextTranslation.about_quranic_encyclopedia[store.langIndex] + ' '}
                    </Link>
                  </div>

                  <div className="mt-auto text-center mb-auto" style={{ padding: "inherit" }} >
                    <Link
                      to="/table"
                      className={(classes.link, "headlink-padding")}
                      style={{
                        color:
                          currentLocation.pathname == "/features"
                            ? "red" :
                            "none",
                        fontFamily:
                          store.langIndex == 0
                            ? "Raleway, sans-serif"
                            : store.langIndex == 1
                              ? "JameelNoori"
                              : "Arabic",

                        fontSize:
                          store.langIndex == 0
                            ? 19
                            : store.langIndex == 1
                              ? 17
                              : 16,
                      }}
                    >
                      {' ' + TextTranslation.quranic_encyclopedia[store.langIndex] + ''}
                    </Link>
                  </div>
                  <div className=" mt-auto text-center mb-auto">
                    <Link
                      to="/contactus"
                      className={(classes.link, "headlink-padding")}

                      style={{
                        color:
                          currentLocation.pathname == "/features"
                            ? "red" :
                            "none",
                        fontFamily:
                          store.langIndex == 0
                            ? "Raleway, sans-serif"
                            : store.langIndex == 1
                              ? "JameelNoori"
                              : "Arabic",
                        fontSize:
                          store.langIndex == 0
                            ? 19
                            : store.langIndex == 1
                              ? 17
                              : 16,
                      }}
                    >
                      {' ' + TextTranslation.suggestion_contact_us[store.langIndex] + ' '}
                    </Link>
                  </div>
                </div>
                {(getParamUrl.initial == 'Quran') || (getParamUrl.selectedSearch == '205') ? (
                  <div
                    className="icon col d-md-none d-block"
                    style={{ color: Colors.primary }}
                    onClick={searchbar}
                  >
                    <i className="fas fa-search fa-sm mx-1" />
                  </div>
                ) : (
                  ""
                )}

                <div className="col p-0 d-flex flex-row justify-content-end m-auto pr-2"  >
                  <div className="mt-auto p-0 ">
                    <div className="d-flex float-right">
                      <div
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MenuIcon
                          style={{
                            color: Colors.primary,
                            cursor: "pointer",
                            fontSize: 30,
                            marginBottom: 5,
                          }}
                        />
                      </div>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        PaperProps={{
                          style: { width: "300px", marginTop: 20 },
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >

                          <Grid
                            item
                            xs={12}
                            lg={10}
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      > */}
                            {/* <Link to="/userarticle">
                          <PostAddIcon style={{ fontSize: 35 }} />
                        </Link> */}
                            {/* {store.langIndex == 0 ? (
                          // <div
                          //   id="menu-name"
                          //   style={{ fontFamily: "Raleway, sans-serif" }}
                          // >
                          //   {TextTranslation.f_articles[store.langIndex]}
                          // </div>
                        ) : store.langIndex == 1 ? (
                          // <div
                          //   id="menu-name"
                          //   style={{ fontFamily: "JameelNoori" }}
                          // >
                          //   {TextTranslation.f_articles[store.langIndex]}
                          // </div>
                        ) : (
                          // <div id="menu-name" style={{ fontFamily: "Arabic" }}>
                          //   {TextTranslation.f_articles[store.langIndex]}
                          // </div>
                        )} */}
                            {/* </div> */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Link to="/userbookmarks">
                                <BookmarksIcon style={{ fontSize: 35 }} />
                              </Link>
                              {store.langIndex == 0 ? (
                                <div
                                  id="menu-name"
                                  style={{ fontFamily: "Raleway, sans-serif" }}
                                >
                                  {TextTranslation.f_bookmarks[store.langIndex]}
                                </div>
                              ) : store.langIndex == 1 ? (
                                <div
                                  id="menu-name"
                                  style={{ fontFamily: "JameelNoori" }}
                                >
                                  {TextTranslation.f_bookmarks[store.langIndex]}
                                </div>
                              ) : (
                                <div id="menu-name" style={{ fontFamily: "Arabic" }}>
                                  {TextTranslation.f_bookmarks[store.langIndex]}
                                </div>
                              )}
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            lg={10}
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Link to="/publicarticletitle">
                          <PublicIcon
                            style={{
                              fontSize: 35,
                              marginRight: 8,
                            }}
                          />
                        </Link>
                        {store.langIndex == 0 ? (
                          <div
                            id="menu-name"
                            style={{ fontFamily: "Raleway, sans-serif" }}
                          >
                            {TextTranslation.f_publicarticles[store.langIndex]}
                          </div>
                        ) : store.langIndex == 1 ? (
                          <div
                            id="menu-name"
                            style={{ fontFamily: "JameelNoori" }}
                          >
                            {TextTranslation.f_publicarticles[store.langIndex]}
                          </div>
                        ) : (
                          <div id="menu-name" style={{ fontFamily: "Arabic" }}>
                            {TextTranslation.f_publicarticles[store.langIndex]}
                          </div>
                        )}
                      </div> */}

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Link to="/userhistory">
                                <HistoryIcon
                                  style={{ fontSize: 35, marginRight: 8 }}
                                />
                              </Link>
                              {store.langIndex == 0 ? (
                                <div
                                  id="menu-name"
                                  style={{ fontFamily: "Raleway, sans-serif" }}
                                >
                                  {TextTranslation.f_searchhistory[store.langIndex]}
                                </div>
                              ) : store.langIndex == 1 ? (
                                <div
                                  id="menu-name"
                                  style={{ fontFamily: "JameelNoori" }}
                                >
                                  {TextTranslation.f_searchhistory[store.langIndex]}
                                </div>
                              ) : (
                                <div id="menu-name" style={{ fontFamily: "Arabic" }}>
                                  {TextTranslation.f_searchhistory[store.langIndex]}
                                </div>
                              )}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            lg={10}
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <a
                                href="https://bftechlab.net/lab.minhaj.digital/en/view/index.php"
                                target="_blank"
                              >
                                <i className="fa fa-book" style={{ fontSize: 30 }} />
                              </a>
                              {store.langIndex == 0 ? (
                                <div
                                  id="menu-name"
                                  style={{ fontFamily: "Raleway, sans-serif" }}
                                >
                                  {
                                    TextTranslation.quranic_encyclopedia[
                                    store.langIndex
                                    ]
                                  }
                                </div>
                              ) : store.langIndex == 1 ? (
                                <div
                                  id="menu-name"
                                  style={{ fontFamily: "JameelNoori" }}
                                >
                                  {
                                    TextTranslation.quranic_encyclopedia[
                                    store.langIndex
                                    ]
                                  }
                                </div>
                              ) : (
                                <div id="menu-name" style={{ fontFamily: "Arabic" }}>
                                  {
                                    TextTranslation.quranic_encyclopedia[
                                    store.langIndex
                                    ]
                                  }
                                </div>
                              )}
                            </div>
                            {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Link to="/newatricle">
                          <i className="fas fa-edit" style={{ fontSize: 30 }} />
                        </Link>
                        {store.langIndex == 0 ? (
                          <div
                            id="menu-name"
                            style={{ fontFamily: "Raleway, sans-serif" }}
                          >
                            {TextTranslation.create_article[store.langIndex]}
                          </div>
                        ) : store.langIndex == 1 ? (
                          <div
                            id="menu-name"
                            style={{ fontFamily: "JameelNoori" }}
                          >
                            {TextTranslation.create_article[store.langIndex]}
                          </div>
                        ) : (
                          <div id="menu-name" style={{ fontFamily: "Arabic" }}>
                            {TextTranslation.create_article[store.langIndex]}
                          </div>
                        )}
                      </div> */}
                          </Grid>
                        </Grid>
                      </Menu>
                    </div>
                  </div>
                  {/* login user stats  */}
                  <div className="mt-auto p-0">
                    <div className="menuItems float-right">
                      <Avatar className={classes.orange}>
                        {/* {name ? name.charAt(0) : "M"} */}
                        <PersonSharpIcon />
                      </Avatar>
                      <div className="login-dropdown-content">
                        <div className="d-flex justify-content-center">
                          <Avatar className={classes.orange}>
                            {/* {name ? name.charAt(0) : "M"} */}
                            <PersonSharpIcon />
                          </Avatar>
                        </div>
                        <div
                          className="d-flex justify-content-center mt-1"
                          style={{ fontFamily: "Raleway,sans-serif", fontSize: 14 }}
                        >
                          {name}
                        </div>
                        <div
                          className="d-flex justify-content-center mt-1"
                          style={{ fontFamily: "Raleway,sans-serif", fontSize: 14 }}
                        >
                          {email}
                        </div>
                        <div
                          className="d-flex justify-content-center mt-1"
                          style={{ fontFamily: "Raleway,sans-serif", fontSize: 14 }}
                        >
                          {cellno}
                        </div>

                        <div className="d-flex justify-content-center mt-3">
                          <div
                            className="btn"
                            style={{
                              backgroundColor: Colors.primary,
                              color: Colors.white,
                              padding: "2px 16px 6px 16px",
                            }}
                          >
                            {store.langIndex == 0 ? (
                              <span
                                style={{
                                  fontFamily: "Raleway,sans-serif",
                                  fontSize: "14px",
                                  letterSpacing: "1px",
                                  cursor: "default",
                                }}
                              >
                                {TextTranslation.account_stat[store.langIndex]}
                              </span>
                            ) : store.langIndex == 1 ? (
                              <span
                                style={{
                                  fontFamily: "JameelNoori",
                                  fontSize: 14,
                                  cursor: "default",
                                }}
                              >
                                {TextTranslation.account_stat[store.langIndex]}
                              </span>
                            ) : (
                              <span
                                style={{
                                  fontFamily: "Arabic",
                                  cursor: "default",
                                  fontSize: 14,
                                }}
                              >
                                {TextTranslation.account_stat[store.langIndex]}
                              </span>
                            )}
                          </div>
                        </div>
                        {!loader ? (
                          <div
                            className="mt-3"
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <div
                              id="circular"
                              onClick={() => history.push("/userbookmarks")}
                            >
                              <div id="circular-stat">
                                {bookmarks}
                              </div>
                              {store.langIndex == 0 ? (
                                <div
                                  className="text-center mt-4 pt-1"
                                  style={{
                                    fontFamily: "Raleway,sans-serif",
                                    fontSize: 12,
                                  }}
                                >
                                  {TextTranslation.f_bookmarks[store.langIndex]}
                                </div>
                              ) : store.langIndex == 1 ? (
                                <div
                                  className="text-center mt-4  pt-1"
                                  style={{ fontFamily: "JameelNoori", fontSize: 16 }}
                                >
                                  {TextTranslation.f_bookmarks[store.langIndex]}
                                </div>
                              ) : (
                                <div
                                  className="text-center mt-4  pt-1"
                                  style={{ fontFamily: "Arabic", fontSize: 12 }}
                                >
                                  {TextTranslation.f_bookmarks[store.langIndex]}
                                </div>
                              )}
                            </div>
                            {/* <div
                        id="circular"
                        onClick={() => history.push("/userarticle")}
                      >
                        <div id="circular-stat">{articles}</div>
                        {store.langIndex == 0 ? (
                          <div
                            className="text-center mt-4  pt-1"
                            style={{
                              fontFamily: "Raleway,sans-serif",
                              fontSize: 12,
                            }}
                          >
                            {TextTranslation.f_articles[store.langIndex]}
                          </div>
                        ) : store.langIndex == 1 ? (
                          <div
                            className="text-center mt-4  pt-1"
                            style={{ fontFamily: "JameelNoori", fontSize: 16 }}
                          >
                            {TextTranslation.f_articles[store.langIndex]}
                          </div>
                        ) : (
                          <div
                            className="text-center mt-4  pt-1"
                            style={{ fontFamily: "Arabic", fontSize: 12 }}
                          >
                            {TextTranslation.f_articles[store.langIndex]}
                          </div>
                        )}
                      </div> */}
                            <div
                              id="circular"
                              onClick={() => history.push("/userhistory")}
                            >
                              <div id="circular-stat">{searchhistory}</div>
                              {store.langIndex == 0 ? (
                                <div
                                  className="text-center mt-4 pt-1"
                                  style={{
                                    fontFamily: "Raleway,sans-serif",
                                    fontSize: 12,
                                  }}
                                >
                                  {TextTranslation.f_searchhistory[store.langIndex]}
                                </div>
                              ) : store.langIndex == 1 ? (
                                <div
                                  className="text-center mt-4 pt-1"
                                  style={{ fontFamily: "JameelNoori", fontSize: 16 }}
                                >
                                  {TextTranslation.f_searchhistory[store.langIndex]}
                                </div>
                              ) : (
                                <div
                                  className="text-center mt-4 pt-1 "
                                  style={{ fontFamily: "Arabic", fontSize: 12 }}
                                >
                                  {TextTranslation.f_searchhistory[store.langIndex]}
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <Grid
                            container
                            spacing={0}
                            justify="center"
                            alignItems="center"
                            direction="column"
                            style={{ minHeight: "10vh" }}
                          >
                            <Grid item>
                              <CircularProgress
                                className={classes.loader}
                                size={30}
                                thickness={2}
                              />
                            </Grid>
                          </Grid>
                        )}

                        {/* edit profile button */}

                        <div className="d-flex justify-content-center mt-5">
                          <div
                            onClick={() => history.push("/editprofile")}
                            className="btn"
                            style={{
                              backgroundColor: Colors.primary,
                              color: Colors.white,
                              padding: "2px 16px 6px 16px",
                            }}
                          >
                            {store.langIndex == 0 ? (
                              <span
                                style={{
                                  fontFamily: "Raleway,sans-serif",
                                  fontSize: "14px",
                                  letterSpacing: "1px",
                                }}
                              >
                                {TextTranslation.edit_profile[store.langIndex]}
                              </span>
                            ) : store.langIndex == 1 ? (
                              <span
                                style={{
                                  fontFamily: "JameelNoori",
                                  fontSize: 14,
                                }}
                              >
                                {TextTranslation.edit_profile[store.langIndex]}
                              </span>
                            ) : (
                              <div
                                style={{
                                  fontFamily: "Arabic",
                                  fontSize: 14,
                                }}
                              >
                                {TextTranslation.edit_profile[store.langIndex]}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* logout button */}
                        <div className="d-flex justify-content-center mt-2">
                          <div
                            onClick={() => {
                              localStorage.removeItem("@uuid");
                              store.setId(null);
                            }}
                            className="btn"
                            style={{
                              backgroundColor: Colors.primary,
                              color: Colors.white,
                              padding: "2px 16px 6px 16px",
                            }}
                          >
                            {store.langIndex == 0 ? (
                              <span
                                style={{
                                  fontFamily: "Raleway,sans-serif",
                                  fontSize: "14px",
                                  letterSpacing: "1px",
                                }}
                              >
                                {TextTranslation.f_logout[store.langIndex]}
                              </span>
                            ) : store.langIndex == 1 ? (
                              <span
                                onClick={() => {
                                  localStorage.removeItem("@uuid");
                                  store.setId(null);
                                }}
                                style={{
                                  fontFamily: "JameelNoori",
                                  fontSize: 14,
                                }}
                              >
                                {TextTranslation.f_logout[store.langIndex]}
                              </span>
                            ) : (
                              <span
                                onClick={() => {
                                  localStorage.removeItem("@uuid");
                                  store.setId(null);
                                }}
                                style={{
                                  fontFamily: "Arabic",
                                  fontSize: 14,
                                }}
                              >
                                {TextTranslation.f_logout[store.langIndex]}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="d-block d-sm-none">
                <nav className="navbar navbar-inverse navbar-static-top p-0" role="navigation">
                  <div className="container p-0">


                    <div className={"collapse navbar-collapse " + (menu ? "show" : "")} >
                      <ul className="nav navbar-nav text-center">
                        <li>
                          <Link
                            to="/features"
                            className={classes.link}
                            style={{
                              color:
                                currentLocation.pathname == "/features"
                                  ? " red" :
                                  "none",
                              fontFamily:
                                store.langIndex == 0
                                  ? "Raleway, sans-serif"
                                  : store.langIndex == 1
                                    ? "JameelNoori"
                                    : "Arabic",

                              fontSize:
                                store.langIndex == 0
                                  ? 19
                                  : store.langIndex == 1
                                    ? 17
                                    : 16,
                            }}
                          >
                            {' ' + TextTranslation.features[store.langIndex] + ' '}
                          </Link>
                          <hr className="m-1" />
                        </li>

                        <li>
                          <Link
                            to="/aboutus"
                            className={classes.link}
                            style={{
                              color:
                                currentLocation.pathname == "/aboutus"
                                  ? "red" :
                                  "none",
                              fontFamily:
                                store.langIndex == 0
                                  ? "Raleway, sans-serif"
                                  : store.langIndex == 1
                                    ? "JameelNoori"
                                    : "Arabic",
                              fontSize:
                                store.langIndex == 0
                                  ? 19
                                  : store.langIndex == 1
                                    ? 17
                                    : 16,
                            }}
                          >
                            {' ' + TextTranslation.about_us[store.langIndex] + ' '}
                          </Link>
                          <hr className="m-1" />
                        </li>
                        <li>
                          <Link
                            to="/aboutencyclopedia"
                            className={classes.link}
                            style={{
                              color:
                                currentLocation.pathname == "/aboutencyclopedia"
                                  ? "red" :
                                  "none",
                              fontFamily:
                                store.langIndex == 0
                                  ? "Raleway, sans-serif"
                                  : store.langIndex == 1
                                    ? "JameelNoori"
                                    : "Arabic",
                              fontSize:
                                store.langIndex == 0
                                  ? 19
                                  : store.langIndex == 1
                                    ? 17
                                    : 16,
                            }}
                          >
                            {' ' + TextTranslation.about_quranic_encyclopedia[store.langIndex] + ' '}
                          </Link>
                          <hr className="m-1" />
                        </li>

                        <li>
                          <Link
                            to="/table"
                            className={classes.link}

                            style={{
                              color:
                                currentLocation.pathname == "/table"
                                  ? "red" :
                                  "none",
                              fontFamily:
                                store.langIndex == 0
                                  ? "Raleway, sans-serif"
                                  : store.langIndex == 1
                                    ? "JameelNoori"
                                    : "Arabic",
                              fontSize:
                                store.langIndex == 0
                                  ? 19
                                  : store.langIndex == 1
                                    ? 17
                                    : 16,
                            }}
                          >
                            {' ' + TextTranslation.quranic_encyclopedia[store.langIndex] + ' '}
                          </Link>
                          <hr className="m-1" />
                        </li>
                        <li>
                          <Link
                            to="/contactus"
                            className={classes.link}

                            style={{
                              color:
                                currentLocation.pathname == "/contactus"
                                  ? "red" :
                                  "none",
                              fontFamily:
                                store.langIndex == 0
                                  ? "Raleway, sans-serif"
                                  : store.langIndex == 1
                                    ? "JameelNoori"
                                    : "Arabic",
                              fontSize:
                                store.langIndex == 0
                                  ? 19
                                  : store.langIndex == 1
                                    ? 17
                                    : 16,
                            }}
                          >
                            {' ' + TextTranslation.suggestion_contact_us[store.langIndex] + ' '}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex h-100">
                <div className="navbar-header col mr-4 d-block d-md-none pt-2">
                  <button type="button" className="navbar-toggle collapsed border-0" onClick={toggleMenu}>
                    <i class="fa fa-bars" aria-hidden="true"></i>
                  </button>
                </div>
                {(getParamUrl.initial == 'Quran') || ((getParamUrl.selectedSearch == '205')) ? (
                  <div
                    className="icon col d-md-none d-block"
                    style={{ color: Colors.primary }}
                    onClick={searchbar}
                  >
                    <i className="fas fa-search fa-sm mx-1" />
                  </div>
                ) : (
                  ""
                )}

                <div className="col-12 d-flex h-100">
                  <div className="col-12 d-md-block d-none">

                    {currentLocation.pathname == "/login" || currentLocation.pathname == "/register" ? (
                      ""
                    ) : (
                      <>
                        <div className="h-100 d-flex w-100">
                          <div className="mt-auto text-center mb-auto">

                            <Link
                              to="/features"
                              className={(classes.link, "headlink-padding")}
                              style={{
                                color:
                                  currentLocation.pathname == "/features"
                                    ? "red" :
                                    "none",
                                fontFamily:
                                  store.langIndex == 0
                                    ? "Raleway, sans-serif"
                                    : store.langIndex == 1
                                      ? "JameelNoori"
                                      : "Arabic",

                                fontSize:
                                  store.langIndex == 0
                                    ? 19
                                    : store.langIndex == 1
                                      ? 17
                                      : 16,
                              }}
                            >
                              {' ' + TextTranslation.features[store.langIndex] + ' '}
                            </Link>
                          </div><div className="mt-auto text-center mb-auto">
                            <Link
                              to="/aboutus"
                              className={(classes.link, "headlink-padding")}
                              style={{
                                color:
                                  currentLocation.pathname == "/aboutus"
                                    ? " red" :
                                    "none",
                                fontFamily:
                                  store.langIndex == 0
                                    ? "Raleway, sans-serif"
                                    : store.langIndex == 1
                                      ? "JameelNoori"
                                      : "Arabic",
                                fontSize:
                                  store.langIndex == 0
                                    ? 19
                                    : store.langIndex == 1
                                      ? 17
                                      : 16,
                              }}
                            >
                              {' ' + TextTranslation.about_us[store.langIndex] + ' '}
                            </Link>
                          </div><div className="mt-auto text-center mb-auto" style={{ padding: "inherit" }} >
                            <Link
                              to="/aboutauthor"
                              className={(classes.link, "headlink-padding")}
                              style={{
                                color:
                                  currentLocation.pathname == "/aboutauthor"
                                    ? "red" :
                                    "none",
                                fontFamily:
                                  store.langIndex == 0
                                    ? "Raleway, sans-serif"
                                    : store.langIndex == 1
                                      ? "JameelNoori"
                                      : "Arabic",

                                fontSize:
                                  store.langIndex == 0
                                    ? 19
                                    : store.langIndex == 1
                                      ? 17
                                      : 16,
                              }}
                            >
                              {' ' + TextTranslation.about_author[store.langIndex] + ' '}
                            </Link>
                          </div><div className="mt-auto text-center mb-auto">
                            <Link
                              to="/aboutencyclopedia"
                              className={(classes.link, "headlink-padding")}

                              style={{
                                color:
                                  currentLocation.pathname == "/aboutencyclopedia"
                                    ? "red" :
                                    "none",
                                fontFamily:
                                  store.langIndex == 0
                                    ? "Raleway, sans-serif"
                                    : store.langIndex == 1
                                      ? "JameelNoori"
                                      : "Arabic",
                                fontSize:
                                  store.langIndex == 0
                                    ? 19
                                    : store.langIndex == 1
                                      ? 17
                                      : 16,
                              }}
                            >
                              {' ' + TextTranslation.about_quranic_encyclopedia[store.langIndex] + ' '}
                            </Link>
                          </div><div className="mt-auto text-center mb-auto">
                            <Link
                              to="/table"
                              className={(classes.link, "headlink-padding")}

                              style={{
                                color:
                                  currentLocation.pathname == "/table"
                                    ? "red" :
                                    "none",
                                fontFamily:
                                  store.langIndex == 0
                                    ? "Raleway, sans-serif"
                                    : store.langIndex == 1
                                      ? "JameelNoori"
                                      : "Arabic",
                                fontSize:
                                  store.langIndex == 0
                                    ? 19
                                    : store.langIndex == 1
                                      ? 17
                                      : 16,
                              }}
                            >
                              {' ' + TextTranslation.quranic_encyclopedia[store.langIndex] + ' '}
                            </Link>
                          </div><div className="mt-auto text-center mb-auto">


                            <Link
                              to="/contactus"
                              className={(classes.link, "headlink-padding")}

                              style={{
                                color:
                                  currentLocation.pathname == "/contactus"
                                    ? "red" :
                                    "none",
                                fontFamily:
                                  store.langIndex == 0
                                    ? "Raleway, sans-serif"
                                    : store.langIndex == 1
                                      ? "JameelNoori"
                                      : "Arabic",
                                fontSize:
                                  store.langIndex == 0
                                    ? 19
                                    : store.langIndex == 1
                                      ? 17
                                      : 16,
                              }}
                            >
                              {' ' + TextTranslation.suggestion_contact_us[store.langIndex] + ' '}
                            </Link>
                          </div>
                        </div>
                      </>
                    )}


                  </div>
                  {/* <div className="col h-100 p-0"> 
            { currentLocation.pathname=="/login" && (
              <>
              <div className="h-100  d-md-block d-none d-flex" style={{ justifyContent:'end',padding:'0px' }}>
                <div className="mt-auto mb-auto text-right pr-2">
                <Link
                to="/register"
                style={{ color: Colors.black, textDecoration: "none" }}
              >
                  <span className="font-eng " style={ 
                     store.langIndex == 0 ? { fontFamily: "Raleway,sans-serif"}:
                        store.langIndex == 1 ? {fontFamily: "JameelNoori"} : { fontFamily: "Arabic"}}>{TextTranslation.create_new_account[store.langIndex]}</span>
                </Link>
                </div>
                </div>
              </>
            )}
            { currentLocation.pathname=="/register" && ( 
            <>
              <div className="h-100  d-md-block d-none d-flex" style={{ justifyContent:'end',padding:'0px' }}>
                <div className="mt-auto mb-auto text-right pr-2" style={ store.langIndex == 0
                      ? { fontFamily: "Raleway, sans-serif" }
                      : store.langIndex == 1
                      ? { fontFamily: "JameelNoori" }
                      : { fontFamily: "Arabic" }}>
                <Link
                to="/login"
                style={{ color: Colors.black, textDecoration: "none" }}
              >
                  <span className="font-eng"style={ 
                      store.langIndex == 0
                      ? { fontFamily: "Raleway, sans-serif" } :
                    store.langIndex == 1
                          ? {
                              
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                
                             
                            }
                          : {
                            
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            
                            }}>
                    {TextTranslation.register_redirection[store.langIndex]}
                    </span>
                </Link>
                </div>
                </div>
              </>
              )}
 { currentLocation.pathname!="/register"  && currentLocation.pathname!="/login" && (
              <>
               <div className="h-100   d-flex" style={{ justifyContent:'end',padding:'0px' }}>
                <div className="mt-auto text-center mb-auto" style={ store.langIndex == 0
                      ? { fontFamily: "Raleway, sans-serif" }
                      : store.langIndex == 1
                      ? { fontFamily: "JameelNoori" }
                      : { fontFamily: "Arabic" }}>
              <Link
              to="/login"
              style={{ color: Colors.black, textDecoration: "none" }}
            >
              <span
                className="btn mt-2"
                style={{
                  backgroundColor: Colors.grey,
                  color: Colors.black,
                  fontFamily: "Raleway,sans-serif",
                  fontSize: "14px",
                  marginRight: "10px",
                }}
              >
                <span
                  style={
                    store.langIndex == 0
                      ? { fontFamily: "Raleway, sans-serif" }
                      : store.langIndex == 1
                      ? { fontFamily: "JameelNoori" }
                      : { fontFamily: "Arabic" }
                  }
                >
                  {TextTranslation.f_login[store.langIndex]}
                </span>
              </span>
            </Link>
            </div><div className="mt-auto text-center mb-auto">
            <Link
              to="/register"
              className="d-md-block d-none "
              style={{ color: Colors.white, textDecoration: "none" }}
            >
              <span
                className="btn mt-2 btn-radius"
                style={{
                  backgroundColor: Colors.primary,
                  color: Colors.white,
                  fontFamily: "Raleway,sans-serif",
                  fontSize: "14px",
                  marginRight: "10px",
                }}
              >
                <span
                  style={
                    store.langIndex == 0
                      ? { fontFamily: "Raleway, sans-serif" }
                      : store.langIndex == 1
                      ? { fontFamily: "JameelNoori" }
                      : { fontFamily: "Arabic" }
                  }
                >
                  {TextTranslation.register[store.langIndex]}
                </span>
              </span>
            </Link>
            </div></div>
            </>
            )}
          
              </div> */}

                </div>

              </div>
              <div className="d-block d-sm-none">
                <nav className="navbar navbar-inverse navbar-static-top p-0" role="navigation">
                  <div className="container p-0">


                    <div className={"collapse navbar-collapse " + (menu ? "show" : "")} >
                      <ul className="nav navbar-nav text-center">
                        <li>
                          <Link
                            to="/features"
                            className={classes.link}
                            style={{
                              color:
                                currentLocation.pathname == "/features"
                                  ? " red" :
                                  "none",
                              fontFamily:
                                store.langIndex == 0
                                  ? "Raleway, sans-serif"
                                  : store.langIndex == 1
                                    ? "JameelNoori"
                                    : "Arabic",

                              fontSize:
                                store.langIndex == 0
                                  ? 19
                                  : store.langIndex == 1
                                    ? 17
                                    : 16,
                            }}
                          >
                            {' ' + TextTranslation.features[store.langIndex] + ' '}
                          </Link>
                          <hr className="m-1" />
                        </li>

                        <li>
                          <Link
                            to="/aboutus"
                            className={classes.link}
                            style={{
                              color:
                                currentLocation.pathname == "/aboutus"
                                  ? " red" :
                                  "none",
                              fontFamily:
                                store.langIndex == 0
                                  ? "Raleway, sans-serif"
                                  : store.langIndex == 1
                                    ? "JameelNoori"
                                    : "Arabic",
                              fontSize:
                                store.langIndex == 0
                                  ? 19
                                  : store.langIndex == 1
                                    ? 17
                                    : 16,
                            }}
                          >
                            {' ' + TextTranslation.about_us[store.langIndex] + ' '}
                          </Link>
                          <hr className="m-1" />
                        </li>
                        <li>
                          <Link
                            to="/aboutencyclopedia"
                            className={classes.link}
                            style={{
                              color:
                                currentLocation.pathname == "/aboutencyclopedia"
                                  ? " red" :
                                  "none",
                              fontFamily:
                                store.langIndex == 0
                                  ? "Raleway, sans-serif"
                                  : store.langIndex == 1
                                    ? "JameelNoori"
                                    : "Arabic",
                              fontSize:
                                store.langIndex == 0
                                  ? 19
                                  : store.langIndex == 1
                                    ? 17
                                    : 16,
                            }}
                          >
                            {' ' + TextTranslation.about_quranic_encyclopedia[store.langIndex] + ' '}
                          </Link>
                          <hr className="m-1" />
                        </li>
                        <li>
                          <Link
                            to="/contactus"
                            className={classes.link}

                            style={{
                              color:
                                currentLocation.pathname == "/contactus"
                                  ? "red" :
                                  "none",
                              fontFamily:
                                store.langIndex == 0
                                  ? "Raleway, sans-serif"
                                  : store.langIndex == 1
                                    ? "JameelNoori"
                                    : "Arabic",
                              fontSize:
                                store.langIndex == 0
                                  ? 19
                                  : store.langIndex == 1
                                    ? 17
                                    : 16,
                            }}
                          >
                            {' ' + TextTranslation.suggestion_contact_us[store.langIndex] + ' '}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>

            </>
          )}
        </div>
      )}
    </Observer>
  );
};

export default MainNavbar;
