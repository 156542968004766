import React, { useContext,useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import Colors from "../../Config/Colors";
import TextTranslationNew from "../../Config/TextTranslationNew"

const useStyles = makeStyles(() => ({
  heading: {
    color: Colors.primary,
    fontFamily: "Raleway, sans-serif",
    textAlign: "center",
    marginTop: "2%",
  },
  para: {
    fontFamily: "Raleway, sans-serif",
    textAlign: "justify",
    fontSize: "14px",
  },
}));

const AboutAuthor = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const store = useContext(StoreContext);
  const classes = useStyles(); // styles classes
  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    
  }, []);

  return (
    <>
          <CommonNavbar />   
          <Grid
            container
            style={{
              justifyContent: "space-around",
            }}
          >
            <Grid item xs={10} sm={10} md={10} lg={8} xl={7}>
              <h5 className={classes.heading} style={ 
                  store.langIndex == 0
                    ? {  textAlign: "center",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                                lineHeight:1.8,
                                textAlign: "center",
                                fontFamily: "JameelNoori",
                                fontSize:18,
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "center",
                                fontFamily: "Arabic",
                                fontSize:18,
                             }}>
                
                {TextTranslationNew.about_author_heading[store.langIndex]}
              </h5>
              <div className="pt-4">
                              {TextTranslationNew.about_author_content[store.langIndex].map(paragraph => <p style={ 
                  store.langIndex == 0
                    ? {  textAlign: "justify",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                                lineHeight:1.8,
                                textAlign: "justify",
                                direction: "rtl",
                                fontFamily: "JameelNoori",
                                fontSize:18,
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "justify",
                                direction: "rtl",
                                fontFamily: "Arabic",
                                fontSize:18,
                             }} className={classes.para}>
                  {paragraph}
                  
                </p>)}

                            </div>
            </Grid>
          </Grid>
          <div className="mt-2 mb-2 text-center">
            <button
              className="btn"
              style={{
                backgroundColor: Colors.primary,
              }}
            >
              <a
                href="https://www.minhaj.org/english/tid/8718/A-Profile-of-Shaykh-ul-Islam-Dr-Muhammad-Tahir-ul-Qadri.html"
                target="_blank"
                style={{
                  color: Colors.white,
                  fontFamily: "Raleway, sans-serif",
                  fontSize: "14px",
                }}
              >
                Read More
              </a>
            </button>
          </div>
        </>
     
  );
};

export default AboutAuthor;
