import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../../../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Observer } from "mobx-react-lite";
import Colors from "../../../../Config/Colors";
import TextTranslation from "../../../../Config/TextTranslation";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightText from "../../../../CustomComponents/HighlightText";
import UrduText from "../../../../CustomComponents/UrduText";
import ArabicText from "../../../../CustomComponents/ArabicText";
import TopicCard from "../../../../CustomComponents/TopicCard";
import EngText from "../../../../CustomComponents/EngText";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

const Articles = ({ search }) => {
  let history = useHistory();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [articleTitle, setArticleTitle] = useState(""); // article title state
  const [from, setFrom] = useState(0); // from state to show results
  const [loader, setLoader] = useState(true); // loader state
  const [loadMore, setLoadMore] = useState(); // show more state

  let urls =
    "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_articles/_search";

  const getData = () => {
    fetch(urls, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
      },
      body: JSON.stringify({
        from: from,
        size: 10,
        query: {
          query_string: {
            query: "*" + search + "*",
            fields: ["title^10", "body^2"],
          },
        },
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        store.setArticleCounter(res.hits.total.value);
        console.log("articles screen response", res.hits.hits);
        {
          res.hits.hits.length >= 10 ? setLoadMore(true) : setLoadMore(false);
        }
        setArticleTitle([...articleTitle, ...res.hits.hits]);
        setFrom(from + 10);
        setLoader(false);
        console.log("ARTICLE SCREEN");
      })
      .catch((error) => {
        console.log("Error in Articles.jsx", error);
        alert(error);
      });
  };

  useEffect(() => {
    const ac = new AbortController();
    getData();
    return () => ac.abort();
  }, [search, store.article_counter]);

  return (
    <Observer>
      {() => (
        <>
          {!loader ? (
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={10} lg={7} xl={6}>
                {/* if articles exist then it will show on the top of results */}
                {articleTitle.length > 0 ? (
                  <>
                    {store.langIndex == 0 ? (
                      <EngText>
                        <HighlightText
                          simpleText={`${store.article_counter} ${TextTranslation.resultStart} "${search}" ${TextTranslation.articles_resultEnd}`}
                          colorText={search}
                        />
                      </EngText>
                    ) : store.langIndex == 1 ? (
                      <UrduText>
                        <HighlightText
                          simpleText={`آپ کے تلاش کردہ لفظ "${search}" کے مطابق "مضامین سیکشن" میں ${store.article_counter} نتائج ملے ہیں `}
                          colorText={search}
                        />
                      </UrduText>
                    ) : (
                      <ArabicText>
                        <HighlightText
                          simpleText={`تم العثور على ${store.article_counter} نتيجة مقابل الكلمة الرئيسية "${search}" من مقالات"`}
                          colorText={search}
                        />
                      </ArabicText>
                    )}
                    <div>
                      {articleTitle.map((data, index) => (
                        <TopicCard
                          key={index}
                          // there are 2 routes; one with fatwa id and other without fatwa id
                          onClick={() =>
                            history.push(
                              data._source.fatwa_id
                                ? `/selectedresult/Fatwa/${data._source.article_id}/${data._source.fatwa_id}/${store.langIndex}`
                                : `/selectedresult/Fatwa/${data._source.article_id}/${store.langIndex}`
                            )
                          }
                        >
                          {/* {store.langIndex == 0 ? (
                            <EngText styles={{textAlign: /[a-zA-Z]/g.test(data._source.title) ?  'left' : 'right'}}>
                              <HighlightText
                                simpleText={data._source.title}
                                colorText={search}
                              />
                            </EngText>
                          ) : store.langIndex == 1 ? (
                            <UrduText styles={{textAlign: /[a-zA-Z]/g.test(data._source.title) ?  'left' : 'right',
                             fontFamily: /[a-zA-Z]/g.test(data._source.title) ?  'Raleway,sans-serif' : 'JameelNoori'}}>
                              <HighlightText
                                simpleText={data._source.title}
                                colorText={search}
                              />
                            </UrduText>
                          ) : (
                            <ArabicText styles={{textAlign: /[a-zA-Z]/g.test(data._source.title) ?  'left' : 'right'}}>
                              <HighlightText
                                simpleText={data._source.title}
                                colorText={search}
                              />
                            </ArabicText>
                          )} */}
                          <UrduText>
                          <HighlightText styles={{fontFamily:'JameelNoori', fontSize: 18}}
                                simpleText={data._source.title}
                                colorText={search}
                              />
                          </UrduText>
                        </TopicCard>
                      ))}
                    </div>
                  </>
                ) : (
                  // if there will be no results then it will show in the center of screen
                  <div className="text-center mt-5 pt-5">
                    {store.langIndex == 0 ? (
                      <EngText styles={{ textAlign: "center" }}>
                        <HighlightText
                          simpleText={`${TextTranslation.no_resultTab_found} "${search}" ${TextTranslation.articles_resultEnd}`}
                          colorText={search}
                        />
                      </EngText>
                    ) : store.langIndex == 1 ? (
                      <UrduText styles={{ textAlign: "center" }}>
                        <HighlightText
                          simpleText={`آپ کے تلاش کردہ لفظ "${search}" کے مطابق "مضامین سیکشن" میں کوئی نتائج نہیں ملے`}
                          colorText={search}
                        />
                      </UrduText>
                    ) : (
                      <ArabicText styles={{ textAlign: "center" }}>
                        <HighlightText
                          simpleText={`لا توجد نتائج مقابل الكلمة الرئيسية "${search}" من "مقالات"`}
                          colorText={search}
                        />
                      </ArabicText>
                    )}
                  </div>
                )}
                {loadMore == false ? null : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <button
                      onClick={() => getData()}
                      style={{
                        backgroundColor: Colors.primary,
                        color: Colors.white,
                        border: "none",
                        borderRadius: "5px",
                        height: 40,
                      }}
                    >
                      <div
                        style={
                          store.langIndex == 0
                            ? { fontFamily: "Raleway, sans-serif" }
                            : store.langIndex == 1
                            ? { fontFamily: "JameelNoori", fontSize: 18 }
                            : { fontFamily: "AlQalam", fontSize: 18 }
                        }
                      >
                        {TextTranslation.show_more[store.langIndex]}
                      </div>
                    </button>
                  </div>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              style={{ minHeight: "100vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Observer>
  );
};

export default Articles;
