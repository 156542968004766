import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { useHistory } from "react-router-dom";
import { Observer } from "mobx-react-lite";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextTranslation from "../../Config/TextTranslation";
import Colors from "../../Config/Colors";
import TopicCard from "../../CustomComponents/TopicCard";
import EngText from "../../CustomComponents/EngText";
import UrduText from "../../CustomComponents/UrduText";
import ArabicText from "../../CustomComponents/ArabicText";
import HighlightText from "../../CustomComponents/HighlightText";
import _ from "underscore";
import moment from "moment";
import axios from "axios";
import Url from "../../Config/Url";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

const Quran = ({ search }) => {
  let data = [];
  let history = useHistory();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [loader, setLoader] = useState(true);
  const [load, setLoad] = useState(true); // loading state on button
  const [loadMore, setLoadMore] = useState();
  const [syn, setSyn] = useState([]);

  let field =
    store.langIndex == 0
      ? [
          "topic_english^10",
          "chapter_english^8",
          "section_english^6",
          "reference_english^4",
        ]
      : store.langIndex == 1
      ? [
          "topic_urdu^10",
          "chapter_urdu^8",
          "section_urdu^6",
          "reference_urdu^4",
        ]
      : [
          "topic_arabic^10",
          "chapter_arabic^8",
          "section_arabic^6",
          "reference_arabic^4",
          "arabic_ref_withouterab^4",
        ];

  // synonym results API
  // const getSynonym = (search) => {
  //   fetch(
  //     `https://dictionaryapi.com/api/v3/references/thesaurus/json/${search}?key=d6ec4705-689a-4de7-9a2f-e551b139ba0e`,
  //     {
  //       method: "GET",
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((response) => {
  //       setSyn(response[0].meta.syns[0]);
  //       console.log("Quran Synonym response", response[0].meta.syns[0]);
  //       store.setSynCounter(response[0].meta.syns[0].length);
  //       for (let i = 0; i < response[0].meta.syns[0].length; i++) {
  //         getSynData(response[0].meta.syns[0][i]);
  //         if (store.dataArr.length > 0) {
  //           break;
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Error in getSynonym", error);
  //     });
  // };

  // const getSynData = (search) => {
  //   fetch(
  //     "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_stemtopic_v6/_search",
  //     {
  //       method: "POST", // or 'PUT'
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
  //       },
  //       body: JSON.stringify({
  //         from: store.from,
  //         size: 10,
  //         query: {
  //           query_string: {
  //             query: "*" + search + "*",
  //             fields: field,
  //           },
  //         },
  //       }),
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((response) => {
  //       console.log("<<<<<", response.hits.hits);
  //       store.setDataArr(response.hits.hits);
  //     })
  //     .catch((error) => {
  //       console.log("Error in getSynData", error);
  //     });
  // };

  // search results API
  const getData = (search) => {
    setLoad(true);
    fetch(
      "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_stemtopic_v6/_search",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
        },
        body: JSON.stringify({
          from: store.from,
          size: 10,
          query: {
            query_string: {
              query: "*" + search + "*",
              fields: field,
            },
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.hits.hits.length !== 0) {
          console.log("Quran Title response", response.hits.hits);
          store.setDataArr([...store.dataArr, ...response.hits.hits]);
          store.setResultCounter(response.hits.total.value);
          store.setFrom(store.from + 10);
          setLoader(false);
          setLoad(false);
        } else {
          //getSynonym(search);
          setLoader(false);
        }
        {
          response.hits.hits.length >= 10
            ? setLoadMore(true)
            : setLoadMore(false);
        }
      })
      .catch((error) => {
        console.log("Error in Quranic.js", error);
      });
  };

  const getCount = () => {
    store.setResultCounter(null);
    fetch(
      "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_transcripts/_count?q=" +
        search,
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        store.setSpeechCounter(responseJson.count);
      })
      .catch((error) => {
        console.log("Error ", error);
        alert(error);
      });
  };

  // post search history API
  const postSearchHistory = (search, searchType) => {
    var PostHistoryForm = new FormData();

    PostHistoryForm.append("search", search);
    PostHistoryForm.append("1", searchType);
    PostHistoryForm.append("lang_id", parseInt(store.langIndex) + 1);
    PostHistoryForm.append("date", moment().format("YYYY-MM-DD HH:mm:ss"));
    {
      store.id !== null &&
        axios({
          method: "post",
          url: `${Url.link}/store_history.php?`,
          data: PostHistoryForm,
          headers: {
            "Content-Type": "multipart/form-data",
            uuid: localStorage.getItem("@uuid"),
          },
        })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log("history" + error);
          });
    }
  };

  useEffect(() => {
    const ac = new AbortController();
    window.scrollTo(0, 0);
    getData(search);
    //getSynonym(search);
    getCount();
    return () => {
      ac.abort();
      store.setDidYouMeanBool(0);
    };
  }, [search]);

  return (
    <Observer>
      {() => (
        <>
          {!loader ? (
            <>
              {store.searchValue.toLowerCase().trim() ==
              store.changeDidYouMean ? null : (
                <p className="text-center mt-5" style={{ color: "black" }}>
                  <span style={{ fontFamily: "Raleway,sans-serif" }}>
                    Did You Mean :
                  </span>
                  <span
                    style={{
                      cursor: "pointer",
                      fontFamily: "Raleway, sans-serif",
                      color: Colors.primary,
                    }}
                    onClick={() => {
                      getData(store.changeDidYouMean);
                      store.setFrom(0);
                      store.setSearchValue(store.changeDidYouMean);
                      store.setDidYouMeanBool(1);
                      postSearchHistory(
                        store.didYouMeanBool ? store.changeDidYouMean : search
                      );
                    }}
                  >
                    {store.changeDidYouMean}
                  </span>

                  <br />
                </p>
              )}

              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={7} xl={6}>
                  {store.langIndex == 0 ? (
                    <EngText>
                      <HighlightText
                        simpleText={`${store.result_counter}  ${
                          TextTranslation.resultStart
                        } "${
                          store.didYouMeanBool == 1
                            ? store.changeDidYouMean
                            : search
                        }" ${TextTranslation.quran_resultEnd}`}
                        colorText={
                          store.didYouMeanBool == 1
                            ? store.changeDidYouMean
                            : search
                        }
                      />
                    </EngText>
                  ) : store.langIndex == 1 ? (
                    <UrduText>
                      <HighlightText
                        simpleText={`آپ کے تلاش کردہ لفظ "${
                          store.didYouMeanBool == 1
                            ? store.changeDidYouMean
                            : search
                        }" کے مطابق "قرآن سیکشن" میں ${
                          store.result_counter
                        } نتائج ملے ہیں `}
                        colorText={
                          store.didYouMeanBool == 1
                            ? store.changeDidYouMean
                            : search
                        }
                      />
                    </UrduText>
                  ) : (
                    <ArabicText>
                      <HighlightText
                        simpleText={`${
                          store.result_counter
                        } نتيجة مقابل كلمة "${
                          store.didYouMeanBool == 1
                            ? store.changeDidYouMean
                            : search
                        }" من موسوعة القرآن`}
                        colorText={
                          store.didYouMeanBool == 1
                            ? store.changeDidYouMean
                            : search
                        }
                      />
                    </ArabicText>
                  )}
                  {store.dataArr
                    .filter((e) =>
                      store.langIndex == 0
                        ? e._source.topic_english != "NA"
                        : store.langIndex == 1
                        ? e._source.topic_urdu != "NA"
                        : e._source.topic_arabic != "NA"
                    )
                    .map((data, index) => (
                      <TopicCard
                        key={index}
                        onClick={() => {
                          history.push(
                            `/selectedresult/Quran/${data._id}/${store.langIndex}`
                          );
                        }}
                      >
                        {store.langIndex == 0 ? (
                          <EngText>
                            <HighlightText
                              simpleText={data._source.topic_english}
                              colorText={
                                store.didYouMeanBool == 1
                                  ? store.changeDidYouMean
                                  : search
                              }
                            />
                          </EngText>
                        ) : store.langIndex == 1 ? (
                          <UrduText>
                            <HighlightText
                              simpleText={data._source.topic_urdu}
                              colorText={
                                store.didYouMeanBool == 1
                                  ? store.changeDidYouMean
                                  : search
                              }
                            />
                          </UrduText>
                        ) : (
                          <ArabicText>
                            <HighlightText
                              simpleText={data._source.topic_arabic}
                              colorText={
                                store.didYouMeanBool == 1
                                  ? store.changeDidYouMean
                                  : search
                              }
                            />
                          </ArabicText>
                        )}
                      </TopicCard>
                    ))}
                  {loadMore == false ? null : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <button
                        onClick={() => {
                          getData(
                            store.changeDidYouMean
                              ? store.changeDidYouMean
                              : search
                          );
                        }}
                        style={{
                          backgroundColor: Colors.primary,
                          color: Colors.white,
                          border: "none",
                          borderRadius: "5px",
                          height: 40,
                        }}
                      >
                        {load ? (
                          <CircularProgress
                            size={20}
                            style={{ color: Colors.white }}
                          />
                        ) : (
                          <div
                            style={
                              store.langIndex == 0
                                ? {
                                    fontFamily: "Raleway, sans-serif",
                                    fontSize: 16,
                                  }
                                : store.langIndex == 1
                                ? { fontFamily: "JameelNoori", fontSize: 18 }
                                : { fontFamily: "AlQalam", fontSize: 18 }
                            }
                          >
                            {TextTranslation.show_more[store.langIndex]}
                          </div>
                        )}
                      </button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              style={{ minHeight: "100vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Observer>
  );
};

export default Quran;
