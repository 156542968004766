export default {
  primary: "#e32636",
  black: "#262626",
  white: "#fff",
  grey: "#F2F2F2",
  danger: "#ff5252",
  green: "#008000",
  blue: "#2471a3",
  theme_bg_wb:["#fff","#202124"],
  theme_bg_wg:["#fff","#303134"],
  theme_c_bw:["#9aa0a6","#fff"],
  theme_c_dbw:["#2c3136","#fff"],
  inputBorder:"#DDDDDD",
};

