import React, { useState, useEffect, useContext } from "react";
import { Card, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Observer } from "mobx-react-lite";
import { StoreContext } from "../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import TextTranslation from "../../Config/TextTranslation";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArabicText from "../../CustomComponents/ArabicText"
import UrduText from "../../CustomComponents/UrduText"
import Heading from "../../CustomComponents/Heading"
import EngText from "../../CustomComponents/EngText"
import Colors from "../../Config/Colors";
import axios from "axios";
import "./PublicArticleTitle.css";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

const PublicArticleTitle = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let history = useHistory();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [category, setCategory] = useState([]); // category state
  const [loader, setLoader] = useState(true);   // loader state

  // get category API
  const getCategory = () => {
    axios({
      method: "post",
      url:
        "https://api.minhaj.digital/get_article_categories.php",
    }).then((res) => {
      console.log("get category api console", res.data);
      setCategory(res.data.MDE_API[0].MSG);
      setLoader(false);
    });
  };

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    getCategory();
    return () => ac.abort();
  }, []);

  return (
    <Observer>
      {() => (
        <>
          <CommonNavbar />
          <div className="pt-3">
          <Heading>{TextTranslation.categoryList[store.langIndex]}</Heading>
          </div>
          {!loader ? (
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={10}
              xl={8}
              justify="space-around"
              style={{ margin: "auto" }}
            >
              {category.map((data, index) => (
                <Card
                  className="category-topic mt-3 "
                  key={index}
                  raised={true}
                  onClick={() =>
                    history.push(`/publicarticleselected/${data.cat_id}`)
                  }
                  style={{
                    width: "30%",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {
                    store.langIndex == 0 ? 
                    <EngText styles={{fontSize: 14}}>{data.cat_eng}</EngText>
                    : store.langIndex == 1 ?
                    <UrduText styles={{fontSize: 18}}>{data.cat_ur}</UrduText>
                    :
                    <ArabicText styles={{fontSize: 18}}>{data.cat_ar}</ArabicText>
                  }
                </Card>
              ))}
            </Grid>
          ) : (
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              style={{ minHeight: "80vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Observer>
  );
};

export default PublicArticleTitle;
