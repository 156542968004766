import { Grid, Card, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect, useContext} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { Observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import Colors from "../../Config/Colors";
import axios from "axios";
import lodash from "lodash";
import "./QuranBookmarkTitle.css";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import EngText from "../../CustomComponents/EngText";
import UrduText from "../../CustomComponents/UrduText";
import QuranicText from "../../CustomComponents/QuranicText";
import Url from "../../Config/Url";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

const AyatBookmarkTitle = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // let history = useHistory();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [title, setTitle] = useState([]);
  const [loader, setLoader] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [success, setSuccess] = useState("");


  const postBookmarkTitle = () => {
    axios({
      method: "post",
      url: `${Url.link}/get_ayat_bookmark.php`,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    }).then((res) => {
   
      setTitle(res.data.MDE_API[0].MSG);
      console.log(store.id);
      setLoader(false);
    });
  };


  
  // delete bookmark API

  const deleteBookmark = (sura_no, ayat_no) => {
    var DeleteBookmarkData = new FormData();

    DeleteBookmarkData.append("ayat_no", ayat_no);
    DeleteBookmarkData.append("sura_no", sura_no);

    axios({
      method: "post",
      url: `${Url.link}/delete_ayat_bookmark.php`,
      data: DeleteBookmarkData,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    }).then((res) => {
      if (window.confirm("Are you sure to delete?")) {
        postBookmarkTitle();
        setOpen1(true);
        setSuccess(1);
      } else {
        setOpen1(true);
        setSuccess(0);
      }
      console.log("delete bookmarks api console", res.data);
    });
  };

  let data = lodash.groupBy(title, "ar_text_erab","ur_text");
  

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    window.location.href= 'https://download.minhaj.app/'; 
  }
    const ac = new AbortController();
    postBookmarkTitle();
    return () => ac.abort();
  }, []);

  return (
    <Observer>
      {() => (
        <>

          {loader ? (
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              directioin="column"
              style={{ minHeight: "80vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center" justify="center">
              <Grid item xs={10} sm={10} md={10} lg={7} xl={6}>
                <div className="text-right mb-2">
                  <EngText styles={{textAlign: 'right'}}>
                    Total Bookmarked Ayat: {Object.keys(data).length}
                  </EngText>
                </div>
                
                {/* // onClick={() => {
                  //     history.push(`/quranbookmark/result/${key}`);
                  //     store.setBookmarkSearchTitle(key);
                  //   }} */}
                {/* {Object.keys(data).map((key, index) => (
                  <Card className="bookmark-card mb-2" key={index} raised={true}
                  
                    >
                      {
                       
                       data[key][0].lang_id == 1 ? 
                       <EngText styles={{margin: "15px 10px"}}>{key} {data[key][0]}</EngText>
                       : data[key][0].lang_id == 2 ?
                       <UrduText styles={{margin: "15px 10px"}}>{key}</UrduText> 
                       : <ArabicText styles={{margin: "15px 10px"}}>{key}</ArabicText>
                      }
                      </Card>                
                ))} */}
                {title.map(user=>(
                         <Card className="bookmark-card mb-2" raised={true}
                  
                         >
                           {user.id}
                            <QuranicText ayat={user.ayat_no} styles={{margin: "15px 10px"}}>{user.ar_text_erab}</QuranicText>
                            <UrduText styles={{margin: "15px 10px"}}> {user.ur_text}</UrduText> 
                            <EngText styles={{margin: "15px 10px"}}>{user.en_text}</EngText>
                         <i
                           onClick={() =>
                            deleteBookmark(
                              user.sura_no,
                              user.ayat_no
                            )
                          }
                      
                          className="fas fa-minus-circle fa-sm mx-2 text-danger"  
                          aria-hidden="true"></i>
                        
                          </Card>  
                   
                        ))}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Observer>
  );
};

export default AyatBookmarkTitle;
