import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Card,
  TextField,
  CircularProgress,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { useHistory } from "react-router-dom";
import { Observer } from "mobx-react-lite";
import { Formik, Form } from "formik";
import TextTranslation from "../../Config/TextTranslation.js";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Colors from "../../Config/Colors";
import moment from "moment";
import axios from "axios";
import "./NewMainSearch.css";
import Url from "../../Config/Url";



const useStyles = makeStyles((theme) => ({
  loader: {
    color: Colors.primary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    width: "60%",
    height: 500,
    borderRadius: "5px",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  input: {
    width: "70%",
  },
  heading: {
    border: "2px solid #e32636",
    textAlign: "center",
    maxWidth: "25%",
    margin: "auto",
    marginBottom: "2%",
    padding: "5px 0px",
    backgroundColor: Colors.primary,
    color: "white",
    fontFamily: "Raleway, sans-serif",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "70%",
      fontSize: "16px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const NewMainSearch = () => {
  let history = useHistory();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [search, setSearch] = useState("");
  const [suggestion, setSuggestion] = useState([]);

  let field =
    store.langIndex == 0
      ? [
          "topic_english^10",
          "chapter_english^8",
          "section_english^6",
          "reference_english^4",
        ]
      : store.langIndex == 1
      ? [
          "topic_urdu^10",
          "chapter_urdu^8",
          "section_urdu^6",
          "reference_urdu^4",
        ]
      : [
          "topic_arabic^10",
          "chapter_arabic^8",
          "section_arabic^6",
          "reference_arabic^4",
          "arabic_ref_withouterab^4",
        ];

  // post search history API
  const postSearchHistory = (search, searchType) => {
    var PostHistoryForm = new FormData();

    PostHistoryForm.append("search", search);
    PostHistoryForm.append("1", searchType);
    PostHistoryForm.append("lang_id", parseInt(store.langIndex) + 1);
    PostHistoryForm.append("date", moment().format("YYYY-MM-DD HH:mm:ss"));
    {
      store.id !== null &&
        axios({
          method: "post",
          url: `${Url.link}/store_history.php?`,
          data: PostHistoryForm,
          headers: {
            "Content-Type": "multipart/form-data",
            uuid: localStorage.getItem("@uuid"),
          },
        })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log("history" + error);
          });
    }
  };
  // did you mean
  const didYouMean = (search) => {
    fetch(
      "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_stemtopic_v6/_search",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
        },
        body: JSON.stringify({
          size: 0,

          suggest: {
            text: search.toLowerCase(),
            suggestion: {
              phrase: {
                field:
                  store.langIndex == 0
                    ? "eng_ref"
                    : store.langIndex == 1
                    ? "urdu_ref"
                    : "arabic_ref",

                real_word_error_likelihood: 0.95,

                max_errors: 0.5,

                confidence: 0,

                highlight: {
                  pre_tag: "<em>",

                  post_tag: "</em>",
                },

                collate: {
                  query: {
                    inline: {
                      match: {
                        "": "",
                      },
                    },
                  },

                  params: {
                    field_name:
                      store.langIndex == 0
                        ? "eng_ref"
                        : store.langIndex == 1
                        ? "urdu_ref"
                        : "arabic_ref",
                  },

                  prune: true,
                },
              },
            },
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson.suggest.suggestion[0].options[0]) {
          store.setDidYouMeanExist(false);
        } else {
          store.setChangeDidYouMean(
            responseJson.suggest.suggestion[0].options[0].text
          );
          store.setDidYouMeanExist(true);
        }
      })
      .catch((error) => {
        console.error("Suggest" + error);
      });
  };

  // get suggestion API
  const getSuggestion = (e) => {
    if (search.length !== 0) {
      store.setSearchValue(e);

      axios({
        method: "post",
        url: "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_stemtopic_v6/_search",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
        },
        data: JSON.stringify({
          query: {
            query_string: {
              query: "*" + e.trim() + "*",
              fields: field,
            },
          },
        }),
      }).then((res) => {
        const temp = [];

        for (let i of res.data.hits.hits) {
          if (store.langIndex == 0)
            temp.push({ name: i._source["topic_english"], id: i._id });
          else if (store.langIndex == 1)
            temp.push({ name: i._source["topic_urdu"], id: i._id });
          else temp.push({ name: i._source["topic_arabic"], id: i._id });
        }

        setSuggestion(temp);
      });
    } else {
      setSuggestion([]);
    }
  };

  // get roman API
  const Search = (search) => {
    const roman =
      store.langIndex == 2
        ?" https://py.minhaj.app/roman_to_arabic?input_text="
        : store.langIndex == 1
        ? "https://py.minhaj.app/roman_to_urdu?input_text="
        :"https://py.minhaj.app/to_english?input_text="

    if (search == "") {
      alert(TextTranslation.enter_something[store.langIndex]);
      return;
    }
    setLoad(true);
    axios
      .get(roman + search + "")
      .then((res) => {
        console.log("Roman MIE API", res.data);
        postSearchHistory(search);
        didYouMean(res.data);
        if (res.data.replace(/\s/g, "").length) {
          getSuggestion(res.data);
        }
        setLoad(false);
        history.push(`/result/Quran/${res.data}/${store.langIndex}`);
      })
      .catch((err) => {
        console.log("Roman Error", err);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const ac = new AbortController();
    return () => ac.abort();
  }, []);

  const searchBarStyle = {
    borderBottomLeftRadius: suggestion.length !== 0 && "1px",
    borderBottomRightRadius: suggestion.length !== 0 && "1px",
    borderTopLeftRadius: suggestion.length !== 0 && "4px",
    borderTopRightRadius: suggestion.length !== 0 && "4px",
    color:Colors.theme_c_bw[store.theme],
    backgroundColor:Colors.theme_bg_wg[store.theme] 
  };

  return (
    <Observer>
      {() => (
        <Grid container className="d-flex justify-content-center ">
          <Grid item xs={10} sm={7} md={5} lg={5} xl={4}>
            
            {store.langIndex == 0 ? (
              <div className="search-bar" style={searchBarStyle}>
                <input
                  id="search-bar-input"
                  style={{ fontFamily: "Raleway,sans-serif", fontSize: 14,
                  color:Colors.theme_c_dbw[store.theme] }}
                  placeholder={TextTranslation.search_input[store.langIndex]}
                  onChange={(text) => {
                    setSearch(text.target.value);

                    if (search.replace(/\s/g, "").length) {
                      getSuggestion(search);
                    } else {
                      setSuggestion([]);
                    }
                  }}
                  onKeyPress={(e) => {
                    {
                      if (e.key === "Enter") {
                        if (/[a-zA-Z]/g.test(search)) {
                          store.setFrom(0);
                          store.setDataArr([]);
                          if (search.replace(/\s/g, "").length) {
                            Search(search);
                          }
                        }
                      }
                    }
                  }}
                />

                <span id="main-search-bar-icon" style={{ color:Colors.theme_c_bw[store.theme]  }}>
                  {load ? (
                    <CircularProgress
                      size={15}
                      style={{ color: Colors.primary }}
                    />
                  ) : (
                    <SearchIcon
                      style={{ fontSize: 25 }}
                      onClick={() => {
                        if (/[a-zA-Z]/g.test(search)) {
                          store.setDataArr([]);
                          store.setFrom(0);
                          if (search.replace(/\s/g, "").length) {
                            Search(search);
                          }
                        }
                      }}
                    />
                  )}
                </span>
                {/* <span id="main-search-bar-icon" className="mx-2" style={{ color:Colors.theme_c_bw[store.theme] }}>
                  <FilterListIcon
                    style={{ fontSize: 25 }}
                    onClick={handleOpen}
                  />
                </span> */}
              </div>
            ) : store.langIndex == 1 ? (
              <div className="search-bar" style={searchBarStyle}>
                {/* <span id="main-search-bar-icon" className="mx-2">
                  <FilterListIcon
                    style={{ fontSize: 25 }}
                    onClick={handleOpen}
                  />
                </span> */}
                <span id="main-search-bar-icon">
                  {load ? (
                    <CircularProgress
                      size={15}
                      style={{ color: Colors.primary }}
                    />
                  ) : (
                    <SearchIcon
                      className="mx-1"
                      style={{ fontSize: 25 }}
                      onClick={() => {
                        store.setDataArr([]);
                        store.setFrom(0);

                        if (search.replace(/\s/g, "").length) {
                          Search(search);
                        }
                      }}
                    />
                  )}
                </span>
                <input
                  className="text-right mx-3"
                  style={{ fontFamily: "JameelNoori", fontSize: 16 }}
                  id="search-bar-input"
                  placeholder={TextTranslation.search_input[store.langIndex]}
                  onChange={(text) => {
                    setSearch(text.target.value);
                    if (search.replace(/\s/g, "").length) {
                      getSuggestion(search);
                    } else {
                      setSuggestion([]);
                    }
                  }}
                  onKeyPress={(e) => {
                    {
                      if (e.key === "Enter") {
                        store.setDataArr([]);
                        store.setFrom(0);
                        if (search.replace(/\s/g, "").length) {
                          Search(search);
                        }
                      }
                    }
                  }}
                />
              </div>
            ) : (
              <div className="search-bar" style={searchBarStyle}>
                {/* <span id="main-search-bar-icon" className="mx-2">
                  <FilterListIcon
                    style={{ fontSize: 25 }}
                    onClick={handleOpen}
                  />
                </span> */}
                <span id="main-search-bar-icon">
                  {load ? (
                    <CircularProgress
                      size={15}
                      style={{ color: Colors.primary }}
                    />
                  ) : (
                    <SearchIcon
                      className="mx-1"
                      style={{ fontSize: 25 }}
                      onClick={() => {
                        store.setDataArr([]);
                        store.setFrom(0);
                        if (search.replace(/\s/g, "").length) {
                          Search(search);
                        }
                      }}
                    />
                  )}
                </span>
                <input
                  className="text-right mx-3"
                  id="search-bar-input"
                  style={{ fontFamily: "Arabic", fontSize: 14 }}
                  placeholder={TextTranslation.search_input[store.langIndex]}
                  onChange={(text) => {
                    setSearch(text.target.value);
                    if (search.replace(/\s/g, "").length) {
                      getSuggestion(search);
                    } else {
                      setSuggestion([]);
                    }
                  }}
                  onKeyPress={(e) => {
                    {
                      if (e.key === "Enter") {
                        store.setDataArr([]);
                        store.setFrom(0);
                        if (search.replace(/\s/g, "").length) {
                          Search(search);
                        }
                      }
                    }
                  }}
                />
              </div>
            )}

            {suggestion.length > 0 && search.length > 0 ? (
              <Card
                className="suggestion-card"
                style={
                  store.langIndex == 0
                    ? {
                        direction: "ltr",
                        overflow: "auto",
                        boxShadow: "0 1px 6px 0 #e32636",
                        zIndex: "9999",
                        color:Colors.theme_c_dbw[store.theme],
                        backgroundColor:Colors.theme_bg_wg[store.theme]
                      }
                    : {
                        direction: "rtl",
                        textAlign: "right",
                        overflow: "auto",
                        boxShadow: "0 1px 6px 0 #e32636",
                        zIndex: "9999",
                        color:Colors.theme_c_dbw[store.theme],
                        backgroundColor:Colors.theme_bg_wg[store.theme]
                      }
                }
              >
                {suggestion
                  .filter((e) => e.name != "NA")
                  .map((data, index) => (
                    <div
                      className="suggestion-card-content"
                      key={index}
                      onClick={() => {
                        history.push(
                          `/selectedresult/Quran/${data.id}/${store.langIndex}`
                        );
                      }}
                    >
                      <SearchIcon
                        className="mx-2"
                        style={{
                          fontSize: 20,
                        }}
                      />
                      <p
                        key={index}
                        style={
                          store.langIndex == 0
                            ? {
                                fontFamily: "Raleway, sans-serif",
                                fontSize: 14,
                              }
                            : store.langIndex == 1
                            ? { fontFamily: "JameelNoori", fontSize: 18 }
                            : { fontFamily: "Arabic", fontSize: 14 }
                        }
                      >
                        {data.name}
                      </p>
                    </div>
                  ))}
              </Card>
            ) : null}
          </Grid>
        </Grid>
      )}
    </Observer>
  );
};

export default NewMainSearch;
