import React, { useContext, useState,useEffect } from "react";
import { Observer } from "mobx-react-lite";
import { Formik, Form } from "formik";
import { StoreContext } from "../../store/store";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  TextField,
  CircularProgress,
  Button,
} from "@material-ui/core";
import TextTranslation from "../../Config/TextTranslation";
import logo from "../../images/MIE_Light.png";
import Colors from "../../Config/Colors";
import * as yup from "yup";
import axios from "axios";

// styles
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20%",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const EnterPassword = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let history = useHistory();
  const store = useContext(StoreContext);
  const classes = useStyles(); // styles classes
  const [load, setLoad] = useState(false); // loading state on button

  //validation schema
  let EnterPasswordSchema = yup.object().shape({
    password: yup
      .string()
      .min(6, TextTranslation.min_password_msg[store.langIndex])
      .max(10, TextTranslation.max_password_msg[store.langIndex])
      .required(TextTranslation.required_password_msg[store.langIndex]),

    confirm_password: yup
      .string()
      .required(TextTranslation.required_confirm_password_msg[store.langIndex])

      .oneOf(
        [yup.ref("password"), null],
        TextTranslation.match_password[store.langIndex]
      ),
  });
  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    
  }, []);

  return (
    <Observer>
      {() => (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <div className="pt-5 pb-5 col-4 d-flex justify-content-center">
              <img src={logo} alt="" className="img-fluid" />
            </div>

            {/*  Form */}
            <Formik
              fullWidth
              initialValues={{
                password: "",
                confirm_password: "",
              }}
              validationSchema={EnterPasswordSchema}
              onSubmit={(values) => {
                setLoad(true);
                var bodyFormData = new FormData();
                bodyFormData.append("email", store.forgEmail);
                bodyFormData.append("pass", values.password);

                console.log(
                  "email",
                  store.forgEmail + "password",
                  values.password
                );

                axios({
                  method: "post",
                  url: "https://api.minhaj.app/forg_pass.php?",
                  data: bodyFormData,
                  headers: { "Content-Type": "multipart/form-data" },
                }).then(function (res) {
                  if (res.data == 1) {
                    alert(TextTranslation.reset_succesfully[store.langIndex]);
                    setLoad(false);
                    history.push("/login");
                  } else {
                    if (res.data == 0) {
                      alert(
                        TextTranslation.pass_change_err_occured[store.langIndex]
                      );
                    }
                    history.push("/login");
                  }
                });
              }}
            >
              {({ errors, handleChange, touched, handleBlur, isValid }) => (
                // form fields
                <Form>
                  {store.langIndex == 0 ? (
                    <TextField
                      fullWidth
                      error={errors.password && touched.password}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2"
                      type="password"
                      label={
                        TextTranslation.inp_plac_enter_new_pass[store.langIndex]
                      }
                      name="password"
                      helperText={
                        errors.password && touched.password
                          ? errors.password
                          : null
                      }
                    />
                  ) : (
                    <TextField
                      fullWidth
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "AlQalam",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "AlQalam",
                                fontSize: 16,
                              },
                            }
                      }
                      error={errors.password && touched.password}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2"
                      type="password"
                      placeholder={
                        TextTranslation.inp_plac_enter_new_pass[store.langIndex]
                      }
                      name="email"
                      helperText={
                        errors.password && touched.password
                          ? errors.password
                          : null
                      }
                    />
                  )}
                  {store.langIndex == 0 ? (
                    <TextField
                      fullWidth
                      error={
                        errors.confirm_password && touched.confirm_password
                      }
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2"
                      type="password"
                      label={
                        TextTranslation.inp_plac_enter_confirm_pass[
                          store.langIndex
                        ]
                      }
                      name="confirm_password"
                      helperText={
                        errors.confirm_password && touched.confirm_password
                          ? errors.confirm_password
                          : null
                      }
                    />
                  ) : (
                    <TextField
                      fullWidth
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "AlQalam",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "AlQalam",
                                fontSize: 16,
                              },
                            }
                      }
                      error={
                        errors.confirm_password && touched.confirm_password
                      }
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2"
                      type="password"
                      placeholder={
                        TextTranslation.inp_plac_enter_confirm_pass[
                          store.langIndex
                        ]
                      }
                      name="confirm_password"
                      helperText={
                        errors.confirm_password && touched.confirm_password
                          ? errors.confirm_password
                          : null
                      }
                    />
                  )}
                  {/* Submit button */}
                  <Button
                    className="mt-3 btn"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                    }}
                    disabled={!isValid}
                  >
                    {load ? (
                      <CircularProgress
                        size={30}
                        style={{ color: Colors.white }}
                      />
                    ) : (
                      <span
                        style={
                          store.langIndex == 0
                            ? { fontFamily: "Raleway, sans-serif" }
                            : store.langIndex == 1
                            ? { fontFamily: "JameelNoori", fontSize: 18 }
                            : { fontFamily: "AlQalam", fontSize: 18 }
                        }
                      >
                        {TextTranslation.sub[store.langIndex]}
                      </span>
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      )}
    </Observer>
  );
};

export default EnterPassword;
