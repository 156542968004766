import React, {useContext} from 'react'
import { StoreContext } from '../store/store'
import { Observer } from 'mobx-react-lite'
import Colors from '../Config/Colors'

const Heading = ({ children, styles }) => {
const store = useContext(StoreContext)
  
return (
  <Observer>
    {
      ()=>(
        <div>
        <h5 style={{fontFamily: store.langIndex == 0 ? 'Raleway,sans-serif' : store.langIndex == 1 ? 'JameelNoori' : 'Arabic',textAlign:'center', color: Colors.primary ,...styles }}>
          {children}
        </h5>
      </div>
      )
    }
  </Observer>
       
  )
}
export default Heading
