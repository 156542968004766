import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Observer } from "mobx-react-lite";
import { StoreContext } from "../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import RemoveCircleOutlineSharpIcon from "@material-ui/icons/RemoveCircleOutlineSharp";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import MuiAlert from "@material-ui/lab/Alert";
import Colors from "../../Config/Colors";
import axios from "axios";
import Url from "../../Config/Url";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 350,
  },
  loader: {
    color: Colors.primary,
  },
  icon: {
    fontSize: 20,
    "&:hover": {
      color: Colors.primary,
      cursor: "pointer",
    },
  },
}));

const SpeechBookmarkResult = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const classes = useStyles();
  let ParamsFromSpeechTitle = useParams(); // getting params from speech bookmark title
  const store = useContext(StoreContext);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [copied, setCopied] = useState(false);
  const [bookmarkSpeechesSelected, setbookmarkSpeechesSelected] = useState([]);

  // alert function
  const Alert = (props) => (
    <MuiAlert elevation={6} variant="filled" {...props} />
  );

  // alert close function
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // copy to clipboard function
  const CopyData = (data) => {
    console.log("speech bookmark result copy", data);
    return `https://www.youtube.com/embed/${data.yt_id}?start=${parseInt(
      data.start_time
    )}`;
  };

  // get bookmarked speeches
  const getSelectedBookmarkSpeechesTitle = () => {
    var data = new FormData();
    data.append("title", ParamsFromSpeechTitle.title);
    axios({
      method: "post",
      url: `${Url.link}/get_speeches_bookmark_on_title.php`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
         uuid: store.id,
      },
    }).then((res) => {
      console.log("Speeches bookmark result", res.data.MDE_API[0].MSG);
      setbookmarkSpeechesSelected(res.data.MDE_API[0].MSG);
      setLoader(false);
    });
  };

  // delete bookmark api
  const deleteSpeech = (title, video_id, start_time, lang_id, index) => {
    var data = new FormData();
    data.append("title", title);
    data.append("video_id", video_id);
    data.append("start_time", start_time);
    data.append("lang_id", lang_id);
    console.log(data);
    axios({
      method: "post",
      url: `${Url.link}/delete_speeches_bookmark.php`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: store.id,
      },
    })
      .then((response) => {
        console.log("Delete Bookmark", response.data.MDE_API[0]);

        if (response.data.MDE_API[0].success == 1) {
          const Delete = bookmarkSpeechesSelected.filter((e) => e != index);
          setbookmarkSpeechesSelected(Delete);
          // alert("deleted successfully");
          if (window.confirm("Are you sure to delete the video?")) {
            getSelectedBookmarkSpeechesTitle();
          }
        } else {
          alert("Error while deleting");
          getSelectedBookmarkSpeechesTitle();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    getSelectedBookmarkSpeechesTitle();
    return () => ac.abort();
  }, []);

  return (
    <Observer>
      {() => (
        <>
          <CommonNavbar />
          {loader ? (
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              directioin="column"
              style={{ minHeight: "80vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="center">
              <Snackbar
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  copied
                </Alert>
              </Snackbar>

              {bookmarkSpeechesSelected.map((data, index) => (
                <Grid
                  item
                  xs={10}
                  sm={8}
                  md={6}
                  lg={4}
                  xl={3}
                  className="mb-2 pt-4 d-flex"
                  key={index}
                  style={{
                    justifyContent: "space-around",
                  }}
                >
                  {bookmarkSpeechesSelected.length < 1 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No Video Here
                    </div>
                  ) : (
                    <Card className={classes.root}>
                      <CardActionArea key={index}>
                        <CardMedia
                          component="iframe"
                          alt=""
                          height="200"
                          image={`https://www.youtube.com/embed/${
                            data.yt_id
                          }?start=${parseInt(data.start_time)}`}
                          title=""
                          allowFullScreen={true}
                        />

                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="caption"
                            component="h2"
                          >
                            {data.title}
                          </Typography>
                        </CardContent>
                      </CardActionArea>

                      <CardActions
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <CopyToClipboard
                          text={CopyData(data)}
                          onCopy={() => {
                            setCopied(true);
                            setOpen(true);
                          }}
                        >
                          <FileCopyOutlinedIcon className={classes.icon} />
                        </CopyToClipboard>

                        <RemoveCircleOutlineSharpIcon
                          className={classes.icon}
                          onClick={() =>
                            deleteSpeech(
                              ParamsFromSpeechTitle.title,
                              data.video_id,
                              data.start_time,
                              data.lang_id,
                              index
                            )
                          }
                        />
                      </CardActions>
                    </Card>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </Observer>
  );
};

export default SpeechBookmarkResult;
