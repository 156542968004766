import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { StoreContext } from "../../store/store";
import { Observer } from "mobx-react-lite";
import Colors from "../../Config/Colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const LinearLoader = () => {
  const classes = useStyles();
  const store = useContext(StoreContext);
  document.body.style.backgroundColor=Colors.theme_bg_wg[store.theme];
  document.body.style.color=Colors.theme_c_dbw[store.theme]

  return (
  
  <Observer>
      {() =>
        store.linearLoader ? (
          <div className={classes.root}>
            <LinearProgress color="secondary" />
          </div>
        ) : null
      }
    </Observer>
  );
};

export default LinearLoader;
