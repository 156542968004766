import React from "react";

const EngText = ({ children, styles }) => {

  return (
        <>
          <div style={{ fontFamily: "Raleway,sans-serif", textAlign:'justify', padding:'5px 5px' ,fontSize: 16, ...styles }}>
            {children}
          </div>
        </>
  );
};
export default EngText;
