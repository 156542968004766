import React, {useContext,useEffect} from "react";
import { Grid } from "@material-ui/core";
import { Observer } from "mobx-react-lite";
import Heading from "../../CustomComponents/Heading";
import Colors from "../../Config/Colors";
import { StoreContext } from "../../store/store";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import TextTranslation from "../../Config/TextTranslation";

const PrivacyPolicy = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const store = useContext(StoreContext);
  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
  }, []);

  return (
    <Observer>
      {() => (
        <>
          <CommonNavbar />
          <Grid
            container
            style={{
              justifyContent: "space-around",
              color:Colors.theme_c_dbw[store.theme],
              backgroundColor:Colors.theme_bg_wg[store.theme]
            }}
          >
            <Grid item xs={10} sm={10} md={10} lg={8} xl={7}>
              <Heading 
              styles={{
                margin: '20px 0',  
                color:Colors.theme_c_dbw[store.theme],
                backgroundColor:Colors.theme_bg_wg[store.theme],  
                }}
                style={store.langIndex == 0
                  ? {  textAlign: "left",fontFamily: "Raleway, sans-serif", color: Colors.primary }
                  : 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                  ? { fontFamily: "JameelNoori", color: Colors.primary }
                  : { fontFamily: "AlQalam", color: Colors.primary }}>
                  
                  {TextTranslation.privacy[store.langIndex]}</Heading>
              <div
                style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                  ? {
                      lineHeight:1.8,
                        textAlign: "right",
                        fontFamily: "JameelNoori",
                        fontSize: 16,
                      
                    }
                  : {
                      lineHeight:1.8,
                        textAlign: "right",
                        fontFamily: "Arabic",
                        fontSize: 16,
                      
                    }}
              >
                <div  style={store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                             
                            }}>
                  <small style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                            lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                             
                            }}>
                    {TextTranslation.privacy_policy_text_1[store.langIndex]}
                  
                  </small>
                  <br />
                  <small style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                            lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                             
                            }}>
                    {TextTranslation.privacy_policy_text_2[store.langIndex]}
                  </small>
                  <br />
                  <small style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                              lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                            
                            }
                          : {
                              lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              
                            }}>
                    {TextTranslation.privacy_policy_text_3[store.langIndex]}
                  </small>
                  <br />
                  <small style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                              lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              
                            }}>
                  {TextTranslation.privacy_policy_text_4[store.langIndex]}
                  </small>
                  <br />
                  <small style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                              lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                              lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                             
                            }}>
                  {TextTranslation.privacy_policy_text_5[store.langIndex]}
                  </small>
                  <br />
                  <small style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                            
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              
                            }}>
                  {TextTranslation.privacy_policy_text_6[store.langIndex]}
                  </small>
                  <br />
                  <small style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                            lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              
                            }}>
                  {TextTranslation.privacy_policy_text_7[store.langIndex]}
                  </small>
                  <br />
                  <small style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                            lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                            
                            }}>
                    <b style={ 
                      store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {display:'flex',
                            flexDirection:'row-reverse',
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                             
                            }}> {TextTranslation.privacy_policy_date[store.langIndex]}</b>
                  </small>
                </div>
              </div>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={8} xl={7}>
              <Heading styles={{margin:'20px 0'}}> {TextTranslation.term_and_condition[store.langIndex]}</Heading>
              <div>
                <h6 style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                             }}>
                  
                  <b  style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                                display:'flex',
                                flexDirection:'row-reverse',
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            }}>
                              <span>{TextTranslation.no1[store.langIndex]}</span>.
                              <span style={ 
                                store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                                display:'flex',
                                flexDirection:'row-reverse',
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            }}>{TextTranslation.terms[store.langIndex]}</span>
                          </b>
                </h6>
                <div
                  // className="text-justify"
                  style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                    ? {
                       lineHeight:1.8,
                          textAlign: "right",
                          fontFamily: "JameelNoori",
                          fontSize: 16,
                      
                      }
                    : {
                       lineHeight:1.8,
                          textAlign: "right",
                          fontFamily: "Arabic",
                          fontSize: 16,
                       
                      }}
                >
                 {TextTranslation.terms_text[store.langIndex]}
                </div>
                <div>
                  <h6 className="mt-1" style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                             
                            }}>
                    <b  style={ 
                      store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {display:'flex',
                            flexDirection:'row-reverse',
                            lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                
                              
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            
                            }}>
                              <span>{TextTranslation.no2[store.langIndex]}</span>.
                              <span   style={ 
                                store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                                display:'flex',
                                flexDirection:'row-reverse',
                               
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                               
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            }}>{TextTranslation.use_license[store.langIndex]}</span>
                              </b>
                  </h6>
                  <div
                    // className="text-justify"
                    style={ 
                      store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                      ? {
                         lineHeight:1.8,
                            textAlign: "right",
                            fontFamily: "JameelNoori",
                            fontSize: 16,
                         
                        }
                      : {
                         lineHeight:1.8,
                            textAlign: "right",
                            fontFamily: "Arabic",
                            fontSize: 16,
                          
                        }}
                  >
                     {TextTranslation.use_license_text_start[store.langIndex]}
                    <div>
                      <small style={ 
                        store.langIndex == 0
                    ? {  textAlign: "left",fontSize: 16,fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                              lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                            
                            }} > 
                        {TextTranslation.use_license_list_1[store.langIndex]}
                      </small>
                      <br />
                      <small style={ 
                        store.langIndex == 0
                    ? {  textAlign: "left",fontSize: 16,fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                               lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                              lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                           
                            }} >
                      {TextTranslation.use_license_list_2[store.langIndex]}
                      </small>
                      <br />
                      <small style={ 
                        store.langIndex == 0
                    ? {  textAlign: "left",fontSize: 16,fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                              lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                            lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                           
                            }} >
                      {TextTranslation.use_license_list_3[store.langIndex]}
                      </small>
                      <br />
                      <small style={ 
                        store.langIndex == 0
                    ? {  textAlign: "left",fontSize: 16,fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                            
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                            
                            }} >
                      {TextTranslation.use_license_list_4[store.langIndex]}
                      </small>
                      <br />
                      {/* <small style={ 
                        store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              
                            }
                          : {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              
                            }}>
                      {TextTranslation.use_license_list_5[store.langIndex]}
                      </small> */}
                    </div>
                    {TextTranslation.use_license_text_end[store.langIndex]}
                  </div>
                </div>
                <h6 className="mt-1" style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                              lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                              lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                             
                            }}>
                  <b  style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {display:'flex',
                            flexDirection:'row-reverse',
                            lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                
                              
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            
                            }}>
                              <span>{TextTranslation.no3[store.langIndex]}</span>.
                              <span style={ 
                                store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                                display:'flex',
                                flexDirection:'row-reverse',
                               
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                               
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            }}>{TextTranslation.disclaimer[store.langIndex]}</span>
                              </b>
                </h6>
                <p
                  // className="text-justify"
                  style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                    ? {
                        lineHeight:1.8, 
                          textAlign: "right",
                          fontFamily: "JameelNoori",
                          fontSize: 16,
                        
                      }
                    : {
                        lineHeight:1.8, 
                          textAlign: "right",
                          fontFamily: "Arabic",
                          fontSize: 16,
                        
                      }}
                >
                 {TextTranslation.disclaimer_text_1[store.langIndex]}
                  <br />
                  {TextTranslation.disclaimer_text_2[store.langIndex]}
                </p>

                <h6 className="mt-1" style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                
                              
                            }
                          : {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                              
                            }}>
                  <b   style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {display:'flex',
                            flexDirection:'row-reverse',
                            lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                
                              
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            
                            }}>
                            
                              <span>{TextTranslation.no4[store.langIndex]}</span>.
                              <span style={ 
                                store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                                display:'flex',
                                flexDirection:'row-reverse',
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            }}>{TextTranslation.limitations[store.langIndex]}</span>
                               </b>
                </h6>
                <p
                  // className="text-justify"
                  style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              
                            }
                          : {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              
                            }}
                >
                 {TextTranslation.limitations_text[store.langIndex]}
                </p>

                <h6 className="mt-1" style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                             
                            }}>
                  <b  style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {display:'flex',
                            flexDirection:'row-reverse',
                            lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                
                              
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            
                            }}>
                              <span>{TextTranslation.no5[store.langIndex]}</span>.
                    <span   style={ 
                      store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                                display:'flex',
                                flexDirection:'row-reverse',
                               
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                               
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            }}>
                    {TextTranslation.accuracy_of_materials[store.langIndex]}
                    </span >
                   </b>
                </h6>
                <p
                  // className="text-justify"
                  style={ 
                        store.langIndex == 0
                        ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                        :store.langIndex == 1
                          ? {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              
                            }
                          : {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              
                            }}
                >
                  {TextTranslation.accuracy_of_materials_text[store.langIndex]}
                </p>

                <h6 className="mt-1" style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                             
                            }}>
                  <b  style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {display:'flex',
                            flexDirection:'row-reverse',
                            lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                
                              
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            
                            }}>
                              <span>{TextTranslation.no6[store.langIndex]}</span>.
                              <span style={ 
                                store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                                display:'flex',
                                flexDirection:'row-reverse',
                                
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                                
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            }}>{TextTranslation.links[store.langIndex]}</span></b>
                </h6>
                <p
                  // className="text-justify"
                  style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              
                            }
                          : {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              
                            }}
                >
                  {TextTranslation.links_text[store.langIndex]}
                </p>

                <h6 className="mt-1" style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                             
                            }}>
                  <b  style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {display:'flex',
                            flexDirection:'row-reverse',
                            lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                
                              
                            }
                          : {
                            display:'flex',
                            flexDirection:'row-reverse',
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            
                            }}>
                              <span>{TextTranslation.no7[store.langIndex]}</span>.
                              <span style={ 
                                store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                                display:'flex',
                                flexDirection:'row-reverse',
                                
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                                
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            }}>{TextTranslation.modifications[store.langIndex]}</span>
                              </b>
                </h6>
                <p
                  // className="text-justify"
                  style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              
                            }
                          : {
                            
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              
                            }}
                >
                  {TextTranslation.modifications_text[store.langIndex]}
                </p>

                <h6 className="mt-1" style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                             
                            }
                          : {
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                             
                            }}>
                  <b  style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {display:'flex',
                            flexDirection:'row-reverse',
                            lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                
                              
                            }
                          : {
                            display:'flex',
                            flexDirection:'row-reverse',
                             lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            
                            }}>
                              <span>{TextTranslation.no8[store.langIndex]}</span>.
                              <span style={ 
                                store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                                display:'flex',
                                flexDirection:'row-reverse',
                               
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                               
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            }}>{TextTranslation.governing_law[store.langIndex]}</span></b>
                </h6>
                <p
                  // className="text-justify"
                  style={ 
                    store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" }
                    :store.langIndex == 1
                          ? {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              
                            }
                          : {
                              lineHeight:1.8, 
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              
                            }}
                >
                  {TextTranslation.governing_law_text[store.langIndex]}
                </p>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </Observer>
  );
};

export default PrivacyPolicy;
