import React, { useContext, useState,useEffect } from "react";
import { Observer } from "mobx-react-lite";
import { Formik, Form } from "formik";
import { StoreContext } from "../../store/store";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  TextField,
  CircularProgress,
  Button,
} from "@material-ui/core";
import TextTranslation from "../../Config/TextTranslation";
import Colors from "../../Config/Colors";
import logo from "../../images/MIE_Light.png";
import * as yup from "yup";
import axios from "axios";

// styles
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20%",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const EnterEmail = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let history = useHistory();
  const store = useContext(StoreContext);
  const classes = useStyles(); // styles classes
  const [load, setLoad] = useState(false); // loading state on button

  //validation schema
  let EnterEmailSchema = yup.object().shape({
    email: yup
      .string()
      .email(TextTranslation.valid_email_msg[store.langIndex])
      .required(TextTranslation.required_email_msg[store.langIndex]),
  });
  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    
  }, []);

  return (
    <Observer>
      {() => (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <div className="pt-5 pb-5 col-4 d-flex justify-content-center">
              <img src={logo} alt="" className="img-fluid"/>
            </div>

            {/*  Form */}
            <Formik
              fullWidth
              initialValues={{
                email: "",
              }}
              validationSchema={EnterEmailSchema}
              onSubmit={(values) => {
                console.log(values.email);
                setLoad(true);

                var formdata = new FormData();
formdata.append("email", values.email);
var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};
console.log(formdata)
fetch("https://api.minhaj.app/forg_pass.php?email="+values.email, requestOptions)
  .then(response => response.text())
  .then(res => {
    console.log(res)
    store.setForgEmail(values.email);
    alert(
      TextTranslation.code_send_to_your_email[store.langIndex]
    );
    history.push("/entercode");
  })
  .catch(error => console.log('error', error));
                // axios
                //   .get(
                //     "https://api.minhaj.digital/forg_pass.php?email=" +
                //       values.email +
                //       ""
                //   )
                //   .then((res) => {
                //     console.log(res.data);
                //     store.setForgEmail(values.email);
                //     alert(
                //       TextTranslation.code_send_to_your_email[store.langIndex]
                //     );
                //     history.push("/entercode");
                //   })
                //   .catch((err) => {
                //     console.log(err);
                //   });
              }}
            >
              {({ errors, handleChange, touched, handleBlur, isValid }) => (
                // form fields
                <Form>
                  {store.langIndex == 0 ? (
                    <TextField
                      fullWidth
                      error={errors.email && touched.email}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2"
                      label={TextTranslation.inp_plac_email[store.langIndex]}
                      name="email"
                      helperText={
                        errors.email && touched.email ? errors.email : null
                      }
                    />
                  ) : (
                    <TextField
                      fullWidth
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                              },
                            }
                      }
                      error={errors.email && touched.email}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2"
                      placeholder={
                        TextTranslation.inp_plac_email[store.langIndex]
                      }
                      name="email"
                      helperText={
                        errors.email && touched.email ? errors.email : null
                      }
                    />
                  )}
                  {/* Submit button */}
                  <Button
                    className="mt-3 btn"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                    }}
                    disabled={!isValid}
                  >
                    {load ? (
                      <CircularProgress
                        size={30}
                        style={{ color: Colors.white }}
                      />
                    ) : (
                      <span
                        style={
                          store.langIndex == 0
                            ? { fontFamily: "Raleway, sans-serif" }
                            : store.langIndex == 1
                            ? { fontFamily: "JameelNoori", fontSize: 18 }
                            : { fontFamily: "Arabic", fontSize: 18 }
                        }
                      >
                        {TextTranslation.sub[store.langIndex]}
                      </span>
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      )}
    </Observer>
  );
};

export default EnterEmail;
