import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { useMediaQuery } from "react-responsive";
import { Observer } from "mobx-react-lite";
import MainNavbar from "../../Navbars/MainNavbar/MainNavbar";
import TextTranslation from "../../Config/TextTranslation";
import AppBar from "@material-ui/core/AppBar";
import Colors from "../../Config/Colors";
import logo from "../../images/MIE_Light.png";
import moment from "moment";
import axios from "axios";
import Url from "../../Config/Url";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    display: "block",
  },
  appbar: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  logo: {
    height: "50px",
   paddingTop:"4px",
    cursor: "pointer",
    backgroundSize: "contain",
    [theme.breakpoints.down("md")]: {
      height: "40px",

      marginRight: 10,
    },
  },
  autocomplete: {
    width: 600,
    height: 40,
    border: "2px solid #e32636",
    backgroundColor: Colors.white,
    [theme.breakpoints.down("md")]: {
      width: 500,
    },
    [theme.breakpoints.down("sm")]: {
      width: 350,
      paddingRight: 5,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      paddingRight: 5,
    },
  },
}));

const SearchNavbar = () => {
  const store = useContext(StoreContext);
  const classes = useStyles();
  const history = useHistory();
  const getParamFromMain = useParams();
  const [search1, setSearch1] = useState();
  const [smallSearch, setSmallSearch] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 430px)" });

  // post search history API
  const postSearchHistory = (search, searchType) => {
    var PostHistoryForm = new FormData();

    PostHistoryForm.append("search", search);
    PostHistoryForm.append("1", searchType);
    PostHistoryForm.append("lang_id", parseInt(store.langIndex) + 1);
    PostHistoryForm.append("date", moment().format("YYYY-MM-DD HH:mm:ss"));
    {
      store.id !== null &&
        axios({
          method: "post",
          url: `${Url.link}/store_history.php?`,
          data: PostHistoryForm,
          headers: {
            "Content-Type": "multipart/form-data",
            uuid: localStorage.getItem("@uuid"),
          },
        })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log("history" + error);
          });
    }
  };

  // did you mean
  // const didYouMean = (search) => {
  //   fetch(
  //     "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_stemtopic_v4/_search",
  //     {
  //       method: "POST", // or 'PUT'
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
  //       },
  //       body: JSON.stringify({
  //         size: 0,

  //         suggest: {
  //           text: search.toLowerCase(),
  //           suggestion: {
  //             phrase: {
  //               field:
  //                 store.langIndex == 0
  //                   ? "eng_ref"
  //                   : store.langIndex == 1
  //                   ? "urdu_ref"
  //                   : "arabic_ref",

  //               real_word_error_likelihood: 0.95,

  //               max_errors: 0.5,

  //               confidence: 0,

  //               highlight: {
  //                 pre_tag: "<em>",

  //                 post_tag: "</em>",
  //               },

  //               collate: {
  //                 query: {
  //                   inline: {
  //                     match: {
  //                       "": "",
  //                     },
  //                   },
  //                 },

  //                 params: {
  //                   field_name:
  //                     store.langIndex == 0
  //                       ? "eng_ref"
  //                       : store.langIndex == 1
  //                       ? "urdu_ref"
  //                       : "arabic_ref",
  //                 },

  //                 prune: true,
  //               },
  //             },
  //           },
  //         },
  //       }),
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       if (!responseJson.suggest.suggestion[0].options[0]) {
  //         store.setDidYouMeanExist(false);
  //       } else {
  //         store.setChangeDidYouMean(
  //           responseJson.suggest.suggestion[0].options[0].text
  //         );
  //         store.setDidYouMeanExist(true);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Suggest" + error);
  //     });
  // };

  // get roman API

  const Search = (search1) => {
    const roman =
      store.langIndex == 2
        ? "https://py.minhaj.app/roman_to_arabic?input_text="
        : store.langIndex == 1
        ? "https://py.minhaj.app/roman_to_urdu?input_text="
        : "https://py.minhaj.app/to_english?input_text=";

    if (search1 == "") {
      alert(TextTranslation.enter_something[store.langIndex]);
      return;
    }

    axios
      .get(roman + search1 + "", {
        headers: {
          Authorization: btoa("bftech:bftechAPI"),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        store.setDataArr([]);
        postSearchHistory(search1);
        history.push(`/result/Quran/${res.data}`);
        //didYouMean(res.data);
        //getData(res.data);
      })
      .catch((err) => {
        console.log("Roman Error", err);
      });
  };

  const searchbar = () => {
    setSmallSearch(true);
  };

  useEffect(() => {
    const ac = new AbortController();
    window.scrollTo(0, 0);
    return () => ac.abort();
  }, [isMobile]);

  return (
    <Observer>
      {() => (
        <>
          <div className={(classes.root, "mb-2 pb-5")}>
            <AppBar
              position="fixed"
              style={{
                backgroundColor: Colors.white,
                boxShadow: "0px 0px 0px white",
              }}
            >
              <Toolbar className="d-block">
              <div className="d-flex p-3" >
                {!isMobile && (
                  <Typography
                    // className={classes.title}
                    style={{ color: Colors.primary }}
                    noWrap
                    
                  >
                    <img
                      className={classes.logo}
                      src={logo}
                      alt=""
                      onClick={() => history.push("/")}
                    />
                   
                  </Typography>
                )}
              <MainNavbar />
              </div>
{/*               
                {!smallSearch && isMobile && (
                  <Typography
                    className={classes.title}
                    style={{ color: Colors.primary }}
                    noWrap
                  >
                    <img
                      className={classes.logo}
                      src={logo}
                      alt=""
                      onClick={() => history.push("/")}
                    />
                  </Typography>
                )}
               */}

                <div
                  className={(classes.appbar,"p-2")}
                  style={{
                    backgroundColor: Colors.white,
                    display: "flex",
                    
                    justifyContent: "space-between",
                  }}
                >
                  {store.smallSearch && isMobile && (
                    <>
                    
                      <input
                        className={classes.autocomplete}
                        style={
                          store.langIndex == 0
                            ? {
                                textAlign: "left",
                                paddingLeft: 10,
                                fontFamily: "Raleway, sans-serif",
                                fontSize: 16,
                              }
                            : store.langIndex == 1
                            ? {
                                textAlign: "right",
                                paddingRight: 10,
                                fontFamily: "JameelNoori",
                              }
                            : {
                                textAlign: "right",
                                paddingRight: 10,
                                fontFamily: "Arabic",
                              }
                        }
                        type="text"
                        placeholder={
                          TextTranslation.search_placeholder[store.langIndex]
                        }
                    
                        defaultValue={getParamFromMain.search}
                        onChange={(e) => setSearch1(e.target.value)}
                        onKeyPress={(e) => {
                          {
                            if (e.key === "Enter") {
                              Search(search1);
                             
                            }
                          }
                        }}
                      />
                      <div
                        className="icon"
                        style={{
                          color: Colors.primary,
                        }}
                        onClick={() => store.setSmallSearch(false)}
                      >
                        <i class="far fa-times-circle" />
                      </div>
                    </>
                  )}
                

                  {!isMobile && (
                    <input
                      className={classes.autocomplete}
                      style={
                        store.langIndex == 0
                          ? {
                              textAlign: "left",
                              paddingLeft: 10,
                              fontFamily: "Raleway, sans-serif",
                              fontSize: 16,
                            }
                          : store.langIndex == 1
                          ? {
                              textAlign: "right",
                              paddingRight: 10,
                              fontFamily: "JameelNoori",
                            }
                          : {
                              textAlign: "right",
                              paddingRight: 10,
                              fontFamily: "Arabic",
                            }
                      }
                      type="text"
                      placeholder={
                        TextTranslation.search_placeholder[store.langIndex]
                      }
                      defaultValue={getParamFromMain.search}
                      onChange={(e) => setSearch1(e.target.value)}
                      onKeyPress={(e) => {
                        {
                          if (e.key === "Enter") {
                            Search(search1);
                          }
                        }
                      }}
                    />
                  )}
                </div>
                {/* {!smallSearch && isMobile && (
                  <>
                    <div
                      className="icon mt-1"
                      style={{ color: Colors.primary }}
                      onClick={searchbar}
                    >
                      <i className="fas fa-search fa-sm mx-1" />
                    </div>
                    <MainNavbar />
                  </>
                )}
                {!isMobile && <MainNavbar />} */}
              </Toolbar>
            </AppBar>
          </div>
        </>
      )}
    </Observer>
  );
};

export default SearchNavbar;
