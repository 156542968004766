import React from "react";

const ArabicText = ({ children, styles }) => {
  return (
    <>
      <div style={{ fontFamily: "Arabic", textAlign:'right', fontSize: 18, ...styles }}>
        {children}
      </div>
    </>
  );
};
export default ArabicText;
