import React from "react";

const UrduText = ({ children, styles }) => {
  return (
    <>
      <div style={{ fontFamily: "JameelNoori", textAlign:'right' , fontSize: 18, ...styles }}>
        {children}
      </div>
    </>
  );
};
export default UrduText;
