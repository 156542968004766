import { StoreProvider } from "./store/store";
import LinearLoader from "./Components/Loader/LinearLoader";
import Routes from "./Components/Routes/Routes";



function App() {

  return (
    <>
      <StoreProvider>
        <>
        <LinearLoader />
        <Routes />
        </>
      </StoreProvider>
    </>
  );
}

export default App;
