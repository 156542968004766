import React, { useContext, useState,useEffect } from "react";
import {
  Container,
  FormControlLabel,
  Avatar,
  Typography,
  CssBaseline,
  CircularProgress,
  Snackbar,
  Checkbox,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { Observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import TextTranslation from "../../Config/TextTranslation";
import MuiAlert from "@material-ui/lab/Alert";
import Colors from "../../Config/Colors";
import * as yup from "yup";
import axios from "axios";
import CodeVerification from "./CodeVerification";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";

//alert function
const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

// styles
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop:'20%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-input': {
      borderRadius: "7px",
      backgroundColor: Colors.grey,
      borderColor: Colors.inputBorder,
    },
  },
})(TextField);

const Register = (props) => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let history = useHistory();
  const store = useContext(StoreContext);
  const classes = useStyles(); // styles classes
  const [load, setLoad] = useState(false); // loading state on button
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState("");
  const [term, setTerm] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [emailValidState, setEmailValidState] = useState(0); // state for changing view email, code, register form
  const [loader, setLoader] = useState(false);
  const [validEmailValues, setValidEmailValues] = useState("");
  const [emailState, setEmailState]= useState('') // 

 

  //validation schema
  let SignupSchema = yup.object().shape({
    UserName: yup
      .string()
      .min(3, TextTranslation.min_name_msg[store.langIndex])
      .required(TextTranslation.req_name_msg[store.langIndex]),
    email: yup
      .string()
      .email()
      .required(TextTranslation.required_email_msg[store.langIndex]),
    phonenum: yup
      .string()
      .min(8)
      .required(TextTranslation.req_cellno_msg[store.langIndex]),
    password: yup
      .string()
      .min(6, TextTranslation.min_password_msg[store.langIndex])
      .required(TextTranslation.required_password_msg[store.langIndex]),
    confirmpassword: yup
      .string()
      .required(TextTranslation.required_confirm_password_msg[store.langIndex])
      .oneOf(
        [yup.ref("password")],
        TextTranslation.match_password[store.langIndex]
      ),
  });

  // alert close function
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpen1(false);
  };

  const setEmailValidStateFromChild=(count)=>{
    setEmailValidState(count)
  }
  const handlePrivacy = () => {
    console.log("doing something");
    const win = window.open("/privacy", "_blank");
    win.focus();
  }
  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
  }, []);

  return (
    <Observer>
      {() => (
        <>
        <CommonNavbar/>
        <Container component="main" maxWidth="xs" >
          
          {/* signup alert Snackbar */}
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity={success == 0 ? "error" : "success"}
            >
              {msg}
            </Alert>
          </Snackbar>

          {/* checkbox alert Snackbar */}
          <Snackbar open={open1} autoHideDuration={1000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity={success === 0 ? "error" : "success"}
            >
              {TextTranslation.acceptTerm[store.langIndex]}
            </Alert>
          </Snackbar>

          <CssBaseline />
          <div className={classes.paper}>
           
              <h1 className="font-weight-bold"
                style={
                  store.langIndex == 0
                    ? { fontFamily: "Raleway, sans-serif" }
                    : store.langIndex == 1
                    ? { fontFamily: "JameelNoori" }
                    : { fontFamily: "Arabic" }
                }
              >
                {TextTranslation.register[store.langIndex]}
              </h1>
              <Typography className="text-center py-3"
              style={
                  store.langIndex == 0
                    ? { fontFamily: "Raleway, sans-serif" }
                    : store.langIndex == 1
                    ? { fontFamily: "JameelNoori",fontSize:19 }
                    : { fontFamily: "Arabic",fontSize:19 }
                }>
                  {TextTranslation.verify_email_screen_string[store.langIndex]}
                
              </Typography>
            {/* Email verification Form */}
            {
              emailValidState == 0 ?
              <Formik fullWidth
               initialValues={{
                 vemail: ''
               }}
               onSubmit={values=>{
                 var data = new FormData();
                 data.append('email', values.vemail);
                 setEmailState(values.vemail)
                 setLoader(true)
                 axios({
                  method: 'post',
                  url: 'https://api.minhaj.app/email_verification.php',
                  data: data,
                  headers: {'Content-Type': 'multipart/form-data'},
                })
                .then(function (response) {
                  console.log(JSON.stringify(response.data));
                  if(response.data.MDE_API[0].success == 0){

                    setMsg(response.data.MDE_API[0].MSG)
                    setOpen(true)
                    setSuccess(0)

                  }else if (response.data.MDE_API[0].success == 1){
                    setMsg(response.data.MDE_API[0].MSG)
                    setOpen(true)
                    setEmailValidState(1);
                    setValidEmailValues(values.vemail);
                    setSuccess(1)
                  }
                  setLoader(false)
                })
                .catch(function (error) {
                  console.log(error);
                  
                })
               }}
               validationSchema={yup.object().shape({
                vemail: yup
                  .string()
                  .email(TextTranslation.valid_email_msg[store.langIndex])
                  .required(TextTranslation.required_email_msg[store.langIndex]),
  
              })}
              >
                {({
              values,
              handleChange,
              errors,
              touched,
              isValid,
              handleSubmit, 
              handleBlur, 
              }) =>(
              <>
              {store.langIndex == 0 ? (
                <CssTextField
                  fullWidth
                  error={errors.vemail && touched.vemail}
                  variant="outlined"
                  onChange={handleChange}
                  value={values.vemail}
                  onBlur={handleBlur}
                  className="mb-2"
                  label={TextTranslation.inp_plac_email[store.langIndex]}
                  name="vemail"
                  helperText={
                    errors.vemail && touched.vemail ? errors.vemail : null
                  }
                />
               
              ) : (
                <CssTextField
                  fullWidth
                  error={errors.vemail && touched.vemail}
                  inputProps={
                    store.langIndex == 1
                      ? {
                          min: 0,
                          style: {
                            textAlign: "right",
                            color: Colors.primary,
                            fontFamily: "JameelNoori",
                            fontSize: 18,
                          },
                        }
                      : {
                          min: 0,
                          style: {
                            textAlign: "right",
                            color: Colors.primary,
                            fontFamily: "Arabic",
                            fontSize: 18,
                          },
                        }
                  }
                  FormHelperTextProps={
                    store.langIndex == 1
                      ? {
                          style: {
                            textAlign: "right",
                            fontFamily: "JameelNoori",
                            fontSize: 16,
                          },
                        }
                      : {
                          style: {
                            textAlign: "right",
                            fontFamily: "Arabic",
                          },
                        }
                  }
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="mb-2"
                    value={values.vemail}
                  placeholder={
                    TextTranslation.inp_plac_email[store.langIndex]
                  }
                  name="vemail"
                  helperText={
                    errors.vemail && touched.vemail ? errors.vemail : null
                  }
                />
                )}
                <div className="col-12 d-flex">
                  <div className="col-1 p-0">
                  <input type="checkbox" className="pt-1" name="policy" id="" />
                  </div>
                  <div className="col font-eng font-size-1 p-0"  onClick={() => history.push("/privacy")} style={ 
                    store.langIndex == 0 ? {  textAlign: "left",}
                    
                    :store.langIndex == 1
                          ? {
                              
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                
                             
                            }
                          : {
                            
                                textAlign: "right",
                                fontFamily: "Arabic",
                                
                            
                            }}>
                  {TextTranslation.register_terms_n_policy[store.langIndex]}
        
                  </div>
       
                </div>
                 
                <Button
                    className="mt-3 mb-3 btn p-3"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                    }}
                    disabled={!isValid}
                    onClick={handleSubmit}
                  >
                    {loader && isValid ? (
                      <CircularProgress
                        size={30}
                        style={{ color: Colors.white }}
                      />
                    ) : (
                      <span
                        style={
                          store.langIndex == 0
                            ? { fontFamily: "Raleway, sans-serif" }
                            : store.langIndex == 1
                            ? { fontFamily: "JameelNoori", fontSize: 18 }
                            : { fontFamily: "Arabic", fontSize: 18 }
                        }
                      >
                        {TextTranslation.validate_email_signup[store.langIndex]}
                      </span>
                    )}
                  </Button>
                </>
            )}
               </Formik>

              //  code verification form
               :  emailValidState == 1 ?
            <CodeVerification email={emailState} setEmailValidStateFromChild={setEmailValidStateFromChild} />

            // register form
               : emailValidState == 2 ? 
            <Formik
              fullWidth
              initialValues={{
                UserName: "",
                email: emailState,
                code:"",
                phonenum: "",
                password: "",
                confirmpassword: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                // FormData append
                var bodyformData = new FormData();
                bodyformData.append("email", values.email);
                bodyformData.append("password", values.password);
                bodyformData.append("name", values.UserName);
                bodyformData.append("cellno", values.phonenum);

                console.log(bodyformData);
                setLoad(true);
                axios({
                  method: "post",
                  url: "https://api.minhaj.app/user_register.php?",
                  data: bodyformData,
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                  .then((res) => {
                    if (term === false) {
                      setOpen1(true);
                      setLoad(false);
                    } else if (res.data.MDE_API[0].success == 0) {
                      setSuccess(res.data.MDE_API[0].success);
                      setMsg(res.data.MDE_API[0].MSG);
                      console.log('open before to true'+open);
                      setOpen(true);
                      console.log('open changed to true'+open);
                      setLoad(false);
                    } else {
                      setSuccess(res.data.MDE_API[0].success);
                      setMsg(res.data.MDE_API[0].MSG);
                      setOpen(true);

                      history.push("/login");
                      setLoad(false);
                    }
                  })
                  .catch((err) => {
                    console.log("Error in Reigister", err);
                  });
              }}
            >
              {({
                values,
                errors,
                handleChange,
                touched,
                handleBlur,
                isValid,
                handleSubmit
              }) => (
                // form fields
                <>
                  {store.langIndex == 0 ? (
                    <TextField
                      fullWidth
                      error={errors.UserName && touched.UserName}
                      name="UserName"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2"
                      label={TextTranslation.inp_plac_username[store.langIndex]}
                      autoFocus
                      helperText={
                        errors.UserName && touched.UserName
                          ? errors.UserName
                          : null
                      }
                    />
                  ) : (
                    <TextField
                      fullWidth
                      error={errors.UserName && touched.UserName}
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                              },
                            }
                      }
                      name="UserName"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2"
                      placeholder={
                        TextTranslation.inp_plac_username[store.langIndex]
                      }
                      autoFocus
                      helperText={
                        errors.UserName && touched.UserName
                          ? errors.UserName
                          : null
                      }
                    />
                  )}
                  {/* <div> */}
                  {store.langIndex == 0 ? (
                      <TextField
                        fullWidth
                        error={errors.email && touched.email}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-2"
                        value={emailState}
                        label={TextTranslation.inp_plac_email[store.langIndex]}
                        name="email"
                        helperText={
                          errors.email && touched.email ? errors.email : null
                        }
                      />
                  ) : (
                    <TextField
                      fullWidth
                      error={errors.email && touched.email}
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                              },
                            }
                      }
                      variant="outlined"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      className="mb-2"
                      placeholder={
                        TextTranslation.inp_plac_email[store.langIndex]
                      }
                      name="email"
                      helperText={
                        errors.email && touched.email ? errors.email : null
                      }
                    />
                  )}
                  {store.langIndex == 0 ? (
                    <TextField
                      fullWidth
                      error={errors.phonenum && touched.phonenum}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="phonenum"
                      label={TextTranslation.inp_plac_cell_no[store.langIndex]}
                      className="mb-2"
                      helperText={
                        errors.phonenum && touched.phonenum
                          ? errors.phonenum
                          : null
                      }
                    />
                  ) : (
                    <TextField
                      fullWidth
                      error={errors.phonenum && touched.phonenum}
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                              },
                            }
                      }
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="phonenum"
                      placeholder={
                        TextTranslation.inp_plac_cell_no[store.langIndex]
                      }
                      className="mb-2"
                      helperText={
                        errors.phonenum && touched.phonenum
                          ? errors.phonenum
                          : null
                      }
                    />
                  )}
                  {store.langIndex == 0 ? (
                    <TextField
                      fullWidth
                      error={errors.password && touched.password}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password"
                      label={TextTranslation.inp_plac_pass[store.langIndex]}
                      type="password"
                      className="mb-2"
                      helperText={
                        errors.password && touched.password
                          ? errors.password
                          : null
                      }
                    />
                  ) : (
                    <TextField
                      fullWidth
                      error={errors.password && touched.password}
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                              },
                            }
                      }
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password"
                      placeholder={
                        TextTranslation.inp_plac_pass[store.langIndex]
                      }
                      type="password"
                      className="mb-2"
                      helperText={
                        errors.password && touched.password
                          ? errors.password
                          : null
                      }
                    />
                  )}
                  {store.langIndex == 0 ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="confirmpassword"
                      label={
                        TextTranslation.inp_plac_cnfrm_pass[store.langIndex]
                      }
                      type="password"
                      className="mb-2"
                      helperText={
                        touched.confirmpassword ? errors.confirmpassword : ""
                      }
                      error={
                        touched.confirmpassword &&
                        Boolean(errors.confirmpassword)
                      }
                    />
                  ) : (
                    <TextField
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="confirmpassword"
                      placeholder={
                        TextTranslation.inp_plac_cnfrm_pass[store.langIndex]
                      }
                      type="password"
                      className="mb-2"
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                              },
                            }
                      }
                      helperText={
                        touched.confirmpassword ? errors.confirmpassword : ""
                      }
                      error={
                        touched.confirmpassword &&
                        Boolean(errors.confirmpassword)
                      }
                    />
                  )}
                  {store.langIndex == 0 ? (
                    <FormControlLabel
                      style={{
                        textAlign: "left",
                        fontFamily: "Raleway, sans-serif",
                        float: "left",
                      }}
                      control={
                        <Checkbox
                          type="checkbox"
                          name="checkbox"
                          color="secondary"
                          onChange={() => setTerm(!term)}
                        />
                      }
                      label={
                        <div
                          onClick={handlePrivacy}
                          style={{
                            textAlign: "right",
                            fontFamily: "Raleway, sans-serif",
                            fontSize: 14,
                            color: Colors.primary,
                            cursor: "pointer",
                          }}
                        >
                          {TextTranslation.acceptTerm[store.langIndex]}
                        </div>
                      }
                    />
                  ) : (
                    <FormControlLabel
                      style={
                        store.langIndex == 1
                          ? {
                              textAlign: "right",
                              fontFamily: "JameelNoori",
                              float: "right",
                            }
                          : {
                              textAlign: "right",
                              fontFamily: "Arabic",
                              float: "right",
                            }
                      }
                      control={
                        <Checkbox
                          type="checkbox"
                          name="checkbox"
                          color="secondary"
                          onChange={() => setTerm(!term)}
                        />
                      }
                      label={
                        <div
                          onClic={() => history.push("/privacy")}
                          style={
                            store.langIndex == 1
                              ? {
                                  textAlign: "right",
                                  fontFamily: "JameelNoori",
                                  fontSize: 18,
                                  color: Colors.primary,
                                  cursor: "pointer",
                                }
                              : {
                                  textAlign: "right",
                                  fontFamily: "Arabic",
                                  color: Colors.primary,
                                  cursor: "pointer",
                                }
                          }
                        >
                          {TextTranslation.acceptTerm[store.langIndex]}
                        </div>
                      }
                    />
                  )}

                  {/* register button */}
                  <Button
                    className="mt-3 mb-3 btn btn-radius"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                    }}
                    disabled={!isValid}
                   onClick={handleSubmit}
                  >
                    {load ? (
                      <CircularProgress
                        size={30}
                        style={{ color: Colors.white }}
                      />
                    ) : (
                      <span
                        style={
                          store.langIndex == 0
                            ? { fontFamily: "Raleway, sans-serif" }
                            : store.langIndex == 1
                            ? { fontFamily: "JameelNoori", fontSize: 18 }
                            : { fontFamily: "Arabic", fontSize: 18 }
                        }
                      >
                        {TextTranslation.register[store.langIndex]}
                      </span>
                    )}
                  </Button>
                </>
              )}
            </Formik>
            : null
            }    
          </div>
        
        </Container>
        </>
      )}
    </Observer>
  );
};

export default Register;
