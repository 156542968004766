import React, { useEffect, useState, useContext } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Grid, Card, Snackbar } from "@material-ui/core";
import { StoreContext } from "../../../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { Observer } from "mobx-react-lite";
import { decode } from "html-entities";
import ReactHtmlParser from "html-react-parser";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";
import Colors from "../../../../Config/Colors";
import TextTranslation from "../../../../Config/TextTranslation";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Book.css";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

// this component is for web view of MIE APP

const MDEAppSelectedBookResult = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let paramFromBookSenetnce = useParams();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [selectedBook, setSelectedBook] = useState("");
  const [bookTitle, setBookTitle] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [loader, setLoader] = useState(true);

  // get selected book data
  const getSelectedBookData = () => {
    axios({
      method: "post",
      url: "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_bookstext/_search",
      data: JSON.stringify({
        size: 999,
        query: {
          bool: {
            must: [
              {
                term: {
                  book_id: {
                    value: paramFromBookSenetnce.selectedBookId,
                  },
                },
              },
              {
                term: {
                  chapter_id: {
                    value: paramFromBookSenetnce.selectedChapterId,
                  },
                },
              },
            ],
          },
        },
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
      },
    })
      .then((response) => {
        console.log("Selected BookScreen fetch", response.data.hits.hits);
        setSelectedBook(response.data.hits.hits);
        paramFromBookSenetnce.selectedLangId == 0
          ? setBookTitle(response.data.hits.hits[0]._source.en_book_title)
          : setBookTitle(response.data.hits.hits[0]._source.ur_book_title);
        setChapterName(response.data.hits.hits[0]._source.chapter_name);
        setLoader(false);
      })
      .catch((error) => {
        console.log("Error in BookScreen", error);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    window.scrollTo(0, 0);
    getSelectedBookData();
  }, []);

  return (
    <Observer>
      {() => (
        <>
          {!loader ? (
            <Grid container justify="center" alignItems="center">
              <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
                <Card className="pt-3 pb-3 px-1 mt-4 mb-4" raised={true}>
                  <div>
                    <h6
                      className="text-center"
                      style={{
                        fontFamily:
                          store.langIndex == 0
                            ? "Raleway,sans-serif"
                            : store.langIndex == 1
                            ? "JameelNoori"
                            : "Arabic",
                        fontSize:
                          store.langIndex == 0
                            ? 18
                            : store.langIndex == 1
                            ? 24
                            : 18,
                      }}
                    >
                      {chapterName}
                    </h6>
                    <div
                      className="text-center pb-2"
                      style={{
                        color: Colors.primary,
                        fontFamily:
                          store.langIndex == 0
                            ? "Raleway,sans-serif"
                            : store.langIndex == 1
                            ? "JameelNoori"
                            : "Arabic",
                        fontSize:
                          store.langIndex == 0
                            ? 18
                            : store.langIndex == 1
                            ? 24
                            : 18,
                      }}
                    >
                      {bookTitle}
                    </div>
                  </div>

                  {selectedBook.map((data, index) => (
                    <div key={index}>
                      <div
                        className="mt-3 mx-2"
                        style={{
                          fontFamily:
                            store.langIndex == 0
                              ? "Raleway,sans-serif"
                              : store.langIndex == 1
                              ? "JameelNoori"
                              : "Arabic",
                          textAlign:
                            data._source.lang_id == 1 ? "Justify" : "right",
                          fontSize:
                            data._source.lang_id == 1
                              ? 14
                              : data._source.lang_id == 2
                              ? 18
                              : 18,
                          lineHeight: data._source.lang_id == 1 ? "" : 2,
                        }}
                      >
                        {ReactHtmlParser(
                          decode(
                            data._source.body.replace(
                              store.bookSentence,
                              `<b id="searchText">${store.bookSentence}</b>`
                            )
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <button
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      border: "none",
                      borderRadius: "5px",
                      width: 130,
                      height: 40,
                    }}
                  >
                    <a
                      style={
                        store.langIndex == 0
                          ? { fontFamily: "Raleway, sans-serif" }
                          : store.langIndex == 1
                          ? { fontFamily: "JameelNoori" }
                          : { fontFamily: "Arabic" }
                      }
                      id="more-btn"
                      href={`https://minhajbooks.com/english/book/${paramFromBookSenetnce.selectedBookId}`}
                      target="_blank"
                    >
                      {TextTranslation.read_more[store.langIndex]}
                    </a>
                  </button>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              style={{ minHeight: "100vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Observer>
  );
};

export default MDEAppSelectedBookResult;
