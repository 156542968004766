import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { useMediaQuery } from "react-responsive";
import { Observer } from "mobx-react-lite";
import Articles from "../../Components/ResultTabs/Tabs/Articles/Articles";
import Speeches from "../../Components/ResultTabs/Tabs/Speeches/Speeches";
import Hadith from "../../Components/ResultTabs/Tabs/Hadith/Hadith";
import Book from "../../Components/ResultTabs/Tabs/Book/Book";
import MainNavbar from "../../Navbars/MainNavbar/MainNavbar";
import TextTranslation from "../../Config/TextTranslation";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import AppBar from "@material-ui/core/AppBar";
import Colors from "../../Config/Colors";
import logo from "../../images/MIE_Light.png";
import Tab from "@material-ui/core/Tab";
import Quran from "../Quran/Quran";
import moment from "moment";
import axios from "axios";
import Url from "../../Config/Url";


const useStyles = makeStyles((theme) => ({
  root: {
   
  },
  title: {
   
    display: "block",
  },
  appbar: {
   
    alignItems: "center",
    justifyContent: "center",
  },

  logo: {
    height: "40px", 
    cursor: "pointer",
    backgroundSize: "contain",
    [theme.breakpoints.down("md")]: {
      height: "40px",

      marginRight: 10,
    },
  },
  autocomplete: {
    width: '100%',
    height: 40,
    border: '2px solid #e32636',
    backgroundColor: Colors.white,
    [theme.breakpoints.down("md")]: {
      width: 500,
    },
    [theme.breakpoints.down("sm")]: {
      width: 350,
      paddingRight: 5,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      paddingRight: 5,
    },
  },

  tabs: {
    backgroundColor: Colors.white,
    color: Colors.primary,
   
  },
  urdufont: {
    fontFamily: "JameelNoori",
    fontSize: 16,
  },
  arabicfont: {
    fontFamily: "Arabic",
    fontSize: 16,
  },
}));

const NewResultSearch = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const store = useContext(StoreContext);
  const classes = useStyles();
  const history = useHistory();
  const getParamFromMain = useParams();
  const [value, setValue] = useState(
    getParamFromMain.initial ? getParamFromMain.initial : "Quran"
  );
  // console.log('getParamFromMain.search '+ getParamFromMain.search );
  const [search1, setSearch1] = useState();
  const [newValue, setNewValue] = useState();
  const [smallSearch, setSmallSearch] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 430px)" });
  const [articleCounter, setArticleCounter] = useState(0);
  const [speechCounter, setSpeechCounter] = useState(0);
console.log('newValue',newValue)
  // post search history API
  const postSearchHistory = (search, searchType) => {
    var PostHistoryForm = new FormData();

    PostHistoryForm.append("search", search);
    PostHistoryForm.append("1", searchType);
    PostHistoryForm.append("lang_id", parseInt(store.langIndex) + 1);
    PostHistoryForm.append("date", moment().format("YYYY-MM-DD HH:mm:ss"));
    {
      store.id !== null &&
        axios({
          method: "post",
          url:  `${Url.link}/store_history.php?`,
          data: PostHistoryForm,
          headers: {
            "Content-Type": "multipart/form-data",
            uuid: localStorage.getItem("@uuid"),
          },
        })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log("history" + error);
          });
    }
  };

  // did you mean
  // const didYouMean = (search) => {
  //   fetch(
  //     "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_stemtopic_v4/_search",
  //     {
  //       method: "POST", // or 'PUT'
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
  //       },
  //       body: JSON.stringify({
  //         size: 0,

  //         suggest: {
  //           text: search.toLowerCase(),
  //           suggestion: {
  //             phrase: {
  //               field:
  //                 store.langIndex == 0
  //                   ? "eng_ref"
  //                   : store.langIndex == 1
  //                   ? "urdu_ref"
  //                   : "arabic_ref",

  //               real_word_error_likelihood: 0.95,

  //               max_errors: 0.5,

  //               confidence: 0,

  //               highlight: {
  //                 pre_tag: "<em>",

  //                 post_tag: "</em>",
  //               },

  //               collate: {
  //                 query: {
  //                   inline: {
  //                     match: {
  //                       "": "",
  //                     },
  //                   },
  //                 },

  //                 params: {
  //                   field_name:
  //                     store.langIndex == 0
  //                       ? "eng_ref"
  //                       : store.langIndex == 1
  //                       ? "urdu_ref"
  //                       : "arabic_ref",
  //                 },

  //                 prune: true,
  //               },
  //             },
  //           },
  //         },
  //       }),
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       if (!responseJson.suggest.suggestion[0].options[0]) {
  //         store.setDidYouMeanExist(false);
  //       } else {
  //         store.setChangeDidYouMean(
  //           responseJson.suggest.suggestion[0].options[0].text
  //         );
  //         store.setDidYouMeanExist(true);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Suggest" + error);
  //     });
  // };

  // get roman API

  const Search = (search1) => {
    postSearchHistory(search1);
    const roman =
      store.langIndex == 2
        ? "https://py.minhaj.app/roman_to_arabic?input_text="
        : store.langIndex == 1
        ? "https://py.minhaj.app/roman_to_urdu?input_text="
        : "https://py.minhaj.app/to_english?input_text=";

    if (search1 == "") {
      alert(TextTranslation.enter_something[store.langIndex]);
      return;
    }

    axios
      .get(roman + search1 + "", {
        headers: {
          Authorization: btoa("bftech:bftechAPI"),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        setNewValue(res.data);
        store.setDataArr([]);
        store.setFrom(0);
          history.push(`/result/${store.locParam}/${res.data}/${store.langIndex}`);
          console.log('store.langIndex------------->',store.langIndex);
      })
      .catch((err) => {
        console.log("Roman Error", err);
      });
  };

  // get speech counter
  const getSpeechCounter = (searchValue) => {
    axios({
      method: "post",
      url: "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_transcripts/_search",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
      },
      data: JSON.stringify({
        query: {
          query_string: {
            query: "*" + searchValue + "*",
          },
        },
      }),
    }).then((res) => {
      setSpeechCounter(res.data.hits.total.value);
    });
  };

  // get book counter
  const getBookCounter = (searchValue) => {
    axios({
      method: "post",
      url: "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_books/_search",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
      },
      data: JSON.stringify({
        query: {
          query_string: {
            query: "*" + searchValue + "*",
          },
        },
      }),
    }).then((res) => {
      store.setBookCounter(res.data.hits.total.value);
    });
  };

  // get article counter
  const getArticleCounter = (searchValue) => {
    setArticleCounter(0);
    axios({
      method: "post",
      url: "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_articles/_search",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
      },
      data: JSON.stringify({
        query: {
          query_string: {
            query: "*" + searchValue + "*",
            fields: ["title^10", "body^2"],
          },
        },
      }),
    }).then((res) => {
      setArticleCounter(res.data.hits.total.value);
    });
  };

  const handleChange = (event, tabTitle) => {
    setValue(tabTitle);
    store.setlocParam(tabTitle)
    history.push(`/result/${store.locParam}/${newValue}/${store.langIndex}`)
    console.log('Selected tab----------------->: ' + store.locParam,newValue);
  };
 
  

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    window.scrollTo(0, 0);
    // setValue()
    setNewValue(getParamFromMain.search);
    getBookCounter(getParamFromMain.search);
    getArticleCounter(getParamFromMain.search);
    getSpeechCounter(getParamFromMain.search);
    return () => {
      ac.abort();
      store.setDidYouMeanBool(0)
    } 
  }, [isMobile, search1, getParamFromMain.search]);

  return (
    <Observer>
      {() => (
        <>
          <div className={(classes.root)}>
            <AppBar
              position="unset"
              style={{
                backgroundColor: Colors.white,
                boxShadow: "0px 0px 0px white",
                
              }}
            >
              <Toolbar className="d-block" >
              <div className="d-flex p-3" >
                {!isMobile &&   (
                  <Typography
                    className={(classes.title,'p-3',"d-md-block d-none")}
                    style={{ color: Colors.primary }}
                    noWrap
                  >
                    <img
                      className={(classes.logo)}
                      src={logo}
                      alt=""
                      onClick={() => history.push("/")}
                    />
                   
                  </Typography>
                )}
 <MainNavbar />
              </div>
                <div
                  className={(classes.appbar,'col-11')}
                  style={{
                    backgroundColor: Colors.white,
                    display: "flex",
                    
                    justifyContent: "space-between",
                  }}
                >
                  
                  {store.smallSearch && isMobile && (
                    <>
                    
                      <input
                        className={classes.autocomplete}
                        style={
                          store.langIndex == 0
                            ? {
                                textAlign: "left",
                                paddingLeft: 10,
                                fontFamily: "Raleway, sans-serif",
                                fontSize: 16,
                              }
                            : store.langIndex == 1
                            ? {
                                textAlign: "right",
                                paddingRight: 10,
                                fontFamily: "JameelNoori",
                              }
                            : {
                                textAlign: "right",
                                paddingRight: 10,
                                fontFamily: "Arabic",
                              }
                        }
                        type="text"
                        placeholder={
                          TextTranslation.search_placeholder[store.langIndex]
                        }
                    
                        defaultValue={getParamFromMain.search}
                        onChange={(e) => setSearch1(e.target.value)}
                        onKeyPress={(e) => {
                          {
                            if (e.key === "Enter") {
                              Search(search1);
                              setValue("Quran");
                            }
                          }
                        }}
                      />
                      <div
                        className="icon"
                        style={{
                          color: Colors.primary,
                        }}
                        onClick={() => store.setSmallSearch(false)}
                      >
                        <i class="far fa-times-circle" />
                      </div>
                    </>
                  )}

                  {!isMobile && (
                    <input
                      className={classes.autocomplete}
                      style={
                        store.langIndex == 0
                          ? {
                              textAlign: "left",
                              paddingLeft: 10,
                              fontFamily: "Raleway, sans-serif",
                              fontSize: 16,
                            }
                          : store.langIndex == 1
                          ? {
                              textAlign: "right",
                              paddingRight: 10,
                              fontFamily: "JameelNoori",
                            }
                          : {
                              textAlign: "right",
                              paddingRight: 10,
                              fontFamily: "Arabic",
                            }
                      }
                      type="text"
                      placeholder={
                        TextTranslation.search_placeholder[store.langIndex]
                      }
                      defaultValue={getParamFromMain.search}
                      onChange={(e) => setSearch1(e.target.value)}
                      onKeyPress={(e) => {
                        {
                          if (e.key === "Enter") {
                            Search(search1);
                            setValue("Quran");
                          }
                        }
                      }}
                    />
                  )}
                </div>
               
              </Toolbar>
            </AppBar>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            
          ></div>
          <br />
          <TabContext value={value} style={{ backgroundColor:"red",boxShadow:"0px 2px 4px -1px rgb(0 0 0 / 20%) !important" }} >
            {store.langIndex == 0 ? (
              <AppBar position="static" className="shadow-sm" >
                <TabList
                  centered
                  onChange={handleChange}
                  className={(classes.tabs)}
                  
                >
                  <Tab
                    label={
                      <small>
                        {TextTranslation.quran[store.langIndex]}
                        <span
                          style={{
                            border: "1px solid",
                            borderColor: Colors.primary,
                            borderRadius: "6px",
                            color: Colors.primary,
                            width: "auto",
                            padding: "0px 3px 2px 3px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {store.result_counter}
                        </span>
                      </small>
                    }
                   
                    value="Quran"
                    
                  />
                  {/* <Tab
                    label={
                      <small>
                        {TextTranslation.hadith[store.langIndex]}
                        <span
                          style={{
                            border: "1px solid",
                            borderColor: Colors.primary,
                            borderRadius: "6px",
                            color: Colors.primary,
                            width: "auto",
                            padding: "0px 3px 2px 3px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {"0"}
                        </span>
                      </small>
                    }
                    value="Hadith"
                  /> */}
                  <Tab
                    label={
                      <small>
                        {TextTranslation.speeches[store.langIndex]}
                        <span
                          style={{
                            border: "1px solid",
                            borderColor: Colors.primary,
                            borderRadius: "6px",
                            color: Colors.primary,
                            width: "auto",
                            padding: "0px 3px 2px 3px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {speechCounter}
                        </span>
                      </small>
                    }
                    
                    value="Speech"
                    
                  />
                  {/* <Tab
                    label={
                      <small>
                        {TextTranslation.books[store.langIndex]}
                        <span
                          style={{
                            border: "1px solid",
                            borderColor: Colors.primary,
                            borderRadius: "6px",
                            color: Colors.primary,
                            width: "auto",
                            padding: "0px 3px 2px 3px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {store.book_counter}
                        </span>
                      </small>
                    }
                    value="Book"
                  /> */}
                  <Tab
                    label={
                      <small>
                        {TextTranslation.articles[store.langIndex]}
                        <span
                          style={{
                            border: "1px solid",
                            borderColor: Colors.primary,
                            borderRadius: "6px",
                            color: Colors.primary,
                            width: "auto",
                            padding: "0px 3px 2px 3px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {articleCounter}
                        </span>
                      </small>
                    }
                  
                    value="Fatwa"
                  />
                </TabList>
              </AppBar>
            ) : (
              <AppBar position="static" style={{ boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 12%)"}}>
                <TabList
                  centered
                  onChange={handleChange}
                  className={classes.tabs}
                >
                  <Tab
                    label={
                      <small
                        className={
                          store.langIndex == 1
                            ? classes.urdufont
                            : classes.arabicfont
                        }
                      >
                        {TextTranslation.articles[store.langIndex]}
                        <span
                          style={{
                            border: "1px solid",
                            borderColor: Colors.primary,
                            borderRadius: "6px",
                            color: Colors.primary,
                            width: "auto",
                            padding: "0px 3px 2px 3px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {articleCounter}
                        </span>
                      </small>
                    }
                    value="Fatwa"
                  />
                  {/* <Tab
                    label={
                      <small
                        className={
                          store.langIndex == 1
                            ? classes.urdufont
                            : classes.arabicfont
                        }
                      >
                        {TextTranslation.books[store.langIndex]}
                        <span
                          style={{
                            border: "1px solid",
                            borderColor: Colors.primary,
                            borderRadius: "6px",
                            color: Colors.primary,
                            width: "auto",
                            padding: "0px 3px 2px 3px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {store.book_counter}
                        </span>
                      </small>
                    }
                    value="Book"
                  /> */}
                  <Tab
                    label={
                      <small
                        className={
                          store.langIndex == 1
                            ? classes.urdufont
                            : classes.arabicfont
                        }
                      >
                        {TextTranslation.speeches[store.langIndex]}
                        <span
                          style={{
                            border: "1px solid",
                            borderColor: Colors.primary,
                            borderRadius: "6px",
                            color: Colors.primary,
                            width: "auto",
                            padding: "0px 3px 2px 3px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {speechCounter}
                        </span>
                      </small>
                    }
                    value="Speech"
                  />
                  {/* <Tab
                    label={
                      <>
                        <small
                          className={
                            store.langIndex == 1
                              ? classes.urdufont
                              : classes.arabicfont
                          }
                        >
                          {TextTranslation.hadith[store.langIndex]}
                          <span
                            style={{
                              border: "1px solid",
                              borderColor: Colors.primary,
                              borderRadius: "6px",
                              color: Colors.primary,
                              width: "auto",
                              padding: "0px 3px 2px 3px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {"0"}
                          </span>
                        </small>
                      </>
                    }
                    value="Hadith"
                  /> */}

                  <Tab
                    label={
                      <small
                        className={
                          store.langIndex == 1
                            ? classes.urdufont
                            : classes.arabicfont
                        }
                      >
                        {TextTranslation.quran[store.langIndex]}
                        <span
                          style={{
                            border: "1px solid",
                            borderColor: Colors.primary,
                            borderRadius: "6px",
                            color: Colors.primary,
                            width: "auto",
                            padding: "0px 3px 2px 3px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {store.result_counter}
                        </span>
                      </small>
                    }
                    value="Quran"
                  />
                </TabList>
              </AppBar>
            )}
            <TabPanel value="Quran">
              <Quran search={newValue} />
            </TabPanel>
            <TabPanel value="Hadith">
              <Hadith search={newValue} />
            </TabPanel>
            <TabPanel value="Speech">
              <Speeches searchValue={newValue} speechCounter={speechCounter} />
            </TabPanel>
            <TabPanel value="Book">
              <Book search={newValue} />
            </TabPanel>
            <TabPanel value="Fatwa">
              <Articles search={newValue} />
            </TabPanel>
          </TabContext>
        </>
      )}
    </Observer>
  );
};

export default NewResultSearch;
