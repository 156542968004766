import React, { useContext, useState,useEffect } from "react";
import { Observer } from "mobx-react-lite";
import { Formik, Form } from "formik";
import { StoreContext } from "../../store/store";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Url from "../../Config/Url";
import {
  Container,
  Grid,
  TextField,
  Typography,
  CssBaseline,
  CircularProgress,
  Button,
  Snackbar,
  Avatar,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import TextTranslation from "../../Config/TextTranslation";
import MuiAlert from "@material-ui/lab/Alert";
import Colors from "../../Config/Colors";
import axios from "axios";
import facebook from "../../images/social_media/facebook.png"
import google from "../../images/social_media/google.png"
import linkedIn from "../../images/social_media/linkedIn.png"
import twitter from "../../images/social_media/twitter.png"
import * as yup from "yup";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
//alert function
const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

// styles
const useStyles = makeStyles((theme) => ({
 
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'red',
    },
     
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-input': {
        borderRadius: "7px",
        backgroundColor: Colors.grey,
        borderColor: Colors.inputBorder,
      },
    },
})(TextField);
const Login = (props) => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let history = useHistory();
  const store = useContext(StoreContext);

  const classes = useStyles(); // styles classes
  const [load, setLoad] = useState(false); // loading state on button
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState("");


  //validation schema
  let LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(TextTranslation.required_email_msg[store.langIndex]),
    password: yup
      .string()
      .min(6, TextTranslation.min_password_msg[store.langIndex])
      .required(TextTranslation.required_password_msg[store.langIndex]),
  });

  // alert close function
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
  }, []);

  return (
    <Observer>
      {() => (
        <>
        <CommonNavbar />
        <Container component="main"  maxWidth="xs">
           
          {/* login alert Snackbar */}
          <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity={success === 0 ? "error" : "success"}
            >
              {success === 0
                ? TextTranslation.invalid_email_or_password[store.langIndex]
                : TextTranslation.login_successfully[store.langIndex]}
            </Alert>
          </Snackbar>
          <CssBaseline />

          <div className={classes.paper}>
          
            <h1 className="font-weight-bold"
                style={
                  store.langIndex == 0
                    ? { fontFamily: "Raleway, sans-serif" }
                    : store.langIndex == 1
                    ? { fontFamily: "JameelNoori" }
                    : { fontFamily: "Arabic" }
                }
              >
                {TextTranslation.f_login[store.langIndex]}
              </h1>
              <Typography  className="text-center py-3"
              style={
                  store.langIndex == 0
                    ? { fontFamily: "Raleway, sans-serif" }
                    : store.langIndex == 1
                    ? { fontFamily: "JameelNoori" }
                    : { fontFamily: "Arabic" }
                }>
                  {TextTranslation.login_screen_string[store.langIndex]}
                
              </Typography>
            {/* Login Form */}
            <Formik
              fullWidth
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={LoginSchema}
              onSubmit={(values) => {
                // FormData append
                var bodyformData = new FormData();
                bodyformData.append("email", values.email);
                bodyformData.append("password", values.password);

                setLoad(true);
                axios({
                  method: "post",
                  url: `${Url.link}/user_login.php?`,
                  data: bodyformData,
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                  .then((res) => {
                    if (res.data.MDE_API[0].success === "0") {
                      setOpen(true);
                      setSuccess(0);
                      setLoad(false);
                    } else {
                      setOpen(true);
                      setSuccess(1);
                      // if success == 1 then uuid store in local
                      localStorage.setItem("@uuid", res.headers.uuid);
                      // after submit it's go to main screen
                      history.push("/");
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              {({ errors, handleChange, touched, handleBlur, isValid }) => (
                // form fields
                <Form>
                  {store.langIndex == 0 ? (
                    <CssTextField
                      fullWidth
                      error={errors.email && touched.email}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2"
                   
                      label={TextTranslation.inp_plac_email[store.langIndex]}
                      name="email"
                      helperText={
                        errors.email && touched.email ? errors.email : null
                      }
                      
                    />
                  ) : (
                    <CssTextField
                      fullWidth
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              },
                            }
                      }
                      error={errors.email && touched.email}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2"
                      placeholder={
                        TextTranslation.inp_plac_email[store.langIndex]
                      }
                      name="email"
                      helperText={
                        errors.email && touched.email ? errors.email : null
                      }
                    />
                  )}
                  {store.langIndex == 0 ? (
                    <CssTextField
                      fullWidth
                      error={errors.password && touched.password}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password"
                      label={TextTranslation.inp_plac_pass[store.langIndex]}
                      type="password"
                      className="mb-2"
                      helperText={
                        errors.password && touched.password
                          ? errors.password
                          : null
                      }
                    />
                  ) : (
                    <CssTextField
                      fullWidth
                      error={errors.password && touched.password}
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              },
                            }
                      }
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password"
                      placeholder={
                        TextTranslation.inp_plac_pass[store.langIndex]
                      }
                      type="password"
                      className="mb-2"
                      helperText={
                        errors.password && touched.password
                          ? errors.password
                          : null
                      }
                    />
                  )}
                  {/* Login button */}
                  <Button
                    className="mt-3 btn py-2 btn-radius"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                    }}
                    disabled={!isValid}
                  >
                    {load ? (
                      <CircularProgress
                        size={30}
                        style={{ color: Colors.white }}
                      />
                    ) : (
                      <span
                        style={
                          store.langIndex == 0
                            ? { fontFamily: "Raleway, sans-serif" }
                            : store.langIndex == 1
                            ? { fontFamily: "JameelNoori" }
                            : { fontFamily: "Arabic", fontSize: 18 }
                        }
                      >
                        {TextTranslation.f_login[store.langIndex]}
                      </span>
                    )}
                  </Button>

                  <Grid container className="mt-3">
                    <Grid item xs className="text-right">
                      {/* forgot password */}
                      <div onClick={() => history.push("/enteremail")}>
                        <span
                          style={
                            store.langIndex == 0
                              ? {
                                  fontFamily: "Raleway, sans-serif",
                                  color: Colors.black,
                                  cursor: "pointer",
                                  fontSize: 14,
                                }
                              : store.langIndex == 1
                              ? {
                                  fontFamily: "JameelNoori",
                                  fontSize: 16,
                                  color: Colors.black,
                                  cursor: "pointer",
                                }
                              : {
                                  fontFamily: "Arabic",
                                  fontSize: 16,
                                  color: Colors.black,
                                  cursor: "pointer",
                                }
                          }
                        >
                          {TextTranslation.forget_pass_text[store.langIndex]}
                        </span>
                      </div>
                    </Grid>
                 {/*   <Grid item>
                     new register 
                       <div onClick={() => history.push("/register")}>
                        <span
                          style={
                            store.langIndex == 0
                              ? {
                                  fontFamily: "Raleway, sans-serif",
                                  color: Colors.primary,
                                  cursor: "pointer",
                                  fontSize: 14,
                                }
                              : store.langIndex == 1
                              ? {
                                  fontFamily: "JameelNoori",
                                  fontSize: 16,
                                  color: Colors.primary,
                                  cursor: "pointer",
                                }
                              : {
                                  fontFamily: "Arabic",
                                  fontSize: 16,
                                  color: Colors.primary,
                                  cursor: "pointer",
                                }
                          }
                        >
                          {TextTranslation.create_new_account[store.langIndex]}
                        </span>
                      </div> 
                    </Grid>*/}
                  </Grid>
                </Form>
              )}
              
            </Formik>
            
          </div>
        </Container>
        </>
      )}
    </Observer>
  );
};

export default Login;
