import {
  Grid,
  Card,
  CardHeader,
  Divider,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { useHistory } from "react-router-dom";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import MuiAlert from "@material-ui/lab/Alert";
import Colors from "../../Config/Colors";
import axios from "axios";
import "./UserHistory.css";
import lodash from "lodash";
import moment from "moment";
import Url from "../../Config/Url";

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

const UserHistory = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let history = useHistory();
  const classes = useStyles();

  const store = useContext(StoreContext);
  const [topic, setTopic] = useState([]);
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState("");

  // get history API
  const getHistory = () => {
    axios({
      method: "post",
      url: `${Url.link}/get_history.php`,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    }).then((res) => {
      setTopic(res.data.MDE_API[0].MSG['data']);
      console.log(res.data.MDE_API[0].MSG);
      setLoader(false);
    });
  };

  let data = lodash.groupBy(topic, "date");
console.log('data',data);
  // delete history API
  const deleteHistory = (id) => {
    var deleteHistoryFormData = new FormData();
    deleteHistoryFormData.append("search_id", id);
    axios({
      method: "post",
      url: `${Url.link}/delete_history.php?`,
      data: deleteHistoryFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    })
      .then((res) => {
        console.log("delete history api console", res.data);
        const Delete = topic.filter((e) => e.search_id != id);
        if (window.confirm("Are you sure to delete this ?")) {
          setOpen(true);
          setSuccess(1);
          setTopic(Delete);
        } else {
          setOpen(true);
          setSuccess(0);
        }
      })
      .catch((err) => {
        console.log("delete history API error", err);
      });
  };

  useEffect(() => {
    const ac = new AbortController();
    getHistory();
    return () => ac.abort();
  }, []);

  // alert close function
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
     
  }, []);

  return (
    <>
      <CommonNavbar />
      {loader ? (
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          directioin="column"
          style={{ minHeight: "100vh" }}
        >
          <Grid>
            <CircularProgress
              className={classes.loader}
              size={50}
              thickness={3}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="center" justify="center" className="mt-4">
          <Grid item xs={11} sm={9} md={9} lg={8}>
            {/* History alert Snackbar */}
            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={success === 0 ? "error" : "success"}
              >
                {success === 0
                  ? "History Not Deleted"
                  : "Selected History Deleted Successfully"}
              </Alert>
            </Snackbar>
            {Object.keys(data).map((key, index) => (
              <Card
                className="history-card mb-4 mt-5"
                raised={true}
                key={index}
              >
                <CardHeader
                  titleTypographyProps={{ variant: "subheading" }}
                  id="history-card-header"
                  title={
                    <p
                      className="mt-3"
                      style={{ fontFamily: "Raleway, sans-serif" }}
                    >
                      {moment(key).calendar(null, {
                        sameDay: "[Today] - dddd  MMMM DD ,YYYY",
                        nextDay: "[Tomorrow] - dddd , MMMM  DD , YYYY",
                        nextWeek: "dddd",
                        lastDay: "[Yesterday] - dddd  MMMM DD ,YYYY",
                        lastWeek: "[Last] dddd",
                        sameElse: "MMMM DD ,YYYY",
                      })}
                    </p>
                  }
                />
                <Divider />
                {data[key].map((child, index) => (
                  <Grid key={index} container justify="space-around">
                    <Grid
                      item
                      className="history-card-data-left"
                      xs={6}
                      sm={6}
                      lg={6}
                    >
                      <div>{index + 1}</div>
                      <div
                        style={{ cursor: "pointer", fontSize: "14px" }}
                        onClick={() =>
                          history.push(`/result/Quran/${child.search_query}`)
                        }
                      >
                        <span
                          className="mx-2"
                          style={
                            store.langIndex == 0
                              ? {
                                  fontFamily: "Raleway,sans-serif",
                                }
                              : store.langIndex == 1
                              ? {
                                  fontFamily: "JameelNoori",
                                }
                              : {
                                  fontFamily: "Arabic",
                                }
                          }
                        >
                          {child.search_query}
                        </span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      className="history-card-data-right"
                      xs={5}
                      sm={5}
                      lg={3}
                      xl={3}
                    >
                      <div>{moment(child.time, "hh:mm:ss").format("LT")}</div>
                      <div onClick={() => deleteHistory(child.search_id)}>
                        <RemoveCircleIcon
                          id="delete-icon"
                          style={{ fontSize: 20 }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Card>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserHistory;
