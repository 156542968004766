import React, { useContext } from "react";
import { Observer } from "mobx-react-lite";
import { StoreContext } from "../../store/store";
import TextTranslation from "../../Config/TextTranslation";
import Colors from "../../Config/Colors";
import "./Languages.css";
import "./toogle.css";

const Languages = () => {
  const store = useContext(StoreContext);

  const changeLangIndex = (index) => {
    store.setLangIndex(index);
    console.log('store.langIndex====>',store.langIndex);
    localStorage.setItem("@langIndex", index);
  };

  return (
    <Observer>
      {() => (
        <>
          
        <div
          className="mt-3"
          style={{ 
            display: "flex", 
            justifyContent: "center",
            color:Colors.theme_c_dbw[store.theme] }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
              
          <div className="col-6">
            <div className="switch d-flex ">
              <input name="switch" id="one" type="radio" checked={store.langIndex==0 ? true : false} />
              <label for="one" className="switch__label"   style={{ fontFamily: store.langIndex==0 ? "Raleway, sans-serif" : store.langIndex==1 ? "JameelNoori" : "Arabic"}}  onClick={() => changeLangIndex(0)}>{TextTranslation.english[store.langIndex]}</label>
              <input name="switch" id="two" type="radio" checked={store.langIndex==1 ? true : false} />
              <label for="two" className={store.langIndex==0 ? "switch__label ":store.langIndex==1 ? "switch__label switch__label_urdu" : "switch__label switch__label_arabic"}  style={{ fontFamily: store.langIndex==0 ? "Raleway, sans-serif" : store.langIndex==1 ? "JameelNoori" : "Arabic"}}    onClick={() => changeLangIndex(1)}> {TextTranslation.urdu[store.langIndex]}</label>
              <input name="switch" id="three" type="radio" checked={store.langIndex==2 ? true : false} />
              <label for="three" className="switch__label"  style={{ fontFamily: store.langIndex==0 ? "Raleway, sans-serif" : store.langIndex==1 ? "JameelNoori" : "Arabic"}}  onClick={() => changeLangIndex(2)}>{TextTranslation.arabic[store.langIndex]}</label>
              <div className="switch__indicator" ></div>  
            </div>
          </div>
            
          </div>
     
        </div>
     
   </>
      )}
    </Observer>
  );
};

export default Languages;
