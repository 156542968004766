import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../../../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Observer } from "mobx-react-lite";
import { decode } from "html-entities";
import Colors from "../../../../Config/Colors";
import ReactHtmlParser from "html-react-parser";
import TextTranslation from "../../../../Config/TextTranslation";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchNavbar from "../../../../Navbars/SearchNavbar/SearchNavbar";
import "./Articles.css";

const useStyles = makeStyles(() => ({
  loader: {
    color: Colors.primary,
  },
}));
const ArticlesResult = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let paramFromArticleTitle = useParams(); // getting params from previous screen
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [articleTitle, setArticleTitle] = useState(""); // article title state
  const [articleBody, setArticleBody] = useState(""); // article body state
  const [loader, setLoader] = useState(true); // loader state

  let urls =
    "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_articles/_search";

  const getData = () => {
    fetch(urls, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
      },
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              {
                term: {
                  article_id: {
                    value: paramFromArticleTitle.selectedFatwaId,
                  },
                },
              },
            ],
          },
        },
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("articles result screen response", res.hits.hits);
        // console.log("fatwa id", res.hits.hits[0]._source.fatwa_id);
        setArticleTitle(res.hits.hits[0]._source.title);
        setArticleBody(res.hits.hits);
        setLoader(false);
        // in data some anchor tags are not opening on new tab, for opening in new tab -->
        var links = document.getElementsByTagName("a");
        var len = links.length;
        for (var i = 0; i < len; i++) {
          links[i].target = "_blank";
        }
      })
      .catch((error) => {
        console.log("Error in Articles.jsx", error);
        alert(error);
      });
  };

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    window.scrollTo(0, 0);
    getData();
    return () => ac.abort();
  }, []);
  return (
    <Observer>
      {() => (
        <>
          <SearchNavbar />
          {!loader ? (
            <Grid container justify="center" alignItems="center" style={{ marginTop:"8%" }} >
              <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
                <Card className="pt-3 pb-3 px-2 mt-4 mb-4" raised={true}>
                  <h4
                    className="text-center mb-3"
                    style={{
                  
                      // fontFamily:
                      //   store.langIndex == 0
                      //     ? "Raleway,sans-serif"
                      //     : store.langIndex == 1
                      //     ? "JameelNoori"
                      //     : "Arabic",
                      fontFamily:'JameelNoori',
                      color: Colors.primary,
                    }}
                  >
                    {articleTitle}
                  </h4>
                  {articleBody.map((data, index) => (
                    <div
                      key={index}
                      className="mx-2"
                      style={{
                        direction:"rtl",
                        textAlign:
                          paramFromArticleTitle.langIndex == 0
                            ? "left"
                            : "right",
                      }}
                    >
                      {ReactHtmlParser(decode(data._source.body))}
                    </div>
                  ))}
                </Card>
                {/* if fatwa id exist then button will show otherwise button will disappear */}
                {paramFromArticleTitle.fatwaId && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: Colors.primary,
                        color: Colors.white,
                        border: "none",
                        borderRadius: "5px",
                        width: 130,
                        height: 40,
                      }}
                    >
                      <a
                        style={
                          store.langIndex == 0
                            ? { fontFamily: "Raleway, sans-serif" }
                            : store.langIndex == 1
                            ? { fontFamily: "JameelNoori" }
                            : { fontFamily: "Arabic" }
                        }
                        id="more-btn"
                        href={`https://www.thefatwa.com/urdu/questionID/${paramFromArticleTitle.fatwaId}`}
                        target="_blank"
                      >
                        {TextTranslation.read_more[store.langIndex]}
                      </a>
                    </button>
                  </div>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              style={{ minHeight: "100vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Observer>
  );
};

export default ArticlesResult;
