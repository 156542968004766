import React, { useState, useEffect, useContext } from "react";
import { Grid, Card, CircularProgress } from "@material-ui/core";
import { Observer } from "mobx-react-lite";
import { StoreContext } from "../../store/store";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../Config/Colors";
import axios from "axios";
import "./QuranBookmarkTitle.css";
import Url from "../../Config/Url";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

const SpeechBookmarkTitle = ({title}) => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let history = useHistory();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [loader, setLoader] = useState(true);
  const [bookmarkSpeeches, setBookmarSpeeches] = useState([]);

  const getBookmarkSpeechesTitle = () => {
    var data = new FormData();
    data.append("title", title);
    axios({
      method: "post",
      url: `${Url.link}/get_speeches_bookmarks_title.php`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: store.id,
      },
    }).then((res) => {
      setBookmarSpeeches(res.data.MDE_API[0].MSG);
      console.log("Speeches", res.data.MDE_API[0].MSG);
      setLoader(false);
    });
  };

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    getBookmarkSpeechesTitle();
    return () => ac.abort();
  }, []);

  return (
    <Observer>
      {() => (
        <>
          {loader ? (
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              directioin="column"
              style={{ minHeight: "80vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center" justify="center">
              <Grid item xs={10} sm={10} md={10} lg={7} xl={6}>
                {bookmarkSpeeches.map((data, index) => (
                  <Card
                    onClick={() =>
                      history.push(`/speechbookmark/result/${data}`)
                    }
                    key={index}
                    className="bookmark-card mb-2 pt-3 pb-3"
                    raised={true}
                  >
                    <div
                      className="mx-3"
                      style={{
                        textAlign: /[a-zA-Z]/g.test(data) ? "left" : "right",
                      }}
                    >
                      {data}
                    </div>
                  </Card>
                ))}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Observer>
  );
};

export default SpeechBookmarkTitle;
