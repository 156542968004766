import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { StoreContext } from "../../store/store";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AndroidIcon from "@material-ui/icons/Android";
import GoogleStore from "../../images/play_store.png";
import AppStore from "../../images/App_Store.png";
import HuaweiAppStore from "../../images/huawei_download_image.png";
import Mobiles2 from "../../images/Mobiles2.png";
import WebApp from "../../images/WebApp.png";
import AppleIcon from "@material-ui/icons/Apple";
import CircularProgress from "@material-ui/core/CircularProgress";

import logo from "../../images/MIE_Dark_Black.png";
import Colors from "../../Config/Colors";
import TextTranslation from "../../Config/TextTranslation";
import facebook from "../../images/facebook.png";
import twitter from '../../images/twitter.png';
import instagram from '../../images/instagram.png';
import handshake from '../../images/handshake.png';
import { useHistory } from "react-router-dom";

import linkedin from '../../images/linkedin.png';
import tiktok from '../../images/tiktok.png';
import whatsapp from '../../images/whatsapp.png';
import youtube from '../../images/youtube.png';
import "./MainOverlay.css";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
  },
  fontRaleway: {
    fontsfontFamily: 'Raleway, sans-serif'
  },
  logo: {
    display: "flex",
    marginBottom: "3%",
    paddingTop: "5%",
    [theme.breakpoints.down("lg")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "10%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "20%",
    },
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: Colors.primary,
    fontFamily: "Raleway, sans-serif",
    color: Colors.black,
    "&:hover": {
      backgroundColor: Colors.black,
    },
  },
}));

const MainOverlay = () => {
  const history = useHistory();

  const store = useContext(StoreContext);
  const classes = useStyles();
  const [load, setLoad] = useState(false); // button loader state

  console.log(window.navigator.userAgent)
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {

      // <Button  target="_blank" rel="noreferrer noopener">Download</Button>
    }

    if (/android/i.test(userAgent)) {
      window.location.href = "https://play.google.com/store/apps/details?id=minhaj.islamic.encyclopedia"

    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "https://apps.apple.com/pk/app/minhaj-islamic-encyclopedia/id1609248178"

    }

    return "unknown";
  }
  // const RedirectPg=()=>{
  //   window.location.host==='download.minhaj.app' ? getMobileOperatingSystem():''
  // }

  // console.log( (getMobileOperatingSystem()));
  useEffect(() => {
    if (window.location.host === 'download.minhaj.app') {
      getMobileOperatingSystem()
    }

    console.log(window.location.host)
  }, [])
  return (
    <>


      {/* <div style={{height:'750px'}}> */}
      <div className="col-12 h-max p-0 d-flex bg-color-mobile bg-gradiant mb-3">

        <div className="col-md-12 col-lg-8 col-sm-12 pl-lg-5 p-3 text-lg-left text-center bg-color-mobile ">
          <div className="col-12 col-lg-12 px-4 py-3" >
            <div className={(classes.logo, "col-5 col-lg-8 m-auto m-lg-0 m-lg-6 ")}>
              <img src={logo} className="main_logo img-fluid img-responsive  " />
            </div>

          </div>
          <div className={(classes.logo, "col-12 col-lg-8 p-0 justify-content-center text-lg-left text-center")}
            style={
              store.langIndex == 0
                ? { fontFamily: "Raleway, sans-serif" }
                : store.langIndex == 1
                  ? {

                    textAlign: "right",
                    fontFamily: "JameelNoori",
                    fontSize: 16,

                  }
                  : {

                    textAlign: "right",
                    fontFamily: "Arabic",
                    fontSize: 16,

                  }} >
            {/* <h1 className="pl-2 fontRaleway display-4">MIE</h1> */}
            <h1 className="pl-2 fontRaleway text-break display-4 text-lg-left text-center" style={
              store.langIndex == 0
                ? { fontFamily: "Raleway, sans-serif" }
                : store.langIndex == 1
                  ? {

                    textAlign: "right",
                    fontFamily: "JameelNoori",
                    fontSize: 14,

                  }
                  : {

                    textAlign: "right",
                    fontFamily: "Arabic",
                    fontSize: 16,

                  }}>{TextTranslation.minhaj_islamic_encyclopedia[store.langIndex]}</h1>
            <p className="pl-2 fontRaleway paregraph text-lg-left text-center" style={
              store.langIndex == 0
                ? { fontFamily: "Raleway, sans-serif" }
                : store.langIndex == 1
                  ? {

                    textAlign: "right",
                    fontFamily: "JameelNoori",
                    fontSize: 19,
                  }
                  : {

                    textAlign: "right",
                    fontFamily: "Arabic",
                    fontSize: 19,

                  }}>

              {TextTranslation.overlay_text[store.langIndex]}
            </p>
          </div>
          <div className={(classes.logo, "col-12 col-lg-8  p-0 justify-content-center text-lg-left text-center  ")}
            style={
              store.langIndex == 0
                ? { fontFamily: "Raleway, sans-serif" }
                : store.langIndex == 1
                  ? {

                    textAlign: "right",
                    fontFamily: "JameelNoori",
                    fontSize: 16,

                  }
                  : {

                    textAlign: "right",
                    fontFamily: "Arabic",
                    fontSize: 16,

                  }} >
            {/* <h1 className="pl-2 fontRaleway display-4">MIE</h1> */}
            <h1 className="pl-2 fontRaleway text-break display-4 text-lg-left text-center" style={
              store.langIndex == 0
                ? { fontFamily: "Raleway, sans-serif" }
                : store.langIndex == 1
                  ? {

                    textAlign: "right",
                    fontFamily: "JameelNoori",
                    fontSize: 16,

                  }
                  : {

                    textAlign: "right",
                    fontFamily: "Arabic",
                    fontSize: 16,

                  }}>{TextTranslation.download[store.langIndex]}
              {/* <a href="https://www.minhaj.app/survey" style={{ fontFamily: "Raleway, sans-serif", fontSize: '16', color: '#27292E', textDecoration: 'none' }}><h1>Participate in Survey</h1></a> */}

            </h1>
          </div>
          <div className="col-lg-12 col-md-12 col-12 mb-3">
            <div className="col-lg-8 col-8 col-md-8 d-flex justify-content-center m-auto m-lg-0">
              <div className="col-lg-4 col-6 col-md-6 p-lg-1 p-md-1 p-1">
                <a target="_blank" rel="noreferrer noopener" href="https://play.google.com/store/apps/details?id=minhaj.islamic.encyclopedia" >
                  <img src={GoogleStore} className="img-responsive img-fluid" />
                </a>
              </div>
              <div className="col-lg-4 col-6 col-md-6 p-lg-1 p-md-1 p-1">
                <a target="_blank" rel="noreferrer noopener" href="https://apps.apple.com/pk/app/minhaj-islamic-encyclopedia/id1609248178" >
                  <img src={AppStore} className="img-responsive img-fluid" />
                </a>
              </div>
              <div className="col-lg-4 col-6 col-md-6 p-lg-1 p-md-1 p-1">
                <a target="_blank" rel="noreferrer noopener" href="https://appgallery.huawei.com/app/C109393717" >
                  <img src={HuaweiAppStore} className="img-responsive img-fluid" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-12 mb-3 d-flex " >
            <div className="col-lg-8 col-8 col-md-8 d-flex justify-content-center m-auto m-lg-0   ">
              {/* <div style={{ marginLeft: '15px' }}>
                <a target="_blank" rel="noreferrer noopener" href='https://wa.link/0cxply'>
                  <img style={{ width: '30px' }} src={whatsapp} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                </a>
              </div> */}
              <div style={{ marginLeft: '15px' }}>
                <a target="_blank" rel="noreferrer noopener" href='https://www.instagram.com/minhaj.app/'>
                  <img style={{ width: '30px' }} src={instagram} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                </a>
              </div>
              <div style={{ marginLeft: '15px' }}>
                <a target="_blank" rel="noreferrer noopener" href='https://fb.com/MinhajApp'>
                  <img style={{ width: '30px' }} src={facebook} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                </a>
              </div>
              <div style={{ marginLeft: '15px' }}>
                <a target="_blank" rel="noreferrer noopener" href='https://twitter.com/MinhajApp'>
                  <img style={{ width: '30px' }} src={twitter} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                </a>
              </div>
              <div style={{ marginLeft: '15px' }}>
                <a target="_blank" rel="noreferrer noopener" href='https://www.youtube.com/@MinhajApp'>
                  <img style={{ width: '30px' }} src={youtube} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                </a>
              </div>
              <div style={{ marginLeft: '15px' }}>
                <a target="_blank" rel="noreferrer noopener" href='https://www.linkedin.com/company/MinhajApp'>
                  <img style={{ width: '30px' }} src={linkedin} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                </a>
              </div>
              <div style={{ marginLeft: '15px' }}>
                <a target="_blank" rel="noreferrer noopener" href=' https://www.tiktok.com/@minhajapp'>
                  <img style={{ width: '30px' }} src={tiktok} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                </a>
              </div>


            </div>
          </div>
          <div className="row center-button px-3">

            <Button
              className="col-lg-4 mx-1 col-md-6 col-sm-12 col-12 btn mb-3 p-2 btn-radius"
              type="submit"
              // fullWidth
              onClick={() => {
                history.push('/join');
              }}
              variant="contained"
              style={{
                backgroundColor: Colors.primary,
                color: Colors.white,
              }}
            >
              {load ? (
                <CircularProgress
                  size={30}
                  style={{ color: Colors.white }}
                />
              ) : (
                <span
                  style={
                    store.langIndex == 0
                      ? { fontFamily: "Raleway, sans-serif" }
                      : store.langIndex == 1
                        ? { fontFamily: "jameelNoori", fontSize: 18 }
                        : { fontFamily: "Arabic", fontSize: 18 }
                  }
                >
                  <img style={{ width: '30px' }} src={handshake} className="img-fluid img-responsive mt-0 pt-0 w-5 mx-2" />
                  Join Team of Contributors
                </span>
              )}
            </Button>
            <a target="_blank" className="col-lg-4 mb-3 mx-1 col-12 col-md-6 col-sm-12 p-0 text-decoration-none" rel="noreferrer noopener" href='https://wa.link/0cxply'>

              <Button target="_blank"
                className="btn p-2 btn-radius d-flex justify-content-center align-items-center "
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  backgroundColor: '#25D366',
                  color: Colors.white,
                }}
              >
                {load ? (
                  <CircularProgress
                    size={30}
                    style={{ color: Colors.white }}
                  />
                ) : (
                  <span
                    style={
                      store.langIndex == 0
                        ? { fontFamily: "Raleway, sans-serif" }
                        : store.langIndex == 1
                          ? { fontFamily: "jameelNoori", fontSize: 18 }
                          : { fontFamily: "Arabic", fontSize: 18 }
                    }
                  >
                    <img style={{ width: '30px' }} src={whatsapp} className="img-fluid img-responsive mt-0 pt-0 w-5 mx-2" />
                    Support/Feedback
                  </span>
                )}
              </Button>
            </a>
          </div>
        </div>


      </div>
      <div className="overlap p-0 m-0">
        <div className="col-11 d-flex justify-content-center">
          <img src={Mobiles2} className="img-fluid img-responsive pt-0 my-4 m-lg-0 w-75" />
        </div>
      </div>

      <div className=" m-auto  justify-content-center footer mt-3 col-12 col-md-12 d-flex d-lg-none text-left text-sm-center text-md-center text-lg-left text-xl-left bg-gray ">
        <div className="col-3 ">
          <Link
            to="/aboutus"
            className={classes.link}
            style={{
              color: Colors.theme_c_dbw[store.theme],
              fontFamily:
                store.langIndex == 0
                  ? "Raleway, sans-serif"
                  : store.langIndex == 1
                    ? "JameelNoori"
                    : "Arabic",
              fontSize:
                store.langIndex == 0
                  ? 17
                  : store.langIndex == 1
                    ? 15
                    : 13,
            }}
          >
            {TextTranslation.about_us[store.langIndex]}
          </Link>
        </div>
        <div className="col-3">
          <Link
            to="/features"
            className={classes.link}
            style={{
              color: Colors.theme_c_dbw[store.theme],
              fontFamily:
                store.langIndex == 0
                  ? "Raleway, sans-serif"
                  : store.langIndex == 1
                    ? "JameelNoori"
                    : "Arabic",
              fontSize:
                store.langIndex == 0
                  ? 17
                  : store.langIndex == 1
                    ? 15
                    : 13,
            }}
          >
            {TextTranslation.features[store.langIndex]}
          </Link>
        </div>
        <div className="col-3">
          <Link
            to="/contactus"
            className={classes.link}
            style={{
              color: Colors.theme_c_dbw[store.theme],
              fontFamily:
                store.langIndex == 0
                  ? "Raleway, sans-serif"
                  : store.langIndex == 1
                    ? "JameelNoori"
                    : "Arabic",
              fontSize:
                store.langIndex == 0
                  ? 17
                  : store.langIndex == 1
                    ? 15
                    : 13,
            }}
          >
            {TextTranslation.suggestion_contact_us[store.langIndex]}
          </Link>
        </div>
      </div>



      <div className="mt-5 col-12 d-none d-lg-flex footer text-left text-lg-center pt-4 pl-md-0 p-md-3 pl-xl-5 pl-lg-5 text-center bg-color-mobile text-lg-left text-xl-left">
        <div className="col-2 col-md-1">
          <Link
            to="/aboutus"
            className={classes.link}
            style={{
              color: 'black',
              fontFamily:
                store.langIndex == 0
                  ? "Raleway, sans-serif"
                  : store.langIndex == 1
                    ? "JameelNoori"
                    : "Arabic",
              fontSize:
                store.langIndex == 0
                  ? 17
                  : store.langIndex == 1
                    ? 15
                    : 13,
            }}
          >
            {TextTranslation.about_us[store.langIndex]}
          </Link>
        </div>
        <div className="col-2 col-md-1">
          <Link
            to="/features"
            className={classes.link}
            style={{
              color: 'black',
              fontFamily:
                store.langIndex == 0
                  ? "Raleway, sans-serif"
                  : store.langIndex == 1
                    ? "JameelNoori"
                    : "Arabic",
              fontSize:
                store.langIndex == 0
                  ? 17
                  : store.langIndex == 1
                    ? 15
                    : 13,
            }}
          >
            {TextTranslation.features[store.langIndex]}
          </Link>
        </div>
        <div className="col-2 col-md-1">
          <Link
            to="/contactus"
            className={classes.link}
            style={{
              color: 'black',
              fontFamily:
                store.langIndex == 0
                  ? "Raleway, sans-serif"
                  : store.langIndex == 1
                    ? "JameelNoori"
                    : "Arabic",
              fontSize:
                store.langIndex == 0
                  ? 17
                  : store.langIndex == 1
                    ? 15
                    : 13,
            }}
          >
            {TextTranslation.suggestion_contact_us[store.langIndex]}
          </Link>
        </div>

      </div>

    </>

  );
};

export default MainOverlay;
