import React, { useContext, useState,useEffect } from "react";
import { Observer } from "mobx-react-lite";
import { Formik, Form } from "formik";
import { StoreContext } from "../../store/store";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {
  Container,
  TextField,
  CircularProgress,
  Button,
} from "@material-ui/core";
import TextTranslation from "../../Config/TextTranslation";
import logo from "../../images/MIE_Light.png";
import Colors from "../../Config/Colors";
import moment from "moment";
import * as yup from "yup";
import axios from "axios";
import Url from "../../Config/Url";

// styles
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20%",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const EnterCode = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let history = useHistory();
  const store = useContext(StoreContext);
  const classes = useStyles(); // styles classes
  const [load, setLoad] = useState(false); // loading state on button
  const [show, setShow] = useState(true);
 
  //validation schema
  let EnterCodeSchema = yup.object().shape({
    code: yup
      .string()
      .required(TextTranslation.required_code_msg[store.langIndex]),
  });

  const forgetCode = (code) => {
    var bodyFormData = new FormData();
    bodyFormData.append("pwd_token", code);
    axios({
      method: "post",
      url: `${Url.link}/check_pwd_token.php?`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        console.log(response.data.MDE_API[0]);

        if (response.data.MDE_API[0].success == 0) {
          alert(TextTranslation.token_expiry_error[store.langIndex]);
        } else {
          history.push("/enterpassword");
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  const ResendCode = () => {
    localStorage.getItem("email");

    fetch(
      `${Url.link}/forg_pass.php?email=`, +
        "",
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        forgetCode(responseJson);

        alert(TextTranslation.code_send_to_your_email[store.langIndex]);
        //setShow(false);
      })
      .catch((error) => {
        alert(JSON.stringify(error));
        console.log(JSON.stringify(error));
      })

      .catch((err) => console.log("Email" + err));
  };
  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
  }, []);

  return (
    <Observer>
      {() => (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <div className="pt-5 pb-5 d-flex col-5 justify-content-center">
              <img src={logo} alt="" className="img-fluid" />
            </div>

            {/*  Form */}
            <Formik
              fullWidth
              initialValues={{
                code: "",
              }}
              validationSchema={EnterCodeSchema}
              onSubmit={(values) => {
                forgetCode(values.code);
              }}
            >
              {({ errors, handleChange, touched, handleBlur, isValid }) => (
                // form fields
                <Form>
                  {store.langIndex == 0 ? (
                    <TextField
                      fullWidth
                      error={errors.code && touched.code}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2"
                      label={TextTranslation.inp_plac_code[store.langIndex]}
                      name="code"
                      helperText={
                        errors.code && touched.code ? errors.code : null
                      }
                    />
                  ) : (
                    <TextField
                      fullWidth
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                              },
                            }
                      }
                      error={errors.code && touched.code}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mb-2"
                      placeholder={
                        TextTranslation.inp_plac_code[store.langIndex]
                      }
                      name="code"
                      helperText={
                        errors.code && touched.code ? errors.code : null
                      }
                    />
                  )}
                  {/* Submit button */}
                  <Button
                    className="mt-3 btn"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                    }}
                    disabled={!isValid}
                  >
                    {load ? (
                      <CircularProgress
                        size={30}
                        style={{ color: Colors.white }}
                      />
                    ) : (
                      <span
                        style={
                          store.langIndex == 0
                            ? { fontFamily: "Raleway, sans-serif" }
                            : store.langIndex == 1
                            ? { fontFamily: "JameelNoori", fontSize: 18 }
                            : { fontFamily: "Arabic", fontSize: 18 }
                        }
                      >
                        {TextTranslation.sub[store.langIndex]}
                      </span>
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
            <br />
            {/* count down timer circle */}
            <CountdownCircleTimer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              isPlaying
              size={80}
              strokeWidth={6}
              duration={90} // 90 seconds
              // color divison according to every 30 seconds
              colors={[
                ["#004777", 0.4],
                ["#F7B801", 0.4],
                ["#A30000", 0.2],
              ]}
            >
              {({ remainingTime }) =>
                remainingTime == 0 && show == true ? (
                  <div
                    onClick={() => ResendCode()}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          color: "black",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          fontFamily:'Raleway, sans-serif',
                          fontSize: 14
                        }}
                      >
                        {TextTranslation.resend_code_msg[store.langIndex]}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>{moment.utc(remainingTime * 1000).format("mm:ss")}</div>
                )
              }
            </CountdownCircleTimer>
          </div>
        </Container>
      )}
    </Observer>
  );
};

export default EnterCode;
