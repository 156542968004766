import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from "../../../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Observer } from "mobx-react-lite";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextTranslation from "../../../../Config/TextTranslation";
import Colors from "../../../../Config/Colors";
import HighlightText from "../../../../CustomComponents/HighlightText";
import EngText from "../../../../CustomComponents/EngText";
import UrduText from "../../../../CustomComponents/UrduText";
import ArabicText from "../../../../CustomComponents/ArabicText";
import TopicCard from "../../../../CustomComponents/TopicCard";
import axios from "axios";
import "./Book.css";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

const Book = ({ search }) => {
  const classes = useStyles();
  let history = useHistory();
  const store = useContext(StoreContext);
  const [sentence, setSentence] = useState(""); // sentence state
  const [from, setFrom] = useState(0); // from state
  const [loader, setLoader] = useState(true); // loader state
  const [loadMore, setLoadMore] = useState(); // show more state

  const getBookSentenceData = () => {
    axios({
      method: "post",
      url: "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_books/_search",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
      },
      data: JSON.stringify({
        from: from,
        size: 10,
        query: {
          query_string: {
            query: "*" + search + "*",
          },
        },
      }),
    }).then((res) => {
      console.log(res.data);
      // book counter which is setting in store
      store.setBookCounter(res.data.hits.total.value);
      
      // if results are more than 10 than show more button will show, otherwise button will not show 
      {
        res.data.hits.hits.length >= 10
          ? setLoadMore(true)
          : setLoadMore(false);
      }

      setSentence([...sentence, ...res.data.hits.hits]);
      setFrom(from + 10);
      setLoader(false);
    });
  };

  useEffect(() => {
    const ac = new AbortController();
    getBookSentenceData();
    return () => ac.abort();
  }, [search, store.book_counter]);

  return (
    <Observer>
      {() => (
        <div>
          {!loader ? (
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={10} lg={7} xl={6}>
                <div>
                  {sentence.length < 1 ? (
                    <div className="text-center mt-5 pt-5">
                      {store.langIndex == 0 ? (
                        <EngText styles={{ textAlign: "center" }}>
                          <HighlightText
                            simpleText={`${TextTranslation.no_resultTab_found} "${search}" ${TextTranslation.books_resultEnd}`}
                            colorText={search}
                          />
                        </EngText>
                      ) : store.langIndex == 1 ? (
                        <UrduText styles={{ textAlign: "center" }}>
                          <HighlightText
                            simpleText={`آپ کے تلاش کردہ لفظ "${search}" کے مطابق "کتب سیکشن" میں کوئی نتائج نہیں ملے`}
                            colorText={search}
                          />
                        </UrduText>
                      ) : (
                        <ArabicText styles={{ textAlign: "center" }}>
                          <HighlightText
                            simpleText={`لا توجد نتائج مقابل كلمة "${search}" من كتاب منهاج`}
                            colorText={search}
                          />
                        </ArabicText>
                      )}
                    </div>
                  ) : (
                    <div>
                      {store.langIndex == 0 ? (
                        <EngText>
                          <HighlightText
                            simpleText={`${store.book_counter} ${TextTranslation.resultStart} "${search}" ${TextTranslation.books_resultEnd}`}
                            colorText={search}
                          />
                        </EngText>
                      ) : store.langIndex == 1 ? (
                        <UrduText>
                          <HighlightText
                            simpleText={`آپ کے تلاش کردہ لفظ "${search}" کے مطابق "کتب سیکشن" میں ${store.book_counter} نتائج ملے ہیں `}
                            colorText={search}
                          />
                        </UrduText>
                      ) : (
                        <ArabicText>
                          <HighlightText
                            simpleText={`تم العثور على ${store.book_counter} نتيجة مقابل كلمة "${search}" من كتاب منهاج`}
                            colorText={search}
                          />
                        </ArabicText>
                      )}

                      {sentence.map((data, index) => (
                        <TopicCard
                          key={index}
                          // there are 2 routes; one with sentence id and other is without sentence id
                          onClick={() => {
                            history.push(
                              data._source.sentence_id
                                ? `/selectedresult/Book/${data._source.book_id}/${data._source.chapter_id}/${data._source.sentence_id}/${data._source.lang_id}`
                                : `/selectedresult/Book/${data._source.book_id}/${data._source.chapter_id}/${data._source.lang_id}`
                            );
                          }}
                          bookName={
                            store.langIndex == 0
                              ? data._source.en_book_title
                              : data._source.ur_book_title
                          }
                        >
                          {store.langIndex == 0 ? (
                            <EngText>
                              <HighlightText
                                simpleText={data._source.sentence}
                                colorText={search}
                              />
                            </EngText>
                          ) : store.langIndex == 1 ? (
                            <UrduText>
                              <HighlightText
                                simpleText={data._source.sentence}
                                colorText={search}
                              />
                            </UrduText>
                          ) : (
                            <ArabicText>
                              <HighlightText
                                simpleText={data._source.sentence}
                                colorText={search}
                              />
                            </ArabicText>
                          )}
                        </TopicCard>
                      ))}
                    </div>
                  )}
                </div>
                {loadMore == false ? null : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <button
                    // on click next 10 results wil show and so on ...
                      onClick={() => getBookSentenceData()}
                      style={{
                        backgroundColor: Colors.primary,
                        color: Colors.white,
                        border: "none",
                        borderRadius: "5px",
                        height: 40,
                      }}
                    >
                      <div
                        style={
                          store.langIndex == 0
                            ? { fontFamily: "Raleway, sans-serif" }
                            : store.langIndex == 1
                            ? { fontFamily: "JameelNoori", fontSize: 18 }
                            : { fontFamily: "AlQalam", fontSize: 18 }
                        }
                      >
                        {TextTranslation.show_more[store.langIndex]}
                      </div>
                    </button>
                  </div>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              style={{ minHeight: "100vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </Observer>
  );
};

export default Book;
