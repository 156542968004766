import React, { useContext } from "react";
import { Observer } from "mobx-react-lite";
import { StoreContext } from "../../store/store";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Colors from "../../Config/Colors";
import books from './MDEFeatures/books.png';
import mic from './MDEFeatures/mic.png';
import fatwa from './MDEFeatures/features_5.png';
import search_engine from './MDEFeatures/search_engine.png';
import life_cycle from './MDEFeatures/life_cycle.png';
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import TextTranslation from "../../Config/TextTranslation";

import "./Features.css";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "90vh",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    margin: "auto",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "2%",
  
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    color: Colors.primary,
    fontFamily: "Raleway, sans-serif",
  
  },
  detail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "auto",
    fontFamily: "Raleway, sans-serif",
  
  },

  img: {
    overflow: "hidden",
    display: "block",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const Features = () => {
  const store = useContext(StoreContext);
  const classes = useStyles();
  const theme = useTheme();


  return (
    <Observer style={{ height: "100%;" }}>
      {() => (
        <>
          <CommonNavbar />
          <div style={{ clear:"both" }}> </div>
          <div className="col-12 pt-3 pb-5">
            <div className="display-4 text-center py-5 col-12"  style={{
                fontFamily:
                  store.langIndex == 0
                    ? "Raleway,sans-serif"
                    : store.langIndex == 1
                    ? "JameelNoori"
                    : "Arabic",}}>
              MIE {TextTranslation.mde_features_header[store.langIndex]}
            </div>
            <div className="container pb-5">
              <div className="col-12 d-sm-flex d-block">
                <div className="col-sm-6 col-12 m-auto">
                  <h1 className="font-weight-bold py-2" style={ 
                     store.langIndex == 0 ? { textAlign: "left",fontFamily: "Raleway, sans-serif" }:
                        store.langIndex == 1
                          ? {
                               textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                              textAlign: "right",
                              fontFamily: "Arabic",
                            }}>{TextTranslation.onboarding_screen1_title[store.langIndex]}</h1>
                  <p style={ 
                     store.langIndex == 0 ? { textAlign: "left",fontFamily: "Raleway, sans-serif" }:
                        store.langIndex == 1
                          ? {
                               textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                              textAlign: "right",
                              fontFamily: "Arabic",
                            }}>{TextTranslation.onboarding_screen1_string[store.langIndex]}</p>
                  </div>
                <div className="col-sm-6 col-12">
                  <div className="col-sm-10 col-12 p-2">
                    <img src={books} className="img-fluid image-rendering-optimizequality"/>
                  </div>
                </div>
              </div>
              <div className="col-12 d-sm-flex  flex-sm-row-reverse flex-row d-block">
                <div className="col-sm-6 col-12 m-auto">
                  <h1 className="font-weight-bold py-2" style={ 
                     store.langIndex == 0 ? { textAlign: "left",fontFamily: "Raleway, sans-serif" }:
                        store.langIndex == 1
                          ? {
                               textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                              textAlign: "right",
                              fontFamily: "Arabic",
                            }} >
                    {TextTranslation.onboarding_screen3_title[store.langIndex]} {TextTranslation.onBoarding_AI[store.langIndex]}
                  </h1>
                  <p style={ 
                     store.langIndex == 0 ? { textAlign: "left",fontFamily: "Raleway, sans-serif" }:
                        store.langIndex == 1
                          ? {
                               textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                              textAlign: "right",
                              fontFamily: "Arabic",
                            }}> 
                  {TextTranslation.onboarding_screen3_string[store.langIndex]} </p>
                  </div>
                <div className="col-sm-6 col-12  ">
                  <div className="col-sm-10 col-12 p-2">
                    <img src={mic} className="img-fluid image-rendering-optimizequality"/>
                  </div>
                </div>
              </div>
              <div className="col-12 d-sm-flex d-block">
                <div className="col-sm-6 col-12 m-auto">
                <h1 className="font-weight-bold py-2" style={ 
                     store.langIndex == 0 ? { textAlign: "left",fontFamily: "Raleway, sans-serif" }:
                        store.langIndex == 1
                          ? {
                               textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                              textAlign: "right",
                              fontFamily: "Arabic",
                            }} >
                    {TextTranslation.onboarding_screen5_title[store.langIndex]} 
                  </h1>
                  <p style={ 
                     store.langIndex == 0 ? { textAlign: "left",fontFamily: "Raleway, sans-serif" }:
                        store.langIndex == 1
                          ? {
                               textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                              textAlign: "right",
                              fontFamily: "Arabic",
                            }}> 
                  {TextTranslation.onboarding_screen5_string[store.langIndex]} </p>
                  </div>
                <div className="col-sm-6 col-12  ">
                  <div className="col-sm-10 col-12 p-2">
                    <img src={fatwa} className="img-fluid image-rendering-optimizequality"/>
                  </div>
                </div>
              </div>
              <div className="col-12 d-sm-flex flex-sm-row-reverse flex-row d-block">
                <div className="col-sm-6 col-12 m-auto">
                <h1 className="font-weight-bold py-2" style={ 
                     store.langIndex == 0 ? { textAlign: "left",fontFamily: "Raleway, sans-serif" }:
                        store.langIndex == 1
                          ? {
                               textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                              textAlign: "right",
                              fontFamily: "Arabic",
                            }} >
                    {TextTranslation.onboarding_screen2_title[store.langIndex]}
                  </h1>
                  <p style={ 
                     store.langIndex == 0 ? { textAlign: "left",fontFamily: "Raleway, sans-serif" }:
                        store.langIndex == 1
                          ? {
                               textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                              textAlign: "right",
                              fontFamily: "Arabic",
                            }}> 
                  {TextTranslation.onboarding_screen2_string[store.langIndex]} </p>                  
                  </div>
                <div className="col-sm-6 col-12 ">
                  <div className="col-sm-10 col-12 p-2">
                    <img src={search_engine} className="img-fluid image-rendering-optimizequality"/>
                  </div>
                </div>
              </div>
              <div className="col-12 d-sm-flex d-block pb-5">
                <div className="col-sm-6 col-12 m-auto">
                <h1 className="font-weight-bold py-2" style={ 
                     store.langIndex == 0 ? { textAlign: "left",fontFamily: "Raleway, sans-serif" }:
                        store.langIndex == 1
                          ? {
                               textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                              textAlign: "right",
                              fontFamily: "Arabic",
                            }} >
                    {TextTranslation.onboarding_screen4_title[store.langIndex]}
                  </h1>
                  <p style={ 
                     store.langIndex == 0 ? { textAlign: "left",fontFamily: "Raleway, sans-serif" }:
                        store.langIndex == 1
                          ? {
                               textAlign: "right",
                                fontFamily: "JameelNoori",
                            }
                          : {
                              textAlign: "right",
                              fontFamily: "Arabic",
                            }}> 
                  {TextTranslation.onboarding_screen4_string[store.langIndex]} </p> 
                  </div>
                <div className="col-sm-6 col-12  ">
                  <div className="col-sm-10 col-12 p-2">
                    <img src={life_cycle} className="img-fluid image-rendering-optimizequality"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Observer>
  );
};

export default Features;
