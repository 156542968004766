import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { Grid, Card } from "@material-ui/core";
import { useHistory } from "react-router";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Colors from "../../Config/Colors";
import axios from "axios";
import "./UserArticle.css";
import EngText from "../../CustomComponents/EngText";
import UrduText from "../../CustomComponents/UrduText";
import ArabicText from "../../CustomComponents/ArabicText";
import Url from "../../Config/Url";


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

const UserArticle = (props) => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const classes = useStyles();
  const store = useContext(StoreContext);
  const history = useHistory();
  const [title, setTitle] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [loader, setLoader] = useState(true);

  const id = () => {
    axios({
      method: "post",
      url: `${Url.link}/get_article_titles_of_user.php`,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    })
      .then(function (response) {
        //handle success
        console.log(response.data.MDE_API[0].MSG);

        setLoader(false);
        if (response.data.MDE_API[0].MSG.length < 1) {
          setEmpty(true);
        } else {
          setTitle(response.data.MDE_API[0].MSG);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    id();
    return () => ac.abort();
  }, []);

  return (
    <>
      <CommonNavbar />
      <Grid
        container
        spacing={0}
        justify="center"
        directioin="column"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={10} sm={10} md={10} lg={7} xl={6}>
          <EngText styles={{textAlign:'right', margin: '20px 0px'}}>Total Articles : {title.length}</EngText>
          {loader ? (
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              style={{ minHeight: "100vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          ) : empty ? (
            <h2 className="text-center">Empty</h2>
          ) : (
            title.map((data, index) => (
              <Card
                className="article-card  mt-2 mb-2"
                raised={true}
                onClick={() =>
                  history.push(`/userarticle/${data.article_title}/${data.uid}`)
                }
                key={index}
              >
                <div
                  className="mx-3 pt-3 pb-3"
                  style={
                    store.langIndex == 0
                      ? {
                          fontFamily: /[a-zA-Z]/g.test(data.article_title)
                            ? "Raleway,sans-serif"
                            : "JameelNoori",
                          textAlign: /[a-zA-Z]/g.test(data.article_title)
                            ? "left"
                            : "right",
                        }
                      : store.langIndex == 1
                      ? {
                          fontFamily: /[a-zA-Z]/g.test(data.article_title)
                            ? "Raleway,sans-serif"
                            : "JameelNoori",
                          fontSize: 18,
                          textAlign: /[a-zA-Z]/g.test(data.article_title)
                            ? "left"
                            : "right",
                        }
                      : {
                          fontFamily: "Arabic",
                          fontSize: 18,
                          textAlign: /[a-zA-Z]/g.test(data.article_title)
                            ? "left"
                            : "right",
                        }
                  }
                >
                  {data.article_title}
                </div>
              </Card>
            ))
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default UserArticle;
