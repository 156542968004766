import React, { useContext, useEffect } from "react";
import { StoreContext } from "../../store/store";
import { Observer } from "mobx-react-lite";
import { Grid } from "@material-ui/core";
import MainNavbar from "../../Navbars/MainNavbar/MainNavbar";
import Languages from "../../Utils/Languages/Languages";
import Footer from "../../Utils/Footer/Footer";
import Colors from "../../Config/Colors";
import logo from "../../images/MIE_Light.png";
import MainOverlay from "../../Utils/MainOverlay/MainOverlay";
import GoogleStore from "../../images/GoogleStore.png";
import AppStore from "../../images/AppStore.png";
import NewMainSearch from "../../NewComponents/NewMainSearch/NewMainSearch";
import "./MainScreen.css";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";


const MainScreen = () => {
  const store = useContext(StoreContext);
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    return () => ac.abort();
  }, []);

  return (
    <Observer>
      {() =>
          <div className="main" style={{ 
            backgroundColor:Colors.theme_bg_wb[store.theme],
            color:Colors.theme_c_bw[store.theme]
             }}>
            <CommonNavbar />
            <div className="col-12 mt-7">
              <div className="logo-bar-div mb-5">
                <img src={logo} alt="" className="main_logo" />
              </div>
            
                <NewMainSearch />
              
            </div>
          <Languages />
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "static",
                }}
              >
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://play.google.com/store/apps/details?id=minhaj.islamic.encyclopedia"
                >
                  <img src={GoogleStore} alt="google" />
                </a>
                &nbsp;&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://testflight.apple.com/join/kF2jyEuV"
                >
                  <img src={AppStore} alt="google" />
                </a>
              </Grid>
            </Grid>
             {/* <Footer /> */}
          </div>
   
      }
    </Observer>
  );
};

export default MainScreen;
