import React from "react";
import { Route, Switch } from "react-router-dom";
import MainScreen from "../../Screens/MainScreen/MainScreen";
import Login from "../Login/Login";
import Register from "../Register/Register";
import EditProfile from "../EditProfile/EditProfile";
import TopicResult from "../ResultTabs/Tabs/Quran/TopicResult";
import UserArticle from "../UserArticles/UserArticle";
import UserArticleSelected from "../UserArticles/UserArticleSelected";
import UserBookmarksMain from "../UserBookmarks/UserBookmarksMain";
import AyatBookmarkTitle from "../UserBookmarks/AyatBookmarkTitle";
import AyatBookmarkResult from "../UserBookmarks/AyatBookmarkResult";
import QuranBookmarkTitle from "../UserBookmarks/QuranBookmarkTitle";
import QuranBookmarkResult from "../UserBookmarks/QuranBookmarkResult";
import PublicArticleTitle from "../PublicArticles/PublicArticleTitle";
import PublicArticleSelected from "../PublicArticles/PublicArticleSelected";
import PublicArticleSelectedView from "../PublicArticles/PublicArticleSelectedView";
import UserHistory from "../UserHistory/UserHistory";
import AboutUs from "../AboutUs/AboutUs";
import Launch from "../Launch/Launch";
import Join from "../Join/Joining";
import ContactUs from "../ContactUs/ContactUs";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import Features from "../Features/Features.jsx";
import EnterEmail from "../ForgetPassword/EnterEmail";
import EnterCode from "../ForgetPassword/EnterCode";
import EnterPassword from "../ForgetPassword/EnterPassword";
import SpeechBookmarkTitle from "../UserBookmarks/SpeechBookmarkTitle";
import SpeechBookmarkResult from "../UserBookmarks/SpeechBookmarkResult";
import PageNotFound from "../PageNotFound/PageNotFound";
import NewResultSearch from "../../NewComponents/NewResultSearch/NewResultSearch";
import MainOverlay from "../../Utils/MainOverlay/MainOverlay";
import SelectedSpeech from "../ResultTabs/Tabs/Speeches/SelectedSpeech";
import SelectedBookResult from "../ResultTabs/Tabs/Book/SelectedBookResult";
import ArticlesResult from "../ResultTabs/Tabs/Articles/ArticlesResult";
import NewArticle from "../CreateNewArticle/NewArticle";
import MDEAppArticlesResult from "../ResultTabs/Tabs/Articles/MDEAppArticlesResult";
import MDEAppSelectedBookResult from "../ResultTabs/Tabs/Book/MDEAppSelectedBookResult";
import AboutAuthor from "../AboutAuthor/AboutAuthor";
import Table from "../Table";
import AboutQuranicEncyclopedia from "../AboutQuranicEncyclopedia/AboutQuranicEncyclopedia";
import Tablecontent from "../Tablecontent";
import Survey from "../Survay/Survey";


const Routes = () => {
  // console.log(window.location.host)
  
  return (
        <Switch>
          <Route exact path="/" component={MainOverlay} />
          <Route path="/features" component={Features} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/aboutus" component={AboutUs} />
          <Route exact path="/survey"  component={Survey} />


          {/* <Route path="/launch" component={Launch} /> */}
          <Route path="/join" component={Join} />
          <Route path="/team" component={Join} />

          <Route path="/searchengine" component={MainScreen} />
          {/* if user loggedIn then main screen else login */}
          <Route
            path="/login"
            render={() =>
              localStorage.getItem("@uuid") ? <MainScreen/> : <Login />
            }
          />
          {/* if user Registerd then main screen else register */}
          <Route
            path="/register"
            render={() =>
              localStorage.getItem("@uuid") ? <MainScreen/> : <Register />
            }
          />
          <Route path="/enteremail" component={EnterEmail} />
          <Route path="/entercode" component={EnterCode} />
          <Route path="/enterpassword" component={EnterPassword} />
          <Route path="/editprofile" component={EditProfile} />
          <Route path="/result/:initial/:search/:langIndex" component={NewResultSearch} />
          <Route
            path="/selectedresult/Speech/:title/:video_id/:start_time/:id"
            component={SelectedSpeech}
          />

          <Route
            path="/selectedresult/Quran/:selectedSearch/:langIndex"
            component={TopicResult}
          />
          <Route
            path="/selectedresult/Book/:selectedBookId/:selectedChapterId/:selectedSentenceId/:selectedLangId"
            component={SelectedBookResult}
          />
          <Route
            path="/selectedresult/Book/:selectedBookId/:selectedChapterId/:selectedLangId"
            component={SelectedBookResult}
          />
          <Route
            path="/Book/:selectedBookId/:selectedChapterId/:selectedSentenceId/:selectedLangId"
            component={MDEAppSelectedBookResult}
          />
          <Route
            path="/Book/:selectedBookId/:selectedChapterId/:selectedLangId"
            component={MDEAppSelectedBookResult}
          />
          <Route
            // path="/selectedresult/Fatwa/:selectedArticleTitle/:selectedFatwaId/:fatwaId"
            path="/selectedresult/Fatwa/:selectedFatwaId/:fatwaId:/langIndex"
            component={ArticlesResult}
          />
          <Route
            // path="/selectedresult/Fatwa/:selectedArticleTitle/:selectedFatwaId/:fatwaId"
            path="/selectedresult/Fatwa/:selectedFatwaId/:langIndex"
            component={ArticlesResult}
          />
          <Route
            path="/Article/:selectedFatwaId/:langIndex"
            component={MDEAppArticlesResult}
          />
          <Route
            path="/Article/:selectedFatwaId/:fatwaId:/langIndex"
            component={MDEAppArticlesResult}
          />
          {/* if user loggedIn then history screen else login */}
          <Route
            path="/userhistory"
            render={() =>
              localStorage.getItem("@uuid") ? <UserHistory /> : <Login />
            }
          />
          <Route
            path="/userbookmarks"
            render={() =>
              localStorage.getItem("@uuid") ? <UserBookmarksMain /> : <Login />
            }
          />
          <Route
            path="/ayatbookmarktitle"
            render={() =>
              localStorage.getItem("@uuid") ? <AyatBookmarkTitle /> : <Login />
            }
          />
          <Route
            path="/ayatbookmark/result/:title"
            render={() =>
              localStorage.getItem("@uuid") ? (
                <AyatBookmarkResult />
              ) : (
                <Login />
              )
            }
          />
          
            {/* if user loggedIn then quran bookmarks else login */}
          <Route
            path="/quranbookmarktitle"
            render={() =>
              localStorage.getItem("@uuid") ? <QuranBookmarkTitle /> : <Login />
            }
          />
          <Route
            path="/quranbookmark/result/:title"
            render={() =>
              localStorage.getItem("@uuid") ? (
                <QuranBookmarkResult />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/speechbookmarktitle"
            render={() =>
              localStorage.getItem("@uuid") ? (
                <SpeechBookmarkTitle />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/speechbookmark/result/:title"
            render={() =>
              localStorage.getItem("@uuid") ? (
                <SpeechBookmarkResult />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/userarticle/:name/:id"
            component={UserArticleSelected}
          />
          <Route path="/userarticle" component={UserArticle} />
          <Route path="/publicarticletitle" component={PublicArticleTitle} />
          <Route
            path="/publicarticleselected/:id"
            component={PublicArticleSelected}
          />
          <Route
            path="/publicarticleselectedview/:title/:id"
            component={PublicArticleSelectedView}
          />

          <Route path="/newatricle" component={NewArticle} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/aboutauthor" component={AboutAuthor} />
          <Route exact path="/table"  component={Table} />
          <Route path="/tablecontent/:ch/:cat"  component={Tablecontent} />
          <Route
            path="/aboutencyclopedia"
            component={AboutQuranicEncyclopedia}
          />
          <Route component={PageNotFound} />


        </Switch>
  );
};

export default Routes;
