import React from "react";

const Hadith = () => {
  return (
    <div>
      <h4 className="text-center mt-5">
        <small>Hadith Component is Coming Soon</small>
      </h4>
    </div>
  );
}

export default Hadith;
