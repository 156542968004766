import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { StoreContext } from "../../store/store";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AndroidIcon from "@material-ui/icons/Android";
import GoogleStore from "../../images/play_store.png";
import AppStore from "../../images/App_Store.png";
import Mobiles2 from "../../images/Mobiles2.png";
import WebApp from "../../images/WebApp.png";
import AppleIcon from "@material-ui/icons/Apple";
import logo from "../../images/MIE_Dark_Black.png";
import newlogo from "../../images/new-logo.gif";
import Colors from "../../Config/Colors";
import TextTranslation from "../../Config/TextTranslation";
import facebook from "../../images/facebook.png";
import twitter from '../../images/twitter.png';

import linkedin from '../../images/linkedin.png';
import tiktok from '../../images/tiktok.png';
import youtube from '../../images/youtube.png';
// import "../../Utils/MainOverlay/MainOverlay.css";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "white",
    },
    fontRaleway: {
        fontsfontFamily: 'Raleway, sans-serif'
    },
    logo: {
        display: "flex",
        marginBottom: "3%",
        paddingTop: "5%",
        [theme.breakpoints.down("lg")]: {
            marginTop: "5%",
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "10%",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "20%",
        },
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: Colors.primary,
        fontFamily: "Raleway, sans-serif",
        color: Colors.black,
        "&:hover": {
            backgroundColor: Colors.black,
        },
    },
}));

const Launch = () => {
    const [cls, setCls] = useState(0);
    const [cls1, setCls1] = useState(0);
    const [saplashcls, setSaplashCls] = useState('d-none');
    const hidediv = (cls,saplashcls,cls1) => {
        setCls(cls)
        setSaplashCls(saplashcls)
        setTimeout(() => {
            setCls1(cls1)
            setSaplashCls('d-none')
        }, 2900);
    }

    const store = useContext(StoreContext);
    const classes = useStyles();
    console.log(window.navigator.userAgent)
    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {

            // <Button  target="_blank" rel="noreferrer noopener">Download</Button>
        }

        if (/android/i.test(userAgent)) {
            window.location.href = "https://play.google.com/store/apps/details?id=minhaj.islamic.encyclopedia"

        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.href = "https://apps.apple.com/pk/app/minhaj-islamic-encyclopedia/id1609248178"

        }

        return "unknown";
    }
    // const RedirectPg=()=>{
    //   window.location.host==='download.minhaj.app' ? getMobileOperatingSystem():''
    // }

    // console.log( (getMobileOperatingSystem()));
    useEffect(() => {
        if (window.location.host === 'download.minhaj.app') {
            getMobileOperatingSystem()
        }

        console.log(window.location.host)
    }, [])
    return (
        <>
            <div className={`${saplashcls}`} style={{ position:'absolute',top:'50%',left:'50%',transform: 'translate(-50%, -50%)',backgroundColor:'#FCFCFF'}}>
                <img src={newlogo} />
            </div>
            <div className={`${cls === 1 ? 'd-none' : 'd-block'}`}>
                <div>
                    <div className="col-12 p-0 d-flex bg-color-mobile h-max m-auto bg-gradiant" style={{ height: '100vh', width: '100vw' }}>
                        <div className="col-md-12 col-lg-8 col-sm-12 m-auto pl-lg-5 p-3 text-center bg-color-mobile ">
                            <div className="col-12 col-lg-12 p-4 " >
                                <img src={logo}  className="main_logo img-fluid img-responsive  " />
                            </div>
                            <div className={(classes.logo, "col-12 col-lg-8 p-0 m-auto pt-3 pb-3 mt-1")}
                                style={
                                    store.langIndex == 0
                                        ? { fontFamily: "Raleway, sans-serif" }
                                        : store.langIndex == 1
                                            ? {
                                                textAlign: "right",
                                                fontFamily: "JameelNoori",
                                                fontSize: 16,

                                            }
                                            : {

                                                textAlign: "right",
                                                fontFamily: "Arabic",
                                                fontSize: 16,

                                            }} >

                                <h1 className="pl-2 fontRaleway text-break display-4 text-lg-left text-center" style={
                                    store.langIndex == 0
                                        ? { fontFamily: "Raleway, sans-serif" }
                                        : store.langIndex == 1
                                            ? {

                                                textAlign: "right",
                                                fontFamily: "JameelNoori",
                                                fontSize: 14,

                                            }
                                            : {

                                                textAlign: "right",
                                                fontFamily: "Arabic",
                                                fontSize: 16,

                                            }}>{TextTranslation.minhaj_islamic_encyclopedia[store.langIndex]}</h1>

                                <button style={{ fontFamily: 'Raleway, sans-serif' }} className="mt-2  btn p-3 btn-radius bg-danger text-light fw-bold" onClick={() => hidediv(1,'d-block',1)} type="submit" variant="contained">Launch Now!</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`fadeIn ${cls1 === 1 ? 'd-block' : 'd-none'}`} >
                <div>
                    <h1 class="playful h1-style" aria-label="Wash your hands">
                        <div className="col-12 col-lg-12 p-4 " >
                            <img src={logo} className="main_logo img-fluid img-responsive  " />
                        </div>
                        <h4 style={{ fontFamily: 'Raleway, sans-serif' }}>Many Congratulations..!</h4>
                        <h4 style={{ fontFamily: 'Raleway, sans-serif' }}>Minhaj Islamic Encyclopedia is Live.</h4>
                        <span style={{ fontFamily: 'Raleway, sans-serif' }} aria-hidden="true">T</span>
                        <span style={{ fontFamily: 'Raleway, sans-serif' }} aria-hidden="true">h</span>
                        <span style={{ fontFamily: 'Raleway, sans-serif' }} aria-hidden="true">a</span>
                        <span style={{ fontFamily: 'Raleway, sans-serif' }} aria-hidden="true">n</span>
                        <span style={{ fontFamily: 'Raleway, sans-serif' }} aria-hidden="true">k</span>
                        <span style={{ fontFamily: 'Raleway, sans-serif' }} aria-hidden="true"> </span>
                        <span style={{ fontFamily: 'Raleway, sans-serif' }} aria-hidden="true">y</span>
                        <span style={{ fontFamily: 'Raleway, sans-serif' }} aria-hidden="true">o</span>
                        <span style={{ fontFamily: 'Raleway, sans-serif' }} aria-hidden="true">u</span>
                        <span style={{ fontFamily: 'Raleway, sans-serif' }} aria-hidden="true">!</span>
                        <div className="col-lg-12 col-md-12 col-12 mb-5 d-flex justify-content-center">
                            <div className="col-lg-8 col-8 col-md-8 d-flex justify-content-center m-auto m-lg-0">
                                <div className="col-lg-4 col-6 col-md-6 p-lg-1 p-md-1 p-1">
                                    <a target="_blank" rel="noreferrer noopener" href="https://play.google.com/store/apps/details?id=minhaj.islamic.encyclopedia" >
                                        <img src={GoogleStore} alt="google" className="img-responsive img-fluid" />
                                    </a>
                                </div>
                                <div className="col-lg-4 col-6 col-md-6 p-lg-1 p-md-1 p-1">
                                    <a target="_blank" rel="noreferrer noopener" href="https://apps.apple.com/pk/app/minhaj-islamic-encyclopedia/id1609248178" >
                                        <img src={AppStore} alt="google" className="img-responsive img-fluid" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </h1>
                </div>

            </div>
        </>
    );
};
export default Launch;
