import React, { useContext } from "react";
import { Card } from "@material-ui/core";
import { Observer } from "mobx-react-lite";
import { StoreContext } from "../store/store";
import Colors from "../Config/Colors";
import "./custom.css";

const TopicCard = ({ children, styles, onClick, bookName }) => {
  const store = useContext(StoreContext);
  return (
    <Observer>
      {() => (
        <Card
          className="customCard mt-2 pt-2 pb-2 px-2"
          raised={true}
          style={{ ...styles }}
          onClick={onClick}
        >
          {children}
          <div
            className="mx-2"
            style={{
              display: "flex",
              color: Colors.primary,
              justifyContent: store.langIndex == 0 ? "flex-end" : "flex-start",
              fontFamily:
                store.langIndex == 0
                  ? "Raleway, sans-serif"
                  : store.langIndex == 1
                  ? "JameelNoori"
                  : "Arabic",
              fontSize:
                store.langIndex == 0 ? 13 : store.langIndex == 1 ? 14 : 14,
            }}
          >
            {bookName}
          </div>
        </Card>
      )}
    </Observer>
  );
};

export default TopicCard;
