import React, { useContext, useState } from "react";
import {
  Container,
  CircularProgress,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { Observer } from "mobx-react-lite";
import { Formik } from "formik";
import TextTranslation from "../../Config/TextTranslation";
import Colors from "../../Config/Colors";
import * as yup from "yup";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// code verfication component that will call in register component

const CodeVerification = ({ setEmailValidStateFromChild, email }) => {
  const store = useContext(StoreContext);
  const classes = useStyles(); // styles classes
  const [loader, setLoader] = useState(false); //loader state

  return (
    <Observer>
      {() => (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Formik
              fullWidth
              initialValues={{
                code: "",
              }}
              onSubmit={(values) => {
                setLoader(true);
                var data = new FormData();
                data.append("email", email);
                data.append("code", values.code);

                var config = {
                  method: "post",
                  url: "https://api.minhaj.app/verify_code.php",
                  headers: { "Content-Type": "multipart/form-data" },
                  data: data,
                };
                axios(config)
                  .then(function (response) {
                    let code = JSON.stringify(response.data.status);
                    // if response == 200 then it will go to ful register form
                    if (code == 200) {
                      setEmailValidStateFromChild(2);
                      setLoader(false);
                    } else if (code == 201) {
                      alert(JSON.stringify(response.data.message));
                      setLoader(false);
                    } else if (code == 404) {
                      alert(JSON.stringify(response.data.message));
                      setLoader(false);
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              }}
              // code verification validation schema
              validationSchema={yup.object().shape({
                code: yup
                  .string()
                  .required(TextTranslation.required_code_msg[store.langIndex])
                  .min(6, "Must be 6 digit Code")
                  .max(6, "Must be 6 digit Code"),
              })}
            >
              {({
                values,
                handleChange,
                errors,
                touched,
                isValid,
                handleSubmit,
                handleBlur,
              }) => (
                <>
                  {store.langIndex == 0 ? (
                    <TextField
                      fullWidth
                      error={errors.code && touched.code}
                      variant="outlined"
                      onChange={handleChange}
                      value={values.code}
                      onBlur={handleBlur}
                      className="mb-2"
                      label={TextTranslation.inp_plac_code[store.langIndex]}
                      name="code"
                      helperText={
                        errors.code && touched.code ? errors.code : null
                      }
                    />
                  ) : (
                    <TextField
                      fullWidth
                      error={errors.code && touched.code}
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                              },
                            }
                      }
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.code}
                      className="mb-2"
                      placeholder={
                        TextTranslation.inp_plac_code[store.langIndex]
                      }
                      name="code"
                      helperText={
                        errors.code && touched.code ? errors.code : null
                      }
                    />
                  )}
                  {/* submit button */}
                  <Button
                    className="mt-3 mb-3 btn"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                    }}
                    disabled={!isValid}
                    onClick={handleSubmit}
                  >
                    {loader && isValid ? (
                      <CircularProgress
                        size={30}
                        style={{ color: Colors.white }}
                      />
                    ) : (
                      <span
                        style={
                          store.langIndex == 0
                            ? { fontFamily: "Raleway, sans-serif" }
                            : store.langIndex == 1
                            ? { fontFamily: "JameelNoori", fontSize: 18 }
                            : { fontFamily: "Arabic", fontSize: 18 }
                        }
                      >
                        {TextTranslation.sub[store.langIndex]}
                      </span>
                    )}
                  </Button>
                </>
              )}
            </Formik>
          </div>
        </Container>
      )}
    </Observer>
  );
};

export default CodeVerification;
