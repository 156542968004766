import React, { useContext } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { Observer } from "mobx-react-lite";
import { useHistory, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Colors from "../../Config/Colors";
import MainNavbar from "../../Navbars/MainNavbar/MainNavbar";
import logo from "../../images/MIE_Dark_Black.png";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: "Raleway, sans-serif",

  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  logo: {
    height: "40px",

    cursor: "pointer",
    backgroundSize: "contain",
    [theme.breakpoints.down("md")]: {
      height: "40px",

    },
  },
}));

const CommonNavbar = () => {
  const currentLocation = useLocation();
  const classes = useStyles();
  let history = useHistory();
  const store = useContext(StoreContext);
  return (
    <Observer>
      {() => (
        <div
        >
          <AppBar
            position="unset"
            style={{
              backgroundColor: Colors.grey,
              boxShadow: "0px 0px 0px white",
              color: Colors.theme_c_dbw[store.theme],
              backgroundColor: Colors.grey,
            }}

            className={((currentLocation.pathname == "/result") || (currentLocation.pathname == "/selectedResult") || (currentLocation.pathname == "/MainOverlay") ? 'navbar-static-top p-2' : 'p-0 navbar-static-top')}
          >
            <Toolbar className={((currentLocation.pathname == "/result") || (currentLocation.pathname == "/selectedResult") || (currentLocation.pathname == "/MainOverlay") ? 'p-3' : 'p-0')} >
              <Typography
                className="col-1"
                style={{
                  color: Colors.primary,
                  display: 'contents'
                }}
                noWrap
              >
                {currentLocation.pathname == "/" ? (

                  <>
                    <div className="px-2"></div>
                  </>
                ) : (
                  <>
                    <div className="col-1 d-md-block d-none">
                      <img
                        className="w-90 p-1"
                        src={logo}
                        onClick={() => history.push("/")}
                      />
                    </div>
                  </>
                )
                }
              </Typography>
              <MainNavbar />
            </Toolbar>
          </AppBar>
        </div>
      )}
    </Observer>
  );
};

export default CommonNavbar;
