import { Button, Grid, Container, TextareaAutosize, styled } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../../store/store";
import { Observer } from "mobx-react-lite";
import { Form, Formik } from "formik";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import TextTranslation from "../../Config/TextTranslation";
import TextField from "@material-ui/core/TextField";
import logo from "../../images/MIE_Dark_Black.png";
import Colors from "../../Config/Colors";
import * as yup from "yup";
import axios from "axios";
import Url from "../../Config/Url";
import facebook from "../../images/facebook.png";
import twitter from '../../images/twitter.png';
import instagram from '../../images/instagram.png';

import linkedin from '../../images/linkedin.png';
import tiktok from '../../images/tiktok.png';
import whatsapp from '../../images/whatsapp.png';
import handshake from '../../images/handshake.png';
import youtube from '../../images/youtube.png';
import { useHistory } from "react-router-dom";

const ContactUs = () => {
  const history = useHistory();
  const store = useContext(StoreContext);
  const [load, setLoad] = useState(false); // button loader state

  document.body.style.backgroundColor = Colors.theme_bg_wb[store.theme];
  document.body.style.color = Colors.theme_c_dbw[store.theme];
  // validatoin schema
  let SuggestionSchema = yup.object().shape({
    email: yup
      .string()
      .email(TextTranslation.valid_email_msg[store.langIndex])
      .required(TextTranslation.required_email_msg[store.langIndex]),

    subject: yup
      .string()
      .required(TextTranslation.inp_subject_req_msg[store.langIndex]),

    message: yup
      .string()
      .required(TextTranslation.inp_msg_req_msg[store.langIndex])
      .max(1000, TextTranslation.inp_msg_max_msg[store.langIndex]),
  });

  useEffect(() => {
    const ac = new AbortController();
    return () => ac.abort();
  }, []);

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'red',
      },

      '& .MuiInput-underline:after': {
        borderBottomColor: 'red',
      },
      '& .MuiOutlinedInput-input': {
        borderRadius: "7px",
        backgroundColor: Colors.grey,
        borderColor: Colors.inputBorder,
      },
    },
  })(TextField);

  const WhiteThemeTextareaAutosize = styled(TextareaAutosize)({

    color: "#202124",
    backgroundColor: Colors.grey,
    borderColor: Colors.inputBorder,
    borderRadius: '7px',

  })
  const DarkThemeTextareaAutosize = styled(TextareaAutosize)({

    color: 'white',
    border: '1px solid #303134',
    borderRadius: '3px',
    backgroundColor: '#303134',

  })
  const DarkThemeTextField = styled(TextField)({

    '& .MuiFormLabel-root': {
      color: 'white',

    },
    '& .MuiOutlinedInput-notchedOutline': {
      backgroundColor: '#303134',
    },
    '&.Mui-focused': {
      color: 'red'
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#303134',
      backgroundColor: '#303134'

    },
  });


  const WhiteThemeTextField = styled(TextField)({

    '& .MuiFormLabel-root': {
      color: "#202124"
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    }

  });

  return (
    <>
      {/* <Observer>
      {() => (
        <> */}
      {/* <CommonNavbar /> */}
      {/* <div className="pt-2 pb-4 d-flex justify-content-center"  style={{color:Colors.theme_c_dbw[store.theme] }}>
            <img src={logo}  className="col-md-2 col-lg-2 col-6"/>
          </div> */}

      {/* <Grid
            container
            spacing={0}
            justify="center"
            alignItems="center"
            direction="column"

          ></Grid>
          <Container component="main" maxWidth="xs" className="pt-5  align-middle" style={{
            color: Colors.theme_c_dbw[store.theme],
            backgroundColor: Colors.theme_bg_wb[store.theme]
          }}>

            <h1 className="font-weight-bold text-center pt-4"
              style={
                store.langIndex == 0
                  ? { fontFamily: "Raleway, sans-serif" }
                  : store.langIndex == 1
                    ? { fontFamily: "JameelNoori" }
                    : { fontFamily: "Arabic" }
              }
            >
              {TextTranslation.suggestion_contact_us[store.langIndex]}
            </h1>
            <Typography className="text-center py-3"
              style={
                store.langIndex == 0
                  ? { fontFamily: "Raleway, sans-serif" }
                  : store.langIndex == 1
                    ? { fontFamily: "JameelNoori" }
                    : { fontFamily: "Arabic" }
              }>
              {TextTranslation.contact_us_text[store.langIndex]}

            </Typography>
            <Formik
              fullWidth

              //initialize values

              initialValues={{
                email: "",
                subject: "",
                message: "",
              }}
              validationSchema={SuggestionSchema}
              onSubmit={(values) => {

                // FormData append

                var suggestionbodyformData = new FormData();
                suggestionbodyformData.append("email", values.email);
                suggestionbodyformData.append("subject", values.subject);
                suggestionbodyformData.append("message", values.message);

                setLoad(true);

                axios({
                  method: "post",
                  url: `${Url.link}/mail.php?`,
                  data: suggestionbodyformData,
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                  .then((res) => {
                    console.log(res.data);
                    // message deliverd alert
                    alert(TextTranslation.delivered[store.langIndex]);
                    setLoad(false);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >

              {({ errors, handleChange, touched, handleBlur, isValid }) => (
                <Form className="pt-2 text-center">
                  {store.langIndex == 0 ? (
                    // store.theme==0 ? {} :  {}
                    store.theme == 0 ? (
                      <CssTextField
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        className="mb-3"
                        label={TextTranslation.inp_plac_email[store.langIndex]}
                        name="email"
                        error={errors.email && touched.email}
                        helperText={
                          errors.email && touched.email ? errors.email : null
                        }
                        style={{ color: Colors.white, }}

                      />) : (<DarkThemeTextField
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        className="mb-3"
                        label={TextTranslation.inp_plac_email[store.langIndex]}
                        name="email"
                        error={errors.email && touched.email}
                        helperText={
                          errors.email && touched.email ? errors.email : null
                        }
                        style={{ color: Colors.white, }}

                      />)
                  ) : (
                    store.theme == 0 ? (
                      <CssTextField

                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={
                          store.langIndex == 1
                            ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,

                              },
                            }
                            : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                        }
                        FormHelperTextProps={
                          store.langIndex == 1
                            ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                            : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                              },
                            }
                        }
                        variant="outlined"
                        className="mb-3"
                        placeholder={
                          TextTranslation.inp_plac_email[store.langIndex]
                        }
                        name="email"
                        error={errors.email && touched.email}
                        helperText={
                          errors.email && touched.email ? errors.email : null
                        }
                      />) : (
                      <DarkThemeTextField

                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={
                          store.langIndex == 1
                            ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                            : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                        }
                        FormHelperTextProps={
                          store.langIndex == 1
                            ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                            : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                              },
                            }
                        }
                        variant="outlined"
                        className="mb-3"
                        placeholder={
                          TextTranslation.inp_plac_email[store.langIndex]
                        }
                        name="email"
                        error={errors.email && touched.email}
                        helperText={
                          errors.email && touched.email ? errors.email : null
                        }
                      />
                    )
                  )}
                  {store.langIndex == 0 ? (
                    store.theme == 0 ? (
                      <CssTextField
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        name="subject"
                        label={TextTranslation.subject[store.langIndex]}
                        className="mb-3"
                        error={errors.subject && touched.subject}
                        helperText={
                          errors.subject && touched.subject
                            ? errors.subject
                            : null
                        }
                      />) : (
                      <DarkThemeTextField
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        name="subject"
                        label={TextTranslation.subject[store.langIndex]}
                        className="mb-3"
                        error={errors.subject && touched.subject}
                        helperText={
                          errors.subject && touched.subject
                            ? errors.subject
                            : null
                        }
                      />
                    )
                  ) : (
                    store.theme == 0 ? (
                      <CssTextField
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={
                          store.langIndex == 1
                            ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,

                              },
                            }
                            : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,

                              },
                            }
                        }
                        FormHelperTextProps={
                          store.langIndex == 1
                            ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                            : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              },
                            }
                        }
                        variant="outlined"
                        name="subject"
                        placeholder={TextTranslation.subject[store.langIndex]}
                        className="mb-2"
                        error={errors.subject && touched.subject}
                        helperText={
                          errors.subject && touched.subject
                            ? errors.subject
                            : null
                        }
                      />) : (
                      <DarkThemeTextField
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={
                          store.langIndex == 1
                            ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                            : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "Arabic",
                                fontSize: 18,
                              },
                            }
                        }
                        FormHelperTextProps={
                          store.langIndex == 1
                            ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                            : {
                              style: {
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize: 16,
                              },
                            }
                        }
                        variant="outlined"
                        name="subject"
                        placeholder={TextTranslation.subject[store.langIndex]}
                        className="mb-3"
                        error={errors.subject && touched.subject}
                        helperText={
                          errors.subject && touched.subject
                            ? errors.subject
                            : null
                        }
                      />
                    )
                  )}
                  {store.langIndex == 0 ? (
                    store.theme == 0 ? (
                      <WhiteThemeTextareaAutosize
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        name="message"
                        placeholder={
                          TextTranslation.inp_plac_message[store.langIndex]
                        }
                        rowsMin={4}
                        style={{ width: "100%" }}
                        className="mb-2 p-3"
                        error={errors.message && touched.message}
                      />) : (<DarkThemeTextareaAutosize
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        name="message"
                        placeholder={
                          TextTranslation.inp_plac_message[store.langIndex]
                        }
                        rowsMin={4}
                        style={{ width: "100%" }}
                        className="mb-2"
                        error={errors.message && touched.message}
                      />)
                  ) : (
                    store.theme == 0 ? (
                      <WhiteThemeTextareaAutosize
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        name="message"
                        rowsMin={4}
                        style={
                          store.langIndex == 1
                            ? {
                              width: "100%",
                              textAlign: "right",
                              fontFamily: "jameelNoori",
                              fontSize: 18,
                              color: Colors.primary,
                              backgroundColor: Colors.theme_bg_wg[store.theme]
                            }
                            : {
                              width: "100%",
                              textAlign: "right",
                              fontFamily: "Arabic",
                              fontSize: 18,
                              color: Colors.primary,
                            }
                        }
                        placeholder={
                          TextTranslation.inp_plac_message[store.langIndex]
                        }
                        className="mb-2"
                        error={errors.message && touched.message}
                      />) : (<DarkThemeTextareaAutosize
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        name="message"
                        rowsMin={4}
                        style={
                          store.langIndex == 1
                            ? {
                              width: "100%",
                              textAlign: "right",
                              fontFamily: "jameelNoori",
                              fontSize: 18,
                              color: Colors.primary,
                            }
                            : {
                              width: "100%",
                              textAlign: "right",
                              fontFamily: "Arabic",
                              fontSize: 18,
                              color: Colors.primary,
                            }
                        }
                        placeholder={
                          TextTranslation.inp_plac_message[store.langIndex]
                        }
                        className="mb-2"
                        error={errors.message && touched.message}
                      />)
                  )}
                  <Button
                    className="mt-2 col-8 btn p-3 btn-radius"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                    }}
                    disabled={!isValid}
                  >
                    {load ? (
                      <CircularProgress
                        size={30}
                        style={{ color: Colors.white }}
                      />
                    ) : (
                      <span
                        style={
                          store.langIndex == 0
                            ? { fontFamily: "Raleway, sans-serif" }
                            : store.langIndex == 1
                              ? { fontFamily: "jameelNoori", fontSize: 18 }
                              : { fontFamily: "Arabic", fontSize: 18 }
                        }
                      >
                        {TextTranslation.sub[store.langIndex]}
                      </span>
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
            <div className="text-center col-lg-12 col-md-12 col-12 my-5" >
              <div className="col-12 d-flex align-items-center  justify-content-center m-auto m-lg-0   ">
                <div style={{ marginLeft: '15px' }}>
                  <a target="_blank" rel="noreferrer noopener" href='https://wa.link/0cxply'>
                    <img style={{ width: '30px' }} src={whatsapp} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                  </a>
                </div>
                <div style={{ marginLeft: '15px' }}>
                  <a target="_blank" rel="noreferrer noopener" href='https://fb.com/MinhajApp'>
                    <img style={{ width: '30px' }} src={facebook} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                  </a>
                </div>
                <div style={{ marginLeft: '15px' }}>
                  <a target="_blank" rel="noreferrer noopener" href='https://twitter.com/MinhajApp'>
                    <img style={{ width: '30px' }} src={twitter} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                  </a>
                </div>
                <div style={{ marginLeft: '15px' }}>
                  <a target="_blank" rel="noreferrer noopener" href='https://www.youtube.com/@MinhajApp'>
                    <img style={{ width: '30px' }} src={youtube} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                  </a>
                </div>
                <div style={{ marginLeft: '15px' }}>
                  <a target="_blank" rel="noreferrer noopener" href='https://www.linkedin.com/company/MinhajApp'>
                    <img style={{ width: '30px' }} src={linkedin} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                  </a>
                </div>
                <div style={{ marginLeft: '15px' }}>
                  <a target="_blank" rel="noreferrer noopener" href=' https://www.tiktok.com/@minhajapp'>
                    <img style={{ width: '30px' }} src={tiktok} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                  </a>
                </div>


              </div>
            </div>
          </Container>
        </>
      )} */}
      {/* </Observer> */}
      <CommonNavbar />
      <div className="col-12 p-0 d-flex bg-color-mobile h-max bg-gradiant">
        <div className="d-flex justify-content-center align-items-center m-md-auto mt-md-5 m-lg-0 text-center">
          <div className="col-lg-6 col-10 col-md-10 col-10 col-sm-10 m-auto" style={{ padding: '0px 15px' }}>
            <h1 style={
              store.langIndex == 0
                ? { fontFamily: "Raleway, sans-serif" }
                : store.langIndex == 1
                  ? { fontFamily: "JameelNoori" }
                  : { fontFamily: "Arabic" }
            }>Join Team of Contributors</h1>
            <p style={
              store.langIndex == 0
                ? { fontFamily: "Raleway, sans-serif" }
                : store.langIndex == 1
                  ? { fontFamily: "JameelNoori" }
                  : { fontFamily: "Arabic" }
            }>Join our dynamic team of contributors where diversity, creativity, and excellence are celebrated.</p>
            {/* <Button
              onClick={() => {
                history.push('/join');
              }}
              className="mt-2 col-8 btn p-3 btn-radius"
              variant="contained"
              fullWidth
              style={
                store.langIndex == 0
                  ? {
                    fontFamily: "Raleway, sans-serif", backgroundColor: Colors.primary,
                    color: Colors.white,
                  }
                  : store.langIndex == 1
                    ? {
                      fontFamily: "JameelNoori", backgroundColor: Colors.primary,
                      color: Colors.white,
                    }
                    : {
                      fontFamily: "Arabic", backgroundColor: Colors.primary,
                      color: Colors.white,
                    }
              }
            >Join Now</Button> */}

            <Button
              className="mt-2 col-8 btn p-3 btn-radius"
              type="submit"
              fullWidth
              onClick={() => {
                history.push('/join');
              }}
              variant="contained"
              style={{
                backgroundColor: Colors.primary,
                color: Colors.white,
              }}
            >
              {load ? (
                <CircularProgress
                  size={30}
                  style={{ color: Colors.white }}
                />
              ) : (
                <span
                  style={
                    store.langIndex == 0
                      ? { fontFamily: "Raleway, sans-serif" }
                      : store.langIndex == 1
                        ? { fontFamily: "jameelNoori", fontSize: 18 }
                        : { fontFamily: "Arabic", fontSize: 18 }
                  }
                >
                  <img style={{ width: '30px' }} src={handshake} className="img-fluid img-responsive mt-0 pt-0 w-5 mx-2" />
                  Join Team of Contributors
                </span>
              )}
            </Button>
            <a target="_blank" className=" text-decoration-none" rel="noreferrer noopener" href='https://wa.link/0cxply'>

              <Button target="_blank"
                className="mt-2 col-8 btn p-3 btn-radius d-flex justify-content-center align-items-center "
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  backgroundColor: '#25D366',
                  color: Colors.white,
                }}
              >
                {load ? (
                  <CircularProgress
                    size={30}
                    style={{ color: Colors.white }}
                  />
                ) : (
                  <span
                    style={
                      store.langIndex == 0
                        ? { fontFamily: "Raleway, sans-serif" }
                        : store.langIndex == 1
                          ? { fontFamily: "jameelNoori", fontSize: 18 }
                          : { fontFamily: "Arabic", fontSize: 18 }
                    }
                  >
                    <img style={{ width: '30px' }} src={whatsapp} className="img-fluid img-responsive mt-0 pt-0 w-5 mx-2" />
                    Support/Feedback
                  </span>
                )}
              </Button>
            </a>

            {/* <div style={{ marginLeft: '15px' }} className="my-2">
              <a target="_blank" rel="noreferrer noopener" href='https://wa.link/0cxply'>
                <img style={{ width: '120px' }} src={whatsapp} className="img-fluid img-responsive mt-0 pt-0 w-5" />
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="overlap p-0 m-0">
        <div className="col-10 col-lg-8 col-md-10 col-sm-10 m-auto d-flex justify-content-center">
          <Observer>
            {() => (
              <>
                <Container component="main" className="pt-5 align-middle" style={{
                  color: Colors.theme_c_dbw[store.theme],
                  // backgroundColor: Colors.theme_bg_wb[store.theme]
                }}>

                  <h1 className="font-weight-bold text-center pt-4"
                    style={
                      store.langIndex == 0
                        ? { fontFamily: "Raleway, sans-serif" }
                        : store.langIndex == 1
                          ? { fontFamily: "JameelNoori" }
                          : { fontFamily: "Arabic" }
                    }
                  >
                    {TextTranslation.suggestion_contact_us[store.langIndex]}
                  </h1>
                  <Typography className="text-center py-3"
                    style={
                      store.langIndex == 0
                        ? { fontFamily: "Raleway, sans-serif" }
                        : store.langIndex == 1
                          ? { fontFamily: "JameelNoori" }
                          : { fontFamily: "Arabic" }
                    }>
                    {TextTranslation.contact_us_text[store.langIndex]}

                  </Typography>
                  <Formik
                    fullWidth

                    //initialize values

                    initialValues={{
                      email: "",
                      subject: "",
                      message: "",
                    }}
                    validationSchema={SuggestionSchema}
                    onSubmit={(values) => {

                      // FormData append

                      var suggestionbodyformData = new FormData();
                      suggestionbodyformData.append("email", values.email);
                      suggestionbodyformData.append("subject", values.subject);
                      suggestionbodyformData.append("message", values.message);

                      setLoad(true);

                      axios({
                        method: "post",
                        url: `${Url.link}/mail.php?`,
                        data: suggestionbodyformData,
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      })
                        .then((res) => {
                          console.log(res.data);
                          // message deliverd alert
                          alert(TextTranslation.delivered[store.langIndex]);
                          setLoad(false);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  >

                    {({ errors, handleChange, touched, handleBlur, isValid }) => (
                      <Form className="pt-2 text-center">
                        {store.langIndex == 0 ? (
                          // store.theme==0 ? {} :  {}
                          store.theme == 0 ? (
                            <CssTextField
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              className="mb-3"
                              label={TextTranslation.inp_plac_email[store.langIndex]}
                              name="email"
                              error={errors.email && touched.email}
                              helperText={
                                errors.email && touched.email ? errors.email : null
                              }
                              style={{ color: Colors.white, }}

                            />) : (<DarkThemeTextField
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              className="mb-3"
                              label={TextTranslation.inp_plac_email[store.langIndex]}
                              name="email"
                              error={errors.email && touched.email}
                              helperText={
                                errors.email && touched.email ? errors.email : null
                              }
                              style={{ color: Colors.white, }}

                            />)
                        ) : (
                          store.theme == 0 ? (
                            <CssTextField

                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputProps={
                                store.langIndex == 1
                                  ? {
                                    min: 0,
                                    style: {
                                      textAlign: "right",
                                      color: Colors.primary,
                                      fontFamily: "JameelNoori",
                                      fontSize: 18,

                                    },
                                  }
                                  : {
                                    min: 0,
                                    style: {
                                      textAlign: "right",
                                      color: Colors.primary,
                                      fontFamily: "Arabic",
                                      fontSize: 18,
                                    },
                                  }
                              }
                              FormHelperTextProps={
                                store.langIndex == 1
                                  ? {
                                    style: {
                                      textAlign: "right",
                                      fontFamily: "JameelNoori",
                                      fontSize: 16,
                                    },
                                  }
                                  : {
                                    style: {
                                      textAlign: "right",
                                      fontFamily: "Arabic",
                                    },
                                  }
                              }
                              variant="outlined"
                              className="mb-3"
                              placeholder={
                                TextTranslation.inp_plac_email[store.langIndex]
                              }
                              name="email"
                              error={errors.email && touched.email}
                              helperText={
                                errors.email && touched.email ? errors.email : null
                              }
                            />) : (
                            <DarkThemeTextField

                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputProps={
                                store.langIndex == 1
                                  ? {
                                    min: 0,
                                    style: {
                                      textAlign: "right",
                                      color: Colors.primary,
                                      fontFamily: "JameelNoori",
                                      fontSize: 18,
                                    },
                                  }
                                  : {
                                    min: 0,
                                    style: {
                                      textAlign: "right",
                                      color: Colors.primary,
                                      fontFamily: "Arabic",
                                      fontSize: 18,
                                    },
                                  }
                              }
                              FormHelperTextProps={
                                store.langIndex == 1
                                  ? {
                                    style: {
                                      textAlign: "right",
                                      fontFamily: "JameelNoori",
                                      fontSize: 16,
                                    },
                                  }
                                  : {
                                    style: {
                                      textAlign: "right",
                                      fontFamily: "Arabic",
                                    },
                                  }
                              }
                              variant="outlined"
                              className="mb-3"
                              placeholder={
                                TextTranslation.inp_plac_email[store.langIndex]
                              }
                              name="email"
                              error={errors.email && touched.email}
                              helperText={
                                errors.email && touched.email ? errors.email : null
                              }
                            />
                          )
                        )}
                        {store.langIndex == 0 ? (
                          store.theme == 0 ? (
                            <CssTextField
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              name="subject"
                              label={TextTranslation.subject[store.langIndex]}
                              className="mb-3"
                              error={errors.subject && touched.subject}
                              helperText={
                                errors.subject && touched.subject
                                  ? errors.subject
                                  : null
                              }
                            />) : (
                            <DarkThemeTextField
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              name="subject"
                              label={TextTranslation.subject[store.langIndex]}
                              className="mb-3"
                              error={errors.subject && touched.subject}
                              helperText={
                                errors.subject && touched.subject
                                  ? errors.subject
                                  : null
                              }
                            />
                          )
                        ) : (
                          store.theme == 0 ? (
                            <CssTextField
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputProps={
                                store.langIndex == 1
                                  ? {
                                    min: 0,
                                    style: {
                                      textAlign: "right",
                                      color: Colors.primary,
                                      fontFamily: "JameelNoori",
                                      fontSize: 18,

                                    },
                                  }
                                  : {
                                    min: 0,
                                    style: {
                                      textAlign: "right",
                                      color: Colors.primary,
                                      fontFamily: "Arabic",
                                      fontSize: 18,

                                    },
                                  }
                              }
                              FormHelperTextProps={
                                store.langIndex == 1
                                  ? {
                                    style: {
                                      textAlign: "right",
                                      fontFamily: "JameelNoori",
                                      fontSize: 16,
                                    },
                                  }
                                  : {
                                    style: {
                                      textAlign: "right",
                                      fontFamily: "Arabic",
                                      fontSize: 16,
                                    },
                                  }
                              }
                              variant="outlined"
                              name="subject"
                              placeholder={TextTranslation.subject[store.langIndex]}
                              className="mb-2"
                              error={errors.subject && touched.subject}
                              helperText={
                                errors.subject && touched.subject
                                  ? errors.subject
                                  : null
                              }
                            />) : (
                            <DarkThemeTextField
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputProps={
                                store.langIndex == 1
                                  ? {
                                    min: 0,
                                    style: {
                                      textAlign: "right",
                                      color: Colors.primary,
                                      fontFamily: "JameelNoori",
                                      fontSize: 18,
                                    },
                                  }
                                  : {
                                    min: 0,
                                    style: {
                                      textAlign: "right",
                                      color: Colors.primary,
                                      fontFamily: "Arabic",
                                      fontSize: 18,
                                    },
                                  }
                              }
                              FormHelperTextProps={
                                store.langIndex == 1
                                  ? {
                                    style: {
                                      textAlign: "right",
                                      fontFamily: "JameelNoori",
                                      fontSize: 16,
                                    },
                                  }
                                  : {
                                    style: {
                                      textAlign: "right",
                                      fontFamily: "Arabic",
                                      fontSize: 16,
                                    },
                                  }
                              }
                              variant="outlined"
                              name="subject"
                              placeholder={TextTranslation.subject[store.langIndex]}
                              className="mb-3"
                              error={errors.subject && touched.subject}
                              helperText={
                                errors.subject && touched.subject
                                  ? errors.subject
                                  : null
                              }
                            />
                          )
                        )}
                        {store.langIndex == 0 ? (
                          store.theme == 0 ? (
                            <WhiteThemeTextareaAutosize
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              name="message"
                              placeholder={
                                TextTranslation.inp_plac_message[store.langIndex]
                              }
                              rowsMin={4}
                              style={{ width: "100%" }}
                              className="mb-2 p-3"
                              error={errors.message && touched.message}
                            />) : (<DarkThemeTextareaAutosize
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              name="message"
                              placeholder={
                                TextTranslation.inp_plac_message[store.langIndex]
                              }
                              rowsMin={4}
                              style={{ width: "100%" }}
                              className="mb-2"
                              error={errors.message && touched.message}
                            />)
                        ) : (
                          store.theme == 0 ? (
                            <WhiteThemeTextareaAutosize
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              name="message"
                              rowsMin={4}
                              style={
                                store.langIndex == 1
                                  ? {
                                    width: "100%",
                                    textAlign: "right",
                                    fontFamily: "jameelNoori",
                                    fontSize: 18,
                                    color: Colors.primary,
                                    backgroundColor: Colors.theme_bg_wg[store.theme]
                                  }
                                  : {
                                    width: "100%",
                                    textAlign: "right",
                                    fontFamily: "Arabic",
                                    fontSize: 18,
                                    color: Colors.primary,
                                  }
                              }
                              placeholder={
                                TextTranslation.inp_plac_message[store.langIndex]
                              }
                              className="mb-2"
                              error={errors.message && touched.message}
                            />) : (<DarkThemeTextareaAutosize
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              name="message"
                              rowsMin={4}
                              style={
                                store.langIndex == 1
                                  ? {
                                    width: "100%",
                                    textAlign: "right",
                                    fontFamily: "jameelNoori",
                                    fontSize: 18,
                                    color: Colors.primary,
                                  }
                                  : {
                                    width: "100%",
                                    textAlign: "right",
                                    fontFamily: "Arabic",
                                    fontSize: 18,
                                    color: Colors.primary,
                                  }
                              }
                              placeholder={
                                TextTranslation.inp_plac_message[store.langIndex]
                              }
                              className="mb-2"
                              error={errors.message && touched.message}
                            />)
                        )}
                        <Button
                          className="mt-2 col-8 btn p-3 btn-radius"
                          type="submit"
                          fullWidth
                          variant="contained"
                          style={{
                            backgroundColor: Colors.primary,
                            color: Colors.white,
                          }}
                          disabled={!isValid}
                        >
                          {load ? (
                            <CircularProgress
                              size={30}
                              style={{ color: Colors.white }}
                            />
                          ) : (
                            <span
                              style={
                                store.langIndex == 0
                                  ? { fontFamily: "Raleway, sans-serif" }
                                  : store.langIndex == 1
                                    ? { fontFamily: "jameelNoori", fontSize: 18 }
                                    : { fontFamily: "Arabic", fontSize: 18 }
                              }
                            >
                              {TextTranslation.sub[store.langIndex]}
                            </span>
                          )}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                  <div className="text-center col-lg-12 col-md-12 col-12 my-5" >
                    <div className="col-12 d-flex align-items-center  justify-content-center m-auto m-lg-0   ">
                      <div style={{ marginLeft: '15px' }}>
                        <a target="_blank" rel="noreferrer noopener" href='https://www.instagram.com/minhaj.app/'>
                          <img style={{ width: '30px' }} src={instagram} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                        </a>
                      </div>
                      <div style={{ marginLeft: '15px' }}>
                        <a target="_blank" rel="noreferrer noopener" href='https://fb.com/MinhajApp'>
                          <img style={{ width: '30px' }} src={facebook} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                        </a>
                      </div>
                      <div style={{ marginLeft: '15px' }}>
                        <a target="_blank" rel="noreferrer noopener" href='https://twitter.com/MinhajApp'>
                          <img style={{ width: '30px' }} src={twitter} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                        </a>
                      </div>
                      <div style={{ marginLeft: '15px' }}>
                        <a target="_blank" rel="noreferrer noopener" href='https://www.youtube.com/@MinhajApp'>
                          <img style={{ width: '30px' }} src={youtube} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                        </a>
                      </div>
                      <div style={{ marginLeft: '15px' }}>
                        <a target="_blank" rel="noreferrer noopener" href='https://www.linkedin.com/company/MinhajApp'>
                          <img style={{ width: '30px' }} src={linkedin} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                        </a>
                      </div>
                      <div style={{ marginLeft: '15px' }}>
                        <a target="_blank" rel="noreferrer noopener" href=' https://www.tiktok.com/@minhajapp'>
                          <img style={{ width: '30px' }} src={tiktok} className="img-fluid img-responsive mt-0 pt-0 w-5" />
                        </a>
                      </div>


                    </div>
                  </div>
                </Container>
              </>
            )}
          </Observer>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
