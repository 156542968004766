import React, { useContext, useEffect, useState } from "react";
import { Grid, Card, Snackbar, CircularProgress } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { StoreContext } from "../../../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import { useParams,useHistory } from "react-router-dom";
import { Observer } from "mobx-react-lite";
import { decode } from "html-entities";
import SearchNavbar from "../../../../Navbars/SearchNavbar/SearchNavbar";
import QuranicText from "../../../../CustomComponents/QuranicText";
import TextTranslation from "../../../../Config/TextTranslation";
import UrduText from "../../../../CustomComponents/UrduText";
import Heading from "../../../../CustomComponents/Heading";
import EngText from "../../../../CustomComponents/EngText";
import Divider from "../../../../Utils/Divider/Divider";
import Colors from "../../../../Config/Colors";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import "./TopicResult.css";
import { set } from "lodash";
import Url from "../../../../Config/Url";



// alert function
const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

// styles
const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

var reference = [];
let topic_id = "";

const TopicResult = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const classes = useStyles();
  let ParamFromQuran = useParams(); // params from quran
  let lang = ParamFromQuran.langIndex;
  let _id = ParamFromQuran.selectedSearch; // params from quran
  const store = useContext(StoreContext);
  
  const [data1, setData1] = useState([]);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [copied, setCopied] = useState(false);
  const [MultiRefArray, setMultiRefArray] = useState([]);
  // const [translation, store.setLangIndex] = useState(
  //   store.langIndex == 0 ? 0 : store.langIndex == 1 ? 1 : 2
  // );

  let urls =
    "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_stemtopic_v6/_search";

  // copy to clipboard function
  const CopyData = (
    topic_name,
    chapter,
    section,
    arabic,
    translations,
    surah_no,
    surah_name,
    ayat_no
  ) => {
    return (
      "" +
      topic_name +
      "\n " +
      "\n" +
      arabic +
      "\n" +
      "\n" +
      translations +
      "" +
      "\n" +
      "\n" +
      `(${surah_no},${surah_name}:${ayat_no})` +
      "\n" +
      "\n" +
      TextTranslation.chapter_no[store.langIndex] +
      " " +
      chapter +
      " " +
      "\n" +
      "\n" +
      TextTranslation.section[store.langIndex] +
      " " +
      section +
      "\n" +
      "\n" +
      `${Url.link}/selectedresult/Quran/` +
      _id +
      "/" +
      store.langIndex +
      ""
    );
  };

  // alert close function
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // get data API
  const getData = async () => {
    await fetch(urls, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
      },
      body: JSON.stringify({
        from: 0,
        size: 1,
        query: {
          multi_match: {
            query: _id,
            fields: ["_id"],
          },
        },
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        console.log("data", data);
        setData1(data.hits.hits[0]);
        topic_id = data.hits.hits[0]._id;
        console.log("reference", data.hits.hits[0]);
        getBookmarkArray();
        reference = [];
        // english , urdu, arabic, ayat no, surah no, topic no push in reference array
        for (
          let i = 0;
          i < data.hits.hits[0]._source.reference_english.length;
          i++
        ) {
          reference.push({
            reference_english: data.hits.hits[0]._source.reference_english[i],
            reference_arabic: data.hits.hits[0]._source.reference_arabic[i],
            reference_urdu: data.hits.hits[0]._source.reference_urdu[i],
            sura_english: data.hits.hits[0]._source.sura_english[i],
            sura_arabic: data.hits.hits[0]._source.sura_arabic[i],
            ayat_no: data.hits.hits[0]._source.ayat_no[i],
            sura_no: data.hits.hits[0]._source.sura_no[i],
            topicno: data.hits.hits[0]._id,
          });
        }
        // history.push(`/selectedresult/Quran/${data.hits.hits[0]._id}/${store.langIndex}`);
        
        setLoader(false);
    })
      .catch(async (err) => {
        console.log("Selected Search Result Error", err);
      });
  };

  // post bookmark API
  const postBookmarkData = (sura, ayat, ref_no) => {
    var postBookmarkFormData = new FormData();
    postBookmarkFormData.append("title", store.searchValue);
    postBookmarkFormData.append("topic_id", parseInt(topic_id));
    postBookmarkFormData.append("sura_no", parseInt(sura));
    postBookmarkFormData.append("ayat_no", parseInt(ayat));
    postBookmarkFormData.append("ref_no", ref_no);
    postBookmarkFormData.append("lang_id", parseInt(store.langIndex) + 1);
    store.setLinearLoader(true);

    axios({
      method: "post",
      url: `${Url.link}/store_bookmarks_multiref.php?`,
      data: postBookmarkFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    })
      .then((res) => {
        console.log("get post bookmarks api console", res.data);
        alert("Bookmarked");
        getData();
        store.setLinearLoader(false);
      })
      .catch((err) => console.log("Post Bookmark Error", err));
  };
  // open full section
 const history = useHistory();
 const history1 =(ch,cat)=>{

  history.push(`/tablecontent/${ch}/${cat}`)
  
  
  }

  // get array bookmark API
  const getBookmarkArray = () => {
    var BookmarkArrayData = new FormData();

    BookmarkArrayData.append("title", store.searchValue);
    BookmarkArrayData.append("topic_id", topic_id);
    BookmarkArrayData.append("lang_id", parseInt(store.langIndex) + 1);

    axios({
      method: "post",
      url: `${Url.link}/get_bookmarks_multiref_on_topic.php?`,
      data: BookmarkArrayData,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    }).then((res) => {
      if (res.data.MDE_API[0].success == "0") {
        setMultiRefArray([]);
      } else {
        console.log('res.data.MDE_API[0]-->',res.data.MDE_API[0]);
        setMultiRefArray(JSON.stringify(res.data.MDE_API[0].MSG.reference_no));
      }
    });
  };

  // delete bookmark API
  const handleDeleteBookmarkArray = (reference_no) => {
    var DeleteBookmarkFormData = new FormData();

    DeleteBookmarkFormData.append("title", store.searchValue);
    DeleteBookmarkFormData.append("topic_id", topic_id);
    DeleteBookmarkFormData.append("ref_no", reference_no);

    if (window.confirm("Are You Sure to Delete this Bookmark?")) {
      alert("Bookmark Deleted");
      store.setLinearLoader(true);
      axios({
        method: "post",
        url: `${Url.link}/delete_bookmarks_multiref.php`,
        data: DeleteBookmarkFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          uuid: localStorage.getItem("@uuid"),
        },
      }).then((res) => {
        console.log("delete bookmark", res.data);
        // history.push(`/selectedresult/Quran/${store.locParam}/${res.data}/${store.langIndex}`);
        getData();
        store.setLinearLoader(false);
      });
    } else {
      alert("Bookmark not Deleted");
    }
  };

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    window.scrollTo(0, 0);
    store.setLangIndex(ParamFromQuran.langIndex);
    getData();
    return () => ac.abort();
  }, [_id]);

  return (
    <Observer>
      {() => (
        <>
          <SearchNavbar />
          {!loader ? (
            <Grid container alignItems="center" justify="center">\
              <Grid item xs={11} sm={11} md={10} lg={10}>
                {/* snackbar alert */}
                <Snackbar
                  open={open}
                  autoHideDuration={1000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity="success">
                    copied
                  </Alert>
                </Snackbar>
                <Card
                  raised={true}
                  style={{
                    marginTop: "8%",
                    borderRadius: "10px",
                    padding: "20px",
                    fontFamily: "Raleway, sans-serif",
                  }}
                >
                  {/* title name */}
                  <div
                    className="text-center mb-3 pt-3"
                    style={{
                      color: Colors.primary,
                      fontSize: "20px",
                      paddingBottom: "10px",
                      maxWidth: "90%",
                      margin: "auto",
                    }}
                  >
                    {store.langIndex == 0 ? (
                      <Heading styles={{ fontFamily: "Raleway,sans-serif" }}>
                        {decode(data1._source.topic_english)}
                      </Heading>
                    ) : store.langIndex == 1 ? (
                      <Heading styles={{ fontFamily: "JameelNoori" }}>
                        {decode(data1._source.topic_urdu)}
                      </Heading>
                    ) : (
                      <Heading styles={{ fontFamily: "Arabic" }}>
                        {decode(data1._source.topic_arabic)}
                      </Heading>
                    )}
                  </div>
                  {/* language button */}
                  <div className="d-flex justify-content-center mb-4">
                    {store.langIndex == 0 ? (
                      <>
                        <div
                          className="mx-2"
                          onClick={() => {
                            store.setLangIndex(0);
                          }}
                          style={{
                            border: "1px solid #e32636",
                            borderRadius: "4px",
                            width: 80,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            cursor: "pointer",
                            backgroundColor: Colors.primary,
                            color: Colors.white,
                            fontFamily: "Raleway, sans-serif",
                            fontSize: 14,
                          }}
                        >
                          {TextTranslation.english[store.langIndex]}
                        </div>
                        <div
                          className="mx-2"
                          onClick={() => {
                            store.setLangIndex(1);
                          }}
                          style={{
                            border: "1px solid #e32636",
                            borderRadius: "4px",
                            width: 80,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            cursor: "pointer",
                            color: "#e32636",
                            fontFamily: "Raleway, sans-serif",
                            fontSize: 14,
                          }}
                        >
                          {TextTranslation.urdu[store.langIndex]}
                        </div>
                        <div
                          className="mx-2"
                          onClick={() => {
                            store.setLangIndex(2);
                          }}
                          style={{
                            border: "1px solid #e32636",
                            borderRadius: "4px",
                            width: 80,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            cursor: "pointer",
                            color: "#e32636",
                            fontFamily: "Raleway, sans-serif",
                            fontSize: 14,
                          }}
                        >
                          {TextTranslation.arabic[store.langIndex]}
                        </div>
                      </>
                    ) : store.langIndex == 1 ? (
                      <>
                        <div
                          className="mx-2"
                          onClick={() => {
                            store.setLangIndex(0);
                          }}
                          style={{
                            cursor: "pointer",
                            border: "1px solid #e32636",
                            borderRadius: "4px",
                            width: 80,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            color: "#e32636",
                            fontFamily: "JameelNoori",
                            fontSize: 14,
                          }}
                        >
                          {TextTranslation.english[store.langIndex]}
                        </div>
                        <div
                          className="mx-2"
                          onClick={() => {
                            store.setLangIndex(1);
                          }}
                          style={{
                            cursor: "pointer",
                            border: "1px solid #e32636",
                            borderRadius: "4px",
                            width: 80,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            backgroundColor: Colors.primary,
                            color: Colors.white,
                            fontFamily: "JameelNoori",
                            fontSize: 14,
                          }}
                        >
                          {TextTranslation.urdu[store.langIndex]}
                        </div>
                        <div
                          className="mx-2"
                          onClick={() => {
                            store.setLangIndex(2);
                          }}
                          style={{
                            cursor: "pointer",
                            border: "1px solid #e32636",
                            borderRadius: "4px",
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 80,
                            textAlign: "center",
                            color: "#e32636",
                            fontFamily: "JameelNoori",
                            fontSize: 14,
                          }}
                        >
                          {TextTranslation.arabic[store.langIndex]}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="mx-2"
                          onClick={() => {
                            store.setLangIndex(0);
                          }}
                          style={{
                            cursor: "pointer",
                            border: "1px solid #e32636",
                            borderRadius: "4px",
                            width: 80,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 14,
                            color: "#e32636",
                            fontFamily: "Arabic",
                          }}
                        >
                          {TextTranslation.english[store.langIndex]}
                        </div>
                        <div
                          className="mx-2"
                          onClick={() => {
                            store.setLangIndex(1);
                          }}
                          style={{
                            cursor: "pointer",
                            border: "1px solid #e32636",
                            borderRadius: "4px",
                            width: 80,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            color: "#e32636",
                            fontFamily: "Arabic",
                            fontSize: 14,
                          }}
                        >
                          {TextTranslation.urdu[store.langIndex]}
                        </div>
                        <div
                          className="mx-2"
                          onClick={() => {
                            store.setLangIndex(2);
                          }}
                          style={{
                            cursor: "pointer",
                            border: "1px solid #e32636",
                            borderRadius: "4px",
                            width: 80,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            backgroundColor: Colors.primary,
                            color: Colors.white,
                            fontFamily: "Arabic",
                            fontSize: 14,
                          }}
                        >
                          {TextTranslation.arabic[store.langIndex]}
                        </div>
                      </>
                    )}
                  </div>
                  {/* chapter  */}
                  <div
                    className="pb-2"
                    style={
                      store.langIndex == 0
                        ? { textAlign: "left" }
                        : { textAlign: "right" }
                    }
                  >
                    <p style={{ paddingLeft: "2%" }}>
                      <span
                        style={
                          store.langIndex == 0
                            ? {
                                fontFamily: "Raleway, sans-serif",
                                color: Colors.primary,
                                fontSize: 18,
                              }
                            : store.langIndex == 1
                            ? {
                                fontFamily: "JameelNoori",
                                color: Colors.primary,
                                fontSize: 22,
                              }
                            : {
                                fontFamily: "Arabic",
                                color: Colors.primary,
                                fontSize: 22,
                              }
                        }
                      >
                        {store.langIndex == 0
                          ? TextTranslation.chapter_no[0]
                          : store.langIndex == 1
                          ? TextTranslation.chapter_no[1]
                          : TextTranslation.chapter_no[2]}
                      </span>
                      &nbsp;
                      {store.langIndex == 0 ? (
                        <span
                          style={{
                            fontFamily: "Raleway, sans-serif",
                            fontSize: 14,
                          }}
                        >
                          &nbsp;{decode(data1._source.chapter_english)}
                        </span>
                      ) : store.langIndex == 1 ? (
                        <span
                          style={{
                            fontFamily: "JameelNoori",
                            fontSize: 22,
                            padding: "0 5px",
                          }}
                        >
                          &nbsp;{decode(data1._source.chapter_urdu)}
                        </span>
                      ) : (
                        <span style={{ fontFamily: "Arabic", fontSize: 18 }}>
                          &nbsp;{decode(data1._source.chapter_arabic)}
                        </span>
                      )}
                    </p>
                    {/* section */}
                    {data1._source.section_english.length > 1 ? (
                      <p style={{ paddingLeft: "2%" }}>
                        <span
                          style={
                            store.langIndex == 0
                              ? {
                                  fontFamily: "Raleway, sans-serif",
                                  color: Colors.primary,
                                  fontSize: 18,
                                }
                              : store.langIndex == 1
                              ? {
                                  fontFamily: "JameelNoori",
                                  color: Colors.primary,
                                  fontSize: 22,
                                }
                              : {
                                  fontFamily: "Arabic",
                                  color: Colors.primary,
                                  fontSize: 22,
                                }
                          }
                        >
                          {store.langIndex == 0
                            ? TextTranslation.section[0]
                            : store.langIndex == 1
                            ? TextTranslation.section[1]
                            : TextTranslation.section[2]}
                        </span>
                        &nbsp;
                        {store.langIndex == 0 ? (
                          <span
                            style={{
                              fontFamily: "Raleway, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            &nbsp;{decode(data1._source.section_english)}
                          </span>
                        ) : store.langIndex == 1 ? (
                          <span
                            style={{
                              fontFamily: "JameelNoori",
                              fontSize: 22,
                            }}
                          >
                            &nbsp;{decode(data1._source.section_urdu)}
                          </span>
                        ) : (
                          <span style={{ fontFamily: "Arabic", fontSize: 18 }}>
                            &nbsp;{decode(data1._source.section_arabic)}
                          </span>
                        )}
                      </p>
                    ) : null}
                  </div>

                  {/* quran verse and store.langIndex */}
                  {reference
                    .filter(
                      (e) =>
                        e.ayat_no != null &&
                        e.reference_english != null &&
                        e.reference_arabic != null &&
                        e.reference_urdu != null &&
                        e.sura_english != null &&
                        e.sura_no != null &&
                        e.topicno != null
                    )
                    .map((data, index) => (
                      <div key={index}>
                        <QuranicText ayat={data.ayat_no}>
                          {data.reference_arabic}
                        </QuranicText>

                        {store.langIndex == 0 ? (
                          <EngText styles={{ fontSize: 14 }}>
                            {data.reference_english}
                          </EngText>
                        ) : store.langIndex == 1 ? (
                          <UrduText styles={{ lineHeight: 2 }}>
                            {data.reference_urdu}
                          </UrduText>
                        ) : (
                          <EngText styles={{ fontSize: 14 }}>
                            {data.reference_english}
                          </EngText>
                        )}
                        {/* bookmark icon */}

                        <div className="bookmark-share-icons">
                          {MultiRefArray.includes(index) ? (
                            <i
                              id="bookmark-icon"
                              onClick={() => {
                                store.id == null
                                  ? alert("Please Login to delete bookmark")
                                  : handleDeleteBookmarkArray(index);
                              }}
                              className="fas fa-bookmark mx-2"
                              aria-hidden="true"
                              style={{ color: Colors.primary }}
                            />
                          ) : (
                            <span
                              onClick={() => {
                                store.id == null
                                  ? alert("Please Login to bookmark")
                                  : postBookmarkData(
                                      data.sura_no,
                                      data.ayat_no,
                                      index
                                    );
                              }}
                            >
                              <i
                                id="bookmark-icon"
                                className="far fa-bookmark mx-2"
                                aria-hidden="true"
                              />
                            </span>
                          )}

                          {/* copy to clipboard icon */}

                          {/* for english language */}
                          {store.langIndex == 0 ? (
                            <CopyToClipboard
                              text={CopyData(
                                data1._source.topic_english,
                                data1._source.chapter_english,
                                data1._source.section_english,
                                data.reference_arabic,
                                data.reference_english,
                                data.sura_english,
                                data.sura_no,
                                data.ayat_no
                              )}
                              onCopy={() => {
                                setCopied(true);
                                //alert("copied");
                                setOpen(true);
                              }}
                            >
                              <span>
                                <i
                                  id="share-icon"
                                  className="far fa-copy mx-3"
                                  aria-hidden="true"
                                />
                              </span>
                            </CopyToClipboard>
                          ) : // for urdu language

                          store.langIndex == 1 ? (
                            <CopyToClipboard
                              text={CopyData(
                                data1._source.topic_urdu,
                                data1._source.chapter_urdu,
                                data1._source.section_urdu,
                                data.reference_arabic,
                                data.reference_urdu,
                                data.sura_arabic,
                                data.sura_no,
                                data.ayat_no
                              )}
                              onCopy={() => {
                                setCopied(true);
                                //alert("copied");
                                setOpen(true);
                              }}
                            >
                              <span>
                                <i
                                  id="share-icon"
                                  className="far fa-copy mx-1"
                                  aria-hidden="true"
                                />
                              </span>
                            </CopyToClipboard>
                          ) : (
                            // for arabic language

                            <CopyToClipboard
                              text={CopyData(
                                data1._source.topic_arabic,
                                data1._source.chapter_arabic,
                                data1._source.section_arabic,
                                data.reference_arabic,
                                data.reference_english,
                                data.sura_arabic,
                                data.sura_no,
                                data.ayat_no
                              )}
                              onCopy={() => {
                                setCopied(true);
                                //alert("copied");
                                setOpen(true);
                              }}
                            >
                              <span>
                                <i
                                  id="share-icon"
                                  className="far fa-copy mx-3"
                                  aria-hidden="true"
                                />
                              </span>
                            </CopyToClipboard>
                          )}
                        </div>
                        <br />

                        {/* surah name, ayat no, surah no */}
                        {store.langIndex == 0 ? (
                          <div className="mt-1">
                            <Divider className="text-center mb-5">
                              <span
                                style={{
                                  fontFamily: "Raleway, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                ({data.sura_english}, {data.sura_no} :
                                {data.ayat_no})
                              </span>
                            </Divider>
                          </div>
                        ) : (
                          <div className="mt-1">
                            <Divider className="text-center mb-5">
                              <span
                                style={{
                                  fontFamily: "AlQalam",
                                  fontSize: 18,
                                }}
                              >
                                ({data.ayat_no} : {data.sura_no} ،
                                {data.sura_arabic})
                              </span>
                            </Divider>
                          </div>
                        )}
                      </div>
                    ))}
                </Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <button
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      border: "none",
                      borderRadius: "5px",
                      width: 130,
                      height: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={history1(data1._source.id_chapter,data1._source.id_section)}
                  >
                    {/* <a
                      style={
                        store.langIndex == 0
                          ? { fontFamily: "Raleway, sans-serif", fontSize: 14 }
                          : store.langIndex == 1
                          ? { fontFamily: "JameelNoori", fontSize: 16 }
                          : { fontFamily: "Arabic", fontSize: 16 }
                      }
                      id="more-btn"
                      href={`https://bftechlab.net/lab.minhaj.digital/en/view/bookview.php?view_chapter=${data1._source.id_chapter}&view_section=${data1._source.id_section}&lang=${store.langIndex}`}
                      target="_blank"
                    > */}
                    {TextTranslation.read_more[store.langIndex]}
                    {/* </a> */}
                  </button>
                </div>
              </Grid>
            </Grid>
          ) : (
            // loader
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              style={{ minHeight: "100vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Observer>
  );
}

export default TopicResult;
