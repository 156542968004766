 import React, { useContext,useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Observer } from "mobx-react-lite";
import { StoreContext } from "../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import Colors from "../../Config/Colors";
import TextTranslation from "../../Config/TextTranslation";


const useStyles = makeStyles((theme) => ({
  heading: {
    color: Colors.primary,
    fontFamily: "Raleway, sans-serif",
    textAlign: "center",
    marginTop: "2%",
  },
  para: {
    fontFamily: "Raleway, sans-serif",
    textAlign: "justify",
    fontSize: "14px",
  },
}));

const AboutQuranicEncyclopedia = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const store = useContext(StoreContext);
  const classes = useStyles(); // styles classes
  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
  }, []);
  return (
    <Observer>
      {() => (
        <>
          <CommonNavbar />

          <div style={{ clear:"both" }}> </div>
          <Grid
            container
            style={{
              justifyContent: "space-around",
              textAlign:'justify',
             
             
            
            }}
          >
            <Grid item xs={10} sm={10} md={10} lg={8} xl={7}>
              <h5 className={classes.heading} style={ 
                  store.langIndex == 0
                    ? {  fontFamily: "Raleway, sans-serif",textAlign:'justify',textAlign:'center' }
                    :store.langIndex == 1
                          ? {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize:18,
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize:18,
                             }}>
                
                {TextTranslation.about_encyclopedia_heading[store.langIndex]}
              </h5>
              <div className="pt-4">
                <p style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif",textAlign:'justify' }
                    :store.langIndex == 1
                          ? {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize:18,
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize:18,
                             }} className={classes.para}>
                  {TextTranslation.about_encyclopedia_text_1[store.langIndex]}
                  
                </p>
                <p style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif",textAlign:'justify' }
                    :store.langIndex == 1
                          ? {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize:18,
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize:18,
                             }} className={classes.para}>
                {TextTranslation.about_encyclopedia_text_2[store.langIndex]}
                </p>
                <p style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" ,textAlign:'justify'}
                    :store.langIndex == 1
                          ? {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize:18,
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize:18,
                             }} className={classes.para}>
                {TextTranslation.about_encyclopedia_text_3[store.langIndex]}
                </p>
                <p style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif",textAlign:'justify' }
                    :store.langIndex == 1
                          ? {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize:18,
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize:18,
                             }} className={classes.para}>
                {TextTranslation.about_encyclopedia_text_4[store.langIndex]}
                </p>
                <p style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif" ,textAlign:'justify'}
                    :store.langIndex == 1
                          ? {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize:18,
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize:18,
                             }} className={classes.para}>
                {TextTranslation.about_encyclopedia_text_5[store.langIndex]}
                </p>
                <p style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif",textAlign:'justify' }
                    :store.langIndex == 1
                          ? {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize:18,
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize:18,
                             }} className={classes.para}>
                {TextTranslation.about_encyclopedia_text_6[store.langIndex]}
                </p>
                <p style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif",textAlign:'justify' }
                    :store.langIndex == 1
                          ? {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize:18,
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize:18,
                             }} className={classes.para}>
                {TextTranslation.about_encyclopedia_text_7[store.langIndex]}
                </p>
                <p style={ 
                  store.langIndex == 0
                    ? {  textAlign: "left",fontFamily: "Raleway, sans-serif",textAlign:'justify' }
                    :store.langIndex == 1
                          ? {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize:18,
                            }
                          : {
                                lineHeight:1.8,
                                textAlign: "right",
                                fontFamily: "Arabic",
                                fontSize:18,
                             }} className={classes.para}>
                {TextTranslation.about_encyclopedia_text_8[store.langIndex]}
                </p>
              </div>
            </Grid>
          </Grid>
          <div className="mt-2 mb-2 text-center">
            <button
              className="btn"
              style={{
                backgroundColor: Colors.primary,
              }}
            >
              <a
                href="https://www.minhajbooks.com/english/book/621/Quranic-Encyclopedia/"
                target="_blank"
                style={{
                  color: Colors.white,
                  fontFamily:
                    store.langIndex == 0
                      ? "Raleway, sans-serif"
                      : store.langIndex == 1
                      ? "JameelNoori"
                      : "Arabic",
                }}
              >
                {TextTranslation.read_more[store.langIndex]}
              </a>
            </button>
          </div>
        </>
      )}
    </Observer>
  );
};

export default AboutQuranicEncyclopedia;
