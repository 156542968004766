import React, { useContext } from "react";
import { StoreContext } from "../../store/store";
import { Observer } from "mobx-react-lite";
import { Grid, Card, CardMedia, CardActionArea } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../images/MIE_Dark.png";
import Colors from "../../Config/Colors";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import TextTranslation from "../../Config/TextTranslation";

const useStyles = makeStyles((theme) => ({
  video: {
    height: 500,
    [theme.breakpoints.down("md")]: {
      height: 200,
    },
  },
}));

const AboutUs = () => {
  const store = useContext(StoreContext);
  const classes = useStyles();

  return (
    <Observer>
      {() => (
        <>
          <CommonNavbar />
          <div className="pt-5 d-flex justify-content-center">
            <img src={logo}  className="col-md-2 col-lg-2 col-6"/>
          </div>
          <div className="mt-3">
            <h5
              className="text-center"
              style={{
                color: Colors.primary,
                textAlign:'justify',
                
               
                fontFamily:
                  store.langIndex == 0
                    ? "Raleway,sans-serif"
                    : store.langIndex == 1
                    ? "JameelNoori"
                    : "Arabic",
              }}
            >
              {TextTranslation.welcome_to_mde[store.langIndex]}
            </h5>
            <p
              style={{
                fontFamily:
                  store.langIndex == 0
                    ? "Raleway,sans-serif"
                    : store.langIndex == 1
                    ? "JameelNoori"
                    : "Arabic",
                fontSize:
                  store.langIndex == 0 ? 14 : store.langIndex == 1 ? 18 : 16,
                lineHeight:
                  store.langIndex == 0 ? 2 : store.langIndex == 1 ? 2 : 2,
                maxWidth: "90%",
                margin: "auto",
                textAlign: "center",
                textAlign:'justify'
              }}
            >
              {TextTranslation.mde_detail[store.langIndex]}
            </p>
          </div>
          <h6
            className="text-center mt-3"
            style={{
              color: Colors.primary,
              textAlign:'justify',
             
              fontFamily:
                store.langIndex == 0
                  ? "Raleway,sans-serif"
                  : store.langIndex == 1
                  ? "JameelNoori"
                  : "Arabic",
            }}
          >
            {TextTranslation.mde_video[store.langIndex]}
          </h6>
          <Grid
            container
            spacing={0}
            justify="center"
            alignItems="center"
            direction="row"
          >
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    className={classes.video}
                    component="iframe"
                    alt=""
                    image="https://www.youtube.com/embed/1OJPwt6g3WY"
                    title="Contemplative Reptile"
                    allowFullScreen={true}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
          <h6
            className="text-center mt-3"
            style={{
              color: Colors.primary,
              fontFamily:
                store.langIndex == 0
                  ? "Raleway,sans-serif"
                  : store.langIndex == 1
                  ? "JameelNoori"
                  : "Arabic",
            }}
          >
            {TextTranslation.mde_Simulation[store.langIndex]}
          </h6>
          <Grid
            container
            spacing={0}
            justify="center"
            alignItems="center"
            direction="row"
          >
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    className={classes.video}
                    component="iframe"
                    alt=""
                    image="https://www.youtube.com/embed/LUKzVVZ6JQQ"
                    title="Contemplative Reptile"
                    allowFullScreen={true}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
          <h6
            className="text-center mt-3"
            style={{
              color: Colors.primary,
              fontFamily:
                store.langIndex == 0
                  ? "Raleway,sans-serif"
                  : store.langIndex == 1
                  ? "JameelNoori"
                  : "Arabic",
            }}
          >
            {TextTranslation.thankyou[store.langIndex]}
          </h6>
        </>
      )}
    </Observer>
  );
};
export default AboutUs;
