import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Observer } from "mobx-react-lite";
import { StoreContext } from "../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../Config/Colors";
import TextTranslation from "../../Config/TextTranslation";

const useStyles = makeStyles((theme) => ({
  main: {
    position: "fixed",
    width: "100%",
    bottom: "0px",
    backgroundColor: "#f2f2f2",
    padding: "10px 30px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "block",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  left: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  leftInner: {
    padding: "0px 20px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 10px",
    },
  },
  right: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  rightInner: {
    padding: "0px 20px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 10px",
    },
  },
  link: {
    color: Colors.black,
    "&:hover": {
      color: Colors.primary,
      textDecoration: "none",
      cursor: "pointer",
    },
  },
}));

function Footer() {
  const store = useContext(StoreContext);
  const classes = useStyles(); // styles classes

  return (
    <Observer>
      {() => (
        <>
          <footer >
            <div className="col-12 d-sm-flex d-block fixed-bottom lead  ">
              <div className="col-12 col-sm-6 d-flex justify-content-sm-start justify-content-center">
                <div className={classes.leftInner}>
                  <Link
                    to="/privacy"
                    className={classes.link}
                    style={{
                      fontFamily:
                        store.langIndex == 0
                          ? "Raleway, sans-serif"
                          : store.langIndex == 1
                          ? "JameelNoori"
                          : "Arabic",
                      fontSize:
                        store.langIndex == 0
                          ? 19
                          : store.langIndex == 1
                          ? 17
                          : 16,
                    }}
                  >
                    {TextTranslation.privacy[store.langIndex]}
                  </Link>
                </div>
                <div className={classes.leftInner}>
                  <Link
                    to="/features"
                    className={classes.link}
                    style={{
                      fontFamily:
                        store.langIndex == 0
                          ? "Raleway, sans-serif"
                          : store.langIndex == 1
                          ? "JameelNoori"
                          : "Arabic",
                      fontSize:
                        store.langIndex == 0
                          ? 19
                          : store.langIndex == 1
                          ? 17
                          : 16,
                    }}
                  >
                    {TextTranslation.features[store.langIndex]}
                  </Link>
                </div>
                <div className={classes.leftInner}>
                  <Link
                    to="/contactus"
                    className={classes.link}
                    style={{
                      fontFamily:
                        store.langIndex == 0
                          ? "Raleway, sans-serif"
                          : store.langIndex == 1
                          ? "JameelNoori"
                          : "Arabic",
                      fontSize:
                        store.langIndex == 0
                          ? 19
                          : store.langIndex == 1
                          ? 17
                          : 16,
                    }}
                  >
                    {TextTranslation.suggestion_contact_us[store.langIndex]}
                  </Link>
                </div>
              </div>
              <div className="col-12 col-sm-6 d-flex justify-content-sm-end justify-content-center ">
                <div className={classes.rightInner}>
                  <Link
                    to="/aboutus"
                    className={classes.link}
                    style={{
                      fontFamily:
                        store.langIndex == 0
                          ? "Raleway, sans-serif"
                          : store.langIndex == 1
                          ? "JameelNoori"
                          : "Arabic",
                      fontSize:
                        store.langIndex == 0
                        ? 19
                        : store.langIndex == 1
                        ? 17
                        : 16,
                    }}
                  >
                    {TextTranslation.about_us[store.langIndex]}
                  </Link>
                </div>
                <div className={classes.rightInner}>
                  <Link
                    to="/aboutauthor"
                    className={classes.link}
                    style={{
                      fontFamily:
                        store.langIndex == 0
                          ? "Raleway, sans-serif"
                          : store.langIndex == 1
                          ? "JameelNoori"
                          : "Arabic",
                      fontSize:
                        store.langIndex == 0
                          ? 19
                          : store.langIndex == 1
                          ? 17
                          : 16,
                    }}
                  >
                    {TextTranslation.about_author[store.langIndex]}
                  </Link>
                </div>
                <div className={classes.rightInner}>
                  <Link
                    to="/aboutencyclopedia"
                    className={classes.link}
                    style={{
                      fontFamily:
                        store.langIndex == 0
                          ? "Raleway, sans-serif"
                          : store.langIndex == 1
                          ? "JameelNoori"
                          : "Arabic",
                      fontSize:
                        store.langIndex == 0
                          ? 19
                          : store.langIndex == 1
                          ? 17
                          : 16,
                    }}
                        >
                    {TextTranslation.about_quranic_encyclopedia[store.langIndex]}
                  </Link>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </Observer>
  );
}

export default Footer;
