import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from "../../store/store";
import { Card, Grid } from "@material-ui/core";
import { decode } from "html-entities";
import { Observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DividerWithText from "../../Utils/Divider/Divider";
import TextTranslation from "../../Config/TextTranslation";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import QuranicText from "../../CustomComponents/QuranicText";
import Colors from "../../Config/Colors";
import axios from "axios";


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

const PublicArticleSelectedView = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let id = useParams(); // getting params from previous screen
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [data, setData] = useState([]); // data state
  const [remark, setRemark] = useState(""); // remarks state
  const [loader, setLoader] = useState(true); // loader state


  const getArticleSelectedView = () => {
    // form data
    var GetArticleViewFormData = new FormData();
    GetArticleViewFormData.append("article_uid", id.id);
    GetArticleViewFormData.append("article_title", id.title);

    axios({
      method: "post",
      url: "https://api.minhaj.digital/get_article_data.php",
      data: GetArticleViewFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    })
      .then((res) => {
        //handle success
        console.log(
          "public article selected view response",
          res.data.MDE_API[0].Data
        );
        setRemark(res.data.MDE_API[0].Remarks);
        setData(res.data.MDE_API[0].Data);
        setLoader(false);
      })
      .catch((err) => {
        //handle error
        console.log("public article selected view error", err);
      });
  };

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    getArticleSelectedView();
    return () => ac.abort();
  }, []);

  return (
    <Observer>
      {() => (
        <>
          <CommonNavbar />
          <div className="mt-5 pt-5">
            {
              <>
                {!loader ? (
                  <Grid container alignItems="center" justify="center">
                    <Grid item xs={11} sm={11} md={8} lg={10} className="mt-3">
                      <Card
                        className="pt-1 pb-3 mb-3"
                        style={{ boxShadow: "1px 2px 10px 0 #e32636" }}
                      >
                        <p
                          className="text-center pt-2 "
                          style={
                            store.langIndex == 0
                              ? {
                                  fontFamily: /[a-zA-Z]/g.test(
                                    store.publicArticleTitle
                                  )
                                    ? "Raleway,sans-serif"
                                    : "JameelNoori",
                                  color: Colors.primary,
                                  fontSize: 20,
                                }
                              : store.langIndex == 1
                              ? {
                                  fontFamily: /[a-zA-Z]/g.test(
                                    store.publicArticleTitle
                                  )
                                    ? "Raleway,sans-serif"
                                    : "JameelNoori",
                                  color: Colors.primary,
                                  fontSize: 24,
                                }
                              : {
                                  fontFamily: /[a-zA-Z]/g.test(
                                    store.publicArticleTitle
                                  )
                                    ? "Raleway,sans-serif"
                                    : "Arabic",
                                  color: Colors.primary,
                                  fontSize: 24,
                                }
                          }
                        >
                          {store.publicArticleTitle}
                        </p>
                        {data.map((d, index) => (
                          <div key={index} className="mx-3">
                            <QuranicText ayat={d.ayat_no}>{d.ar_text_erab}</QuranicText>
                            <p
                              className="mx-3"
                              style={
                                store.langIndex == 0
                                  ? {
                                    fontSize: /[a-zA-Z]/g.test(d.translation)
                                    ? 14
                                    : 18,
                                      textAlign: /[a-zA-Z]/g.test(d.translation)
                                        ? "left"
                                        : "right",
                                      fontFamily: /[a-zA-Z]/g.test(
                                        d.translation
                                      )
                                        ? "Raleway,sans-serif"
                                        : "JameelNoori",
                                    }
                                  : {
                                      fontFamily: /[a-zA-Z]/g.test(
                                        d.translation
                                      )
                                        ? "Raleway,sans-serif"
                                        : "JameelNoori",

                                      textAlign: /[a-zA-Z]/g.test(d.translation)
                                        ? "left"
                                        : "right",
                                        fontSize: /[a-zA-Z]/g.test(d.translation)
                                        ? 14
                                        : 18,
                                      lineHeight: "40px",
                                    }
                              }
                            >
                              {decode(d.translation)}
                            </p>
                          
                            <DividerWithText>
                              <span
                                style={{
                                  fontFamily: "Raleway, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                {d.ayat_no} :&nbsp;{d.sura_no} ،&nbsp;
                                {d.sura_arname}
                              </span>
                            </DividerWithText>
                          </div>
                        ))}
                      </Card>
                      <p
                        style={
                          store.langIndex == 0
                            ? { direction: "ltr" }
                            : { direction: "rtl", textAlign: "right" }
                        }
                      >
                        <span
                          style={
                            store.langIndex == 0
                              ? {
                                  fontFamily: "Raleway,sans-serif",

                                  color: Colors.primary,
                                }
                              : store.langIndex == 1
                              ? {
                                  fontFamily: "JameelNoori",
                                  color: Colors.primary,
                                  fontSize: 22,
                                }
                              : {
                                  fontFamily: "Arabic",
                                  fontSize: 22,
                                  color: Colors.primary,
                                }
                          }
                        >
                          {TextTranslation.remark[store.langIndex]}
                        </span>
                        &nbsp;&nbsp;
                        <span
                          style={
                            store.langIndex == 0
                              ? {
                                  fontFamily: /[a-zA-Z]/g.test(remark)
                                    ? "Raleway,sans-serif"
                                    : "JameelNoori",
                                }
                              : store.langIndex == 1
                              ? {
                                  fontFamily: /[a-zA-Z]/g.test(remark)
                                    ? "Raleway,sans-serif"
                                    : "JameelNoori",
                                  fontSize: 20,
                                }
                              : {
                                  fontFamily: "Arabic",
                                  fontSize: 20,
                                }
                          }
                        >
                          {remark}
                        </span>
                      </p>
                      <div
                        style={{
                          margin: "auto",
                          marginTop: "1%",
                          marginBottom: "1%",
                          backgroundColor: Colors.green,
                          color: Colors.white,
                          width: "160px",
                          height: "50px",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={
                            store.langIndex == 0
                              ? {
                                  fontFamily: "Raleway,sans-serif",
                                  fontSize: 14,
                                }
                              : store.langIndex == 1
                              ? {
                                  fontFamily: "JameelNoori",
                                  fontSize: 18,
                                }
                              : {
                                  fontFamily: "Arabic",
                                  fontSize: 18,
                                }
                          }
                        >
                          {TextTranslation.print_save[store.langIndex]}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    spacing={0}
                    justify="center"
                    alignItems="center"
                    style={{ minHeight: "80vh" }}
                  >
                    <Grid>
                      <CircularProgress
                        className={classes.loader}
                        size={50}
                        thickness={3}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            }
          </div>
        </>
      )}
    </Observer>
  );
};

export default PublicArticleSelectedView;
