import React, { useState, useEffect, useContext } from "react";
import { Grid, Card, CircularProgress } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { Observer } from "mobx-react-lite";
import { decode } from "html-entities";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Colors from "../../Config/Colors";
import RichTextEditor from "react-rte";
import lodash from "lodash";
import axios from "axios";
import DividerWithText from "../../Utils/Divider/Divider";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";

var persianDigits = "۰۱۲۳۴۵۶۷۸۹";
var persianMap = persianDigits.split("");

// modal styling
const useStyles = makeStyles((theme) => ({
  loader: {
    color: Colors.primary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    width: "60%",
    minHeight: 400,
    maxHeight: 500,
    overflowY: "scroll",
    borderRadius: "5px",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  input: {
    fontFamily: "Raleway,sans-serif",
    width: "70%",
    fontSize: 14,
  },
}));

const NewArticle = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const classes = useStyles();
  const store = useContext(StoreContext);
  const [editorState, setEditorState] = useState(() =>
    RichTextEditor.createEmptyValue()
  );
  const [open, setOpen] = useState(false);
  const [bookmarkTitle, setBookmarkTitle] = useState([]);
  const [search, setSearch] = useState("");
  const [switchTab, setSwitchTab] = useState(false);
  const [bookmarkResult, setBookmarkResult] = useState([]);
  const [translation, setTranslation] = useState(
    store.langIndex == 0 ? 0 : store.langIndex == 1 ? 1 : 2
  );
  const [copied, setCopied] = useState(false);
  const [loader, setLoader] = useState(true);

  // text editor onchange
  const onChange = (value) => {
    setEditorState(value);

    console.log("Value", value.toString("html"));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // copy to clipboard function
  const CopyData = (arabic, translations) => {
    return "" + arabic + "\n" + translations + "";
  };

  //bookmark title
  const postBookmarkTitle = () => {
    axios({
      method: "post",
      url: "https://api.minhaj.digital/get_bookmarks_multiref.php",
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    }).then((res) => {
      // console.log("get post bookmarks title api console", res.data);

      setBookmarkTitle(res.data.MDE_API[0].MSG);
      setLoader(false);
    });
  };
  let data = lodash.groupBy(bookmarkTitle, "search_title");

  // post bookmark result API
  const postBookmarkResult = (title) => {
    var BookmarkResultData = new FormData();

    BookmarkResultData.append("title", title);

    axios({
      method: "post",
      url: "https://api.minhaj.digital/get_bookmark_data_on_title.php",
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
      data: BookmarkResultData,
    }).then((res) => {
      console.log("get post bookmarks result api console", res.data);
      setBookmarkResult(res.data.MDE_API[0].MSG);
      setLoader(false);
    });
  };

  let data1 = lodash.groupBy(bookmarkResult, "topic");

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    postBookmarkTitle();
    return () => ac.abort();
  }, []);

  return (
    <Observer>
      {() => (
        <div>
          <CommonNavbar />
          <h4 className="text-center pt-4" style={{ color: Colors.primary }}>
            Create New Article
          </h4>
          <Grid container justify="center">
            <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
              <RichTextEditor
                placeholder="Enter here"
                value={editorState}
                onChange={onChange}
                editorStyle={{ minHeight: 200, maxHeight: "auto" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}
                onClick={handleOpen}
              >
                <button
                  style={{
                    backgroundColor: Colors.primary,
                    color: Colors.white,
                    border: "none",
                    borderRadius: "5px",
                    height: 40,
                  }}
                >
                  <div
                    style={
                      store.langIndex == 0
                        ? { fontFamily: "Raleway, sans-serif" }
                        : store.langIndex == 1
                        ? { fontFamily: "JameelNoori", fontSize: 18 }
                        : { fontFamily: "AlQalam", fontSize: 18 }
                    }
                  >
                    Create New Article
                  </div>
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <button
                  style={{
                    backgroundColor: Colors.primary,
                    color: Colors.white,
                    border: "none",
                    borderRadius: "5px",
                    height: 40,
                  }}
                >
                  <div
                    style={
                      store.langIndex == 0
                        ? { fontFamily: "Raleway, sans-serif" }
                        : store.langIndex == 1
                        ? { fontFamily: "JameelNoori", fontSize: 18 }
                        : { fontFamily: "AlQalam", fontSize: 18 }
                    }
                  >
                    Generate PDF
                  </div>
                </button>
              </div>
            </Grid>
          </Grid>
          <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <h4
                  className="text-center"
                  style={{
                    color: Colors.primary,
                    fontFamily: "Raleway, sans-serif",
                  }}
                >
                  {!switchTab ? "Bookmark Title" : "Bookmark Result"}
                </h4>
                {!switchTab ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 10,
                    }}
                  >
                    <input
                      placeholder="Enter here"
                      className={classes.input}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button
                      style={{
                        backgroundColor: Colors.primary,
                        color: "white",
                        border: "0px solid #e32636",
                        fontFamily: "Raleway, sans-serif",
                      }}
                    >
                      Search
                    </button>
                  </div>
                ) : (
                  <i
                    className="fal fa-arrow-left"
                    style={{
                      cursor: "pointer",
                      color: Colors.primary,
                      paddingBottom: 15,
                    }}
                    onClick={() => setSwitchTab(false)}
                  />
                )}
                {!switchTab
                  ? Object.keys(data)
                      .filter((e) =>
                        e.toLowerCase().includes(search.toLowerCase())
                      )
                      .map((key, index) => (
                        <>
                          {loader ? (
                            <Grid
                              container
                              spacing={0}
                              justify="center"
                              alignItems="center"
                              directioin="column"
                              style={{ minHeight: "80vh" }}
                            >
                              <Grid>
                                <CircularProgress
                                  className={classes.loader}
                                  size={50}
                                  thickness={3}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <Card
                              //className="bookmark-card mb-2"
                              className="mb-2"
                              style={
                                store.langIndex == 0
                                  ? { direction: "ltr", cursor: "pointer" }
                                  : { direction: "rtl", cursor: "pointer" }
                              }
                              raised={true}
                              key={index}
                              onClick={() => {
                                setSwitchTab(true);
                                postBookmarkResult(key);
                              }}
                            >
                              <div
                                className="d-flex p-3"
                                style={{
                                  float: /[a-zA-Z]/g.test(key)
                                    ? "left"
                                    : "right",
                                }}
                              >
                                <p
                                  className="mt-3 mx-3"
                                  style={
                                    data[key][0].lang_id == 1
                                      ? {
                                          fontFamily: "Raleway,sans-serif",
                                        }
                                      : data[key][0].lang_id == 2
                                      ? {
                                          fontFamily: "JameelNoori",
                                          fontSize: 18,
                                        }
                                      : {
                                          fontFamily: "Arabic",
                                          fontSize: 18,
                                        }
                                  }
                                >
                                  {key}
                                </p>
                              </div>
                            </Card>
                          )}
                        </>
                      ))
                  : Object.keys(data1).map((key, index) => (
                      <>
                        {loader ? (
                          <Grid
                            container
                            spacing={0}
                            justify="center"
                            alignItems="center"
                            directioin="column"
                            style={{ minHeight: "30vh" }}
                          >
                            <Grid>
                              <CircularProgress
                                className={classes.loader}
                                size={50}
                                thickness={3}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <Card
                            className="pt-1 pb-3 mb-3"
                            key={index}
                            style={{ boxShadow: "1px 2px 10px 0 #e32636" }}
                          >
                            <p
                              className="text-center pt-2 mx-5"
                              style={{
                                color: Colors.primary,
                                fontSize: "20px",
                              }}
                            >
                              <span
                                style={
                                  data1[key][0].lang_id == 1
                                    ? {
                                        fontFamily: "Raleway,sans-serif",
                                      }
                                    : data1[key][0].lang_id == 2
                                    ? {
                                        fontFamily: "JameelNoori",
                                        fontSize: 18,
                                      }
                                    : {
                                        fontFamily: "Arabic",
                                        fontSize: 18,
                                      }
                                }
                              >
                                {decode(key)}
                              </span>
                            </p>
                            {data1[key].map((data, index) => (
                              <div key={index}>
                                <h5
                                  className="text-right mx-3 mt-3 mb-3"
                                  style={{
                                    fontFamily: "AlQalam",
                                    lineHeight: "40px",
                                  }}
                                >
                                  {decode(data.ar_text_erab)
                                    .replace(
                                      "<img src=https://minhaj.digital/beta.version/en/images/waqf-lazim_mim_sm.png>",
                                      decode("&#x6D8")
                                    )
                                    .replace(
                                      "<img src=http://www.irfan-ul-quran.com/quran/images/waqf-lazim_mim_sm.png>",
                                      decode("&#x6D8")
                                    )}
                                  &nbsp;
                                  <span
                                    style={{
                                      fontFamily: "AlQalam1",
                                    }}
                                  >
                                    {data.ayat_no
                                      .toString()
                                      .replace(/\d/g, function (m) {
                                        return persianMap[parseInt(m)];
                                      })
                                      .split("")
                                      .reverse()
                                      .join("") + decode("&#1757;")}
                                  </span>
                                </h5>

                                <p
                                  className={
                                    store.langIndex == 0
                                      ? "text-left mx-3"
                                      : "text-right mx-3"
                                  }
                                >
                                  <div
                                    style={
                                      store.langIndex == 0
                                        ? {
                                            //fontFamily: "Raleway,sans-serif",
                                            textAlign: /[a-zA-Z]/g.test(
                                              data.translation
                                            )
                                              ? "left"
                                              : "right",
                                            fontFamily: /[a-zA-Z]/g.test(
                                              data.translation
                                            )
                                              ? "Raleway,sans-serif"
                                              : "JameelNoori",
                                            fontSize: /[a-zA-Z]/g.test(
                                              data.translation
                                            )
                                              ? 14
                                              : 18,
                                          }
                                        : {
                                            fontFamily: "JameelNoori",
                                            textAlign: "left",
                                            fontSize: 18,
                                          }
                                    }
                                  >
                                    {data.translation}
                                  </div>
                                </p>
                                <div className="text-right mx-3">
                                  <CopyToClipboard
                                    text={
                                      translation == 1
                                        ? CopyData(
                                            data.ar_text_erab,
                                            data.translation
                                          )
                                        : CopyData(
                                            data.ar_text_erab,
                                            data.translation
                                          )
                                    }
                                    onCopy={() => {
                                      setCopied(true);
                                      alert("copied");
                                      setOpen(false);
                                    }}
                                  >
                                    <span>
                                      <i
                                        className="far fa-copy fa-sm mx-2"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </span>
                                  </CopyToClipboard>
                                </div>
                                <DividerWithText>
                                  <span
                                    style={
                                      store.langIndex == 2
                                        ? { fontFamily: "Arabic", fontSize: 14 }
                                        : {
                                            fontFamily: "Raleway, sans-serif",
                                            fontSize: 14,
                                          }
                                    }
                                  >
                                    {data.ayat_no} :&nbsp;{data.sura_no} ،&nbsp;
                                    {data.sura_arname}
                                  </span>
                                </DividerWithText>
                              </div>
                            ))}
                          </Card>
                        )}
                      </>
                    ))}
              </div>
            </Fade>
          </Modal>
        </div>
      )}
    </Observer>
  );
};

export default NewArticle;
