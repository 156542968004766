import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Grid, Card } from "@material-ui/core";
import { StoreContext } from "../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Colors from "../../Config/Colors";
import "./PublicArticleTitle.css";
import axios from "axios";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

const PublicArticleSelected = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let id = useParams(); // getting param from previous screen
  let history = useHistory();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [arr, setArr] = useState([]);
  const [loader, setLoader] = useState(true);
  
  // get public article title API
  const getPublicArticlesTitle = () => {
    var ArticleTitleFormData = new FormData();
    ArticleTitleFormData.append("category_id", id.id);

    axios({
      method: "post",
      url: "https://api.minhaj.digital/get_public_article_titles.php",
      data: ArticleTitleFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    })
      .then((res) => {
        setArr(res.data.MDE_API[0].MSG);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    getPublicArticlesTitle();
    return () => ac.abort();
  }, []);
  
  return (
    <>
      <CommonNavbar />
      <Grid container spacing={0} justify="center" alignItems="center">
        <Grid item xs={10} sm={10} md={10} lg={7} xl={6} className="pt-5">
          {!loader ? (
            arr.map((data, index) => (
              <Card
                key={index}
                className="title-card mt-2"
                raised={true}
                onClick={() => {
                  history.push(
                    `/publicarticleselectedview/${data.article_title}/${data.uid}`
                  );
                  store.setPublicArticleTitle(data.article_title);
                }}
              >
                <div className="mx-3 pt-2 pb-2">
                   <span
                    style={
                      store.langIndex == 0
                        ? {
                            fontFamily: /[a-zA-Z]/g.test(data.article_title)
                              ? "Raleway,sans-serif"
                              : "JameelNoori",
                              fontSize: /[a-zA-Z]/g.test(data.article_title)
                              ? 14
                              : 16,
                          }
                        : store.langIndex == 1
                        ? {
                            fontFamily: /[a-zA-Z]/g.test(data.article_title)
                              ? "Raleway,sans-serif"
                              : "JameelNoori",
                              fontSize: /[a-zA-Z]/g.test(data.article_title)
                              ? 14
                              : 16,
                          }
                        : { fontFamily: "Arabic",fontSize: /[a-zA-Z]/g.test(data.article_title)
                        ? 14
                        : 16, }
                    }
                  >
                    {data.article_title}
                  </span> 
                  
                  <br />
                  <span
                    className="text-right mx-3"
                    style={{
                      color: Colors.primary,
                      display: "flex",
                      fontFamily: "Raleway, sans-serif",
                      fontSize: 14,
                      justifyContent: "flex-end",
                    }}
                  >
                    {data.user_name}
                  </span>
                </div>
              </Card>
            ))
          ) : (
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              style={{ minHeight: "80vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PublicArticleSelected;
