import {
  Grid,
  Card,
  TextField,
  FormControl,
  Input,
  MenuItem,
  Select,
  Chip,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { Observer } from "mobx-react-lite";
import { useParams, useHistory } from "react-router-dom";
import { decode } from "html-entities";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import TextTranslation from "../../Config/TextTranslation";
import DividerWithText from "../../Utils/Divider/Divider";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Colors from "../../Config/Colors";
import lodash from "lodash";
import axios from "axios";
import QuranicText from "../../CustomComponents/QuranicText";
import UrduText from "../../CustomComponents/UrduText";
import EngText from "../../CustomComponents/EngText";
import Heading from "../../CustomComponents/Heading";
import Url from "../../Config/Url";

//alert function
const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

// modal styling
const useStyles = makeStyles((theme) => ({
  loader: {
    color: Colors.primary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    width: "40%",
    height: "auto",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

// multiple selection styling
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 18;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
// main function
const UserBookmarkAyatResult = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let search = useParams(); // getting params from quran bookmark title
  let history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [result, setResult] = useState([]);
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [selected, setSelected] = useState([]);
  const [name, setName] = useState("");
  const [remarks, setRemarks] = useState("");
  const [loader, setLoader] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [success, setSuccess] = useState("");

  // post bookmark result API
  const postBookmarkResult = () => {
    var BookmarkResultData = new FormData();

    BookmarkResultData.append("title", search.title);

    axios({
      method: "post",
      url: `${Url.link}/get_ayat_bookmark.php`,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
      data: BookmarkResultData,
    }).then((res) => {
      console.log("get post bookmarks result api console", res.data);
      setResult(res.data.MDE_API[0].MSG);
      setLoader(false);
    });
  };

  // delete bookmark API

  const deleteBookmark = (sura_no, ayat_no) => {
    var DeleteBookmarkData = new FormData();

    DeleteBookmarkData.append("ayat_no", ayat_no);
    DeleteBookmarkData.append("sura_no", sura_no);

    axios({
      method: "post",
      url: `${Url.link}/delete_ayat_bookmark.php`,
      data: DeleteBookmarkData,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    }).then((res) => {
      if (window.confirm("Are you sure to delete?")) {
        postBookmarkResult();
        setOpen1(true);
        setSuccess(1);
      } else {
        setOpen1(true);
        setSuccess(0);
      }
      console.log("delete bookmarks api console", res.data);
    });
  };

  let data = lodash.groupBy(result, "topic");
  console.log("group by serach title", result);

  // get Category API
  const getCategory = () => {
    axios({
      method: "post",
      url: `${Url.link}/get_article_categories.php`,
    }).then((res) => {
      setCategory(res.data.MDE_API[0].MSG);
    });
  };

  // post article API
  const postArticle = () => {
    let cat = [];
    for (let i = 0; i < selected.length; i++) {
      cat.push(selected[i].cat_id);
    }
    // form data
    var ArticleFormData = new FormData();
    ArticleFormData.append("title", search.title);
    ArticleFormData.append("article_title", name);
    ArticleFormData.append("remarks", remarks);
    ArticleFormData.append("lang_id", parseInt(store.langIndex) + 1);
    ArticleFormData.append("categories", JSON.stringify(cat));

    axios({
      method: "post",
      url: `${Url.link}/add_article.php?`,
      data: ArticleFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    })
      .then((res) => {
        console.log("post article api console", res.data);
      })
      .catch((err) => {
        console.log("post article error", err);
      });
  };

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    postBookmarkResult();
    getCategory();
    return () => ac.abort();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;

    setSelected(value);
  };

  // alert close function
  const handleClose1 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen1(false);
  };

  return (
    <Observer>
      {() => (
        <>
          <CommonNavbar />
          {/* snackbar alert */}
          <Snackbar open={open1} autoHideDuration={1000} onClose={handleClose1}>
            <Alert
              onClose={handleClose1}
              severity={success === 0 ? "error" : "success"}
            >
              {success === 0
                ? "Bookmark Not Deleted"
                : "Selected Bookamark Deleted Successfully"}
            </Alert>
          </Snackbar>
          <div className="pt-1">
            <div
              style={{
                margin: "auto",
                marginTop: "8%",
                backgroundColor: Colors.primary,
                color: Colors.white,
                width: "150px",
                height: "50px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={handleOpen}
            >
              <span
                style={
                  store.langIndex == 0
                    ? { fontFamily: "Raleway, sans-serif", fontSize: 16 }
                    : store.langIndex == 1
                    ? { fontFamily: "JameelNoori", fontSize: 18 }
                    : { fontFamily: "Arabic", fontSize: 18 }
                }
              >
                {TextTranslation.generate_article[store.langIndex]}
              </span>
            </div>
            <Modal
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <Heading>
                    {TextTranslation.generate_article[store.langIndex]}
                  </Heading>

                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    flexDirection="column"
                    spacing={0}
                  >
                    <Grid item xs={10} sm={10} md={10} lg={10}>
                      {store.langIndex == 0 ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          className="mb-2 mt-2"
                          label={TextTranslation.subject[store.langIndex]}
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          inputProps={
                            store.langIndex == 1
                              ? {
                                  min: 0,
                                  style: {
                                    textAlign: "right",
                                    color: Colors.primary,
                                    fontFamily: "JameelNoori",
                                    fontSize: 18,
                                  },
                                }
                              : {
                                  min: 0,
                                  style: {
                                    textAlign: "right",
                                    color: Colors.primary,
                                    fontFamily: "Arabic",
                                    fontSize: 18,
                                  },
                                }
                          }
                          FormHelperTextProps={
                            store.langIndex == 1
                              ? {
                                  style: {
                                    textAlign: "right",
                                    fontFamily: "JameelNoori",
                                    fontSize: 16,
                                  },
                                }
                              : {
                                  style: {
                                    textAlign: "right",
                                    fontFamily: "Arabic",
                                    fontSize: 16,
                                  },
                                }
                          }
                          variant="outlined"
                          name="name"
                          placeholder={TextTranslation.subject[store.langIndex]}
                          className="mb-2 mt-2"
                          onChange={(e) => setName(e.target.value)}
                        />
                      )}
                      {store.langIndex == 0 ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          className="mb-2 mt-2"
                          label={TextTranslation.remarks[store.langIndex]}
                          name="remakrs"
                          onChange={(e) => setRemarks(e.target.value)}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          inputProps={
                            store.langIndex == 1
                              ? {
                                  min: 0,
                                  style: {
                                    textAlign: "right",
                                    color: Colors.primary,
                                    fontFamily: "JameelNoori",
                                    fontSize: 18,
                                  },
                                }
                              : {
                                  min: 0,
                                  style: {
                                    textAlign: "right",
                                    color: Colors.primary,
                                    fontFamily: "Arabic",
                                    fontSize: 18,
                                  },
                                }
                          }
                          FormHelperTextProps={
                            store.langIndex == 1
                              ? {
                                  style: {
                                    textAlign: "right",
                                    fontFamily: "JameelNoori",
                                    fontSize: 16,
                                  },
                                }
                              : {
                                  style: {
                                    textAlign: "right",
                                    fontFamily: "Arabic",
                                    fontSize: 16,
                                  },
                                }
                          }
                          variant="outlined"
                          name="remarks"
                          placeholder={TextTranslation.remarks[store.langIndex]}
                          className="mb-2 mt-2"
                          onChange={(e) => setRemarks(e.target.value)}
                        />
                      )}

                      {store.langIndex == 0 ? (
                        <FormControl className={classes.formControl} fullWidth>
                          <span style={{ color: Colors.primary }}>
                            <span style={{ fontFamily: "Raleway, sans-serif" }}>
                              {TextTranslation.select_category[store.langIndex]}
                            </span>
                          </span>
                          <Select
                            multiple
                            fullWidth
                            value={selected}
                            defaultValue=""
                            onChange={handleChange}
                            input={<Input />}
                            renderValue={(selected, index) => (
                              <div key={index} className={classes.chips}>
                                {selected.map((value, index) => (
                                  <Chip
                                    key={index}
                                    label={value.cat_eng}
                                    className={classes.chip}
                                  />
                                ))}
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {category.map((name, index) => (
                              <MenuItem
                                key={index}
                                value={name}
                                style={getStyles(name, category, theme)}
                              >
                                {name.cat_eng}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <FormControl className={classes.formControl} fullWidth>
                          <span
                            className="text-right"
                            style={
                              store.langIndex == 1
                                ? {
                                    color: Colors.primary,
                                    fontFamily: "JameelNoori",
                                    fontSize: 18,
                                  }
                                : {
                                    color: Colors.primary,
                                    fontFamily: "Arabic",
                                    fontSize: 18,
                                  }
                            }
                          >
                            {TextTranslation.select_category[store.langIndex]}
                          </span>
                          <Select
                            multiple
                            fullWidth
                            value={selected}
                            defaultValue=""
                            onChange={handleChange}
                            input={<Input />}
                            renderValue={(selected, index) => (
                              <div key={index} className={classes.chips}>
                                {selected.map((value, index) => (
                                  <Chip
                                    key={index}
                                    label={
                                      <span
                                        style={
                                          store.langIndex == 1
                                            ? {
                                                fontFamily: "JameelNoori",
                                                fontSize: 18,
                                              }
                                            : {
                                                fontFamily: "Arabic",
                                              }
                                        }
                                      >
                                        {store.langIndex == 1
                                          ? value.cat_ur
                                          : value.cat_ar}
                                      </span>
                                    }
                                    className={classes.chip}
                                    style={{ margin: "auto" }}
                                  />
                                ))}
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {category.map((name, index) => (
                              <MenuItem
                                dir="rtl"
                                key={index}
                                value={name}
                                style={getStyles(name, category, theme)}
                              >
                                <span
                                  style={
                                    store.langIndex == 1
                                      ? {
                                          fontFamily: "JameelNoori",
                                          fontSize: 18,
                                        }
                                      : { fontFamily: "Arabic" }
                                  }
                                >
                                  {store.langIndex == 1
                                    ? name.cat_ur
                                    : name.cat_ar}
                                </span>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          className="btn btn-success mt-3"
                          onClick={() => postArticle()}
                        >
                          <span
                            style={
                              store.langIndex == 0
                                ? { fontFamily: "Raleway, sans-serif" }
                                : store.langIndex == 1
                                ? { fontFamily: "JameelNoori", fontSize: 18 }
                                : { fontFamily: "Arabic", fontSize: 18 }
                            }
                          >
                            {TextTranslation.print_save[store.langIndex]}
                          </span>
                        </button>
                        <button
                          className="btn btn-danger mt-3"
                          onClick={() => history.push("/userarticle")}
                        >
                          <span
                            style={
                              store.langIndex == 0
                                ? { fontFamily: "Raleway, sans-serif" }
                                : store.langIndex == 1
                                ? { fontFamily: "JameelNoori", fontSize: 18 }
                                : { fontFamily: "Arabic", fontSize: 18 }
                            }
                          >
                            {TextTranslation.my_artice[store.langIndex]}
                          </span>
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Fade>
            </Modal>
          </div>

          {Object.keys(data).map((key, index) => (
            <Grid container alignItems="center" justify="center" key={index}>
              <Grid item xs={11} sm={11} md={8} lg={10} className="mt-3">
                {!loader ? (
                  <Card
                    className="pt-1 pb-3 mb-3"
                    key={index}
                    style={{ boxShadow: "1px 2px 10px 0 #e32636" }}
                  >
                    <Heading
                      styles={{
                        fontFamily:
                          data[key][0].lang_id == 1
                            ? "Raleway,sans-serif"
                            : data[key][0].lang_id == 2
                            ? "JameelNoori"
                            : "Arabic",
                        margin: "5px 0px",
                      }}
                    >
                      {decode(key)}
                    </Heading>
                    {data[key].map((data, index) => (
                      <div key={index}>
                        <QuranicText
                          ayat={data.ayat_no}
                          styles={{ margin: "10px 10px" }}
                        >
                          {data.ar_text_erab}
                        </QuranicText>
                        {data.lang_id == 2 ? (
                          <UrduText
                            styles={{ padding: "5px 20px", lineHeight: 2 }}
                          >
                            {data.translation}
                          </UrduText>
                        ) : (
                          <EngText
                            styles={{ padding: "5px 20px", fontSize: 14 }}
                          >
                            {data.translation}
                          </EngText>
                        )}
                        <div className="text-right mx-3">
                          <i
                            onClick={() =>
                              deleteBookmark(
                                data.search_title,
                                data.topic_id,
                                data.reference_no
                              )
                            }
                            id="delete-icon"
                            className="fas fa-minus-circle fa-sm mx-2"
                          />
                        </div>
                        
                        <DividerWithText>
                          <span
                            style={
                              data.lang_id == 2
                                ? { fontFamily: "AlQalam", fontSize: 18 }
                                : {
                                    fontFamily: "Raleway, sans-serif",
                                    fontSize: 14,
                                  }
                            }
                          >
                            {data.ayat_no} :&nbsp;{data.sura_no} ،&nbsp;
                            {data.sura_arname}
                          </span>
                        </DividerWithText>
                      </div>
                    ))}
                  </Card>
                ) : (
                  <Grid
                    container
                    spacing={0}
                    justify="center"
                    alignItems="center"
                    directioin="column"
                    style={{ minHeight: "100vh" }}
                  >
                    <Grid>
                      <CircularProgress
                        className={classes.loader}
                        size={50}
                        thickness={3}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </Observer>
  );
};

export default UserBookmarkAyatResult;
