import React, { useContext, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useHistory, useParams } from "react-router-dom";
import { StoreContext } from "../../../../store/store";
import { makeStyles } from "@material-ui/core/styles";
import { Observer } from "mobx-react-lite";
import {
  Card,
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Grid,
  Snackbar,
  CardActions,
} from "@material-ui/core";
import CommonNavbar from "../../../../Navbars/CommonNavbar/CommonNavbar";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkSharpIcon from "@material-ui/icons/BookmarkSharp";
import Colors from "../../../../Config/Colors";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import Url from "../../../../Config/Url";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 350,
  },
  loader: {
    color: Colors.primary,
    alignItems: "center",
  },
  icon: {
    fontSize: 20,
    "&:hover": {
      color: Colors.primary,
      cursor: "pointer",
    },
  },
}));

const SelectedSpeech = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let history = useHistory();
  let ParamsFromSpeech = useParams(); // getting params from previous speech screen
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [videoId, setVideoId] = useState([]);
  const [startTime, setStartTime] = useState([]);
  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(false);
console.log('ParamsFromSpeech',ParamsFromSpeech)
  store.setId(localStorage.getItem("@uuid"));

  // copy to clipboard function
  const CopyData = (ParamsFromSpeech) => {
    return `https://www.youtube.com/embed/${
      ParamsFromSpeech.id
    }?start=${parseInt(ParamsFromSpeech.start_time)}`;
  };

  // alert function
  const Alert = (props) => (
    <MuiAlert elevation={6} variant="filled" {...props} />
  );

  // alert close function
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  //get bookmark speech data
  const getBookmarkedData = () => {
    var data = new FormData();
    data.append("title", ParamsFromSpeech.title);
    data.append("lang_id", store.langIndex);

    axios({
      method: "post",
      url: `${Url.link}/get_speeches_bookmarks_for_result.php`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: store.id,
      },
    })
      .then((response) => {
        if (store.id) {
          setStartTime(response.data.MDE_API[0].MSG.start_time);
          setVideoId(response.data.MDE_API[0].MSG.video_id);
        } else {
          setStartTime([]);
          setVideoId([]);
        }
      })
      .catch((error) => {
        console.log("history" + error);
      });
  };
  
  const postSpeech = () => {
    if (!store.id) {
      return history.push("/login");
    } else {
      var data = new FormData();
      data.append("title", ParamsFromSpeech.title);
      data.append("video_id", ParamsFromSpeech.video_id);
      data.append("start_time", ParamsFromSpeech.start_time);
      data.append("lang_id", store.langIndex);
      axios({
        method: "post",
        url: "https://api.minhaj.digital/store_speeches_bookmark.php",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          uuid: store.id,
        },
      })
        .then((response) => {
          getBookmarkedData();
          if (response.data.MDE_API[0].success == 0) {
            alert("Already Bookmarked");
          } else {
            alert("Successfully Bookmarked");
          }
        })
        .catch((error) => {
          console.log("history" + error);
        });
    }
  };

  // delete bookmark api
  const deleteBookmark = () => {
    alert("Delete Bookmark", "Are you sure to delete this bookmark ?");

    var data = new FormData();

    data.append("title", ParamsFromSpeech.title);
    data.append("video_id", ParamsFromSpeech.video_id);
    data.append("start_time", ParamsFromSpeech.start_time);
    data.append("lang_id", store.langIndex);

    axios({
      method: "post",
      url: "https://api.minhaj.digital/delete_speeches_bookmark.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data", uuid: store.id },
    })
      .then((response) => {
        if (response.data.MDE_API[0].success == 1) {
          alert("deleted successfully");
          getBookmarkedData();
        } else {
          alert("Error while deleting bookmark !");
          getBookmarkedData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const abortController = new AbortController();
    console.log('speeches-->')
    getBookmarkedData();
    return () => {
      abortController.abort();
    };
  }, [store.id]);
  return (
    <Observer>
      {() => (
        <>
          <CommonNavbar />
          {/* snackbar alert */}
          <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              copied
            </Alert>
          </Snackbar>
          <Grid
            container
            spacing={0}
            justify="center"
            alignItems="center"

            style={{ minHeight: "90vh" }}
          >
            <Grid className="col-12 col-md-6" item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="iframe"
                    alt=""
                    height="400"
                    image={`https://www.youtube.com/embed/${ParamsFromSpeech.id}?start=${parseInt(ParamsFromSpeech.start_time)}&showinfo=1&fullscreen=1`}
                    title=""
                    allowFullScreen={true}
                  />

                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="caption"
                      component="h2"
                      className="text-center"
                    >
                      {ParamsFromSpeech.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CopyToClipboard
                    text={CopyData(ParamsFromSpeech)}
                    onCopy={() => {
                      setCopied(true);
                      setOpen(true);
                    }}
                  >
                    <FileCopyOutlinedIcon className={classes.icon} />
                  </CopyToClipboard>
                  
                  {startTime.includes(ParamsFromSpeech.start_time) &&
                  videoId.includes(ParamsFromSpeech.video_id) ? (
                    <BookmarkSharpIcon
                      className={classes.icon}
                      style={{ color: Colors.primary }}
                      onClick={() => deleteBookmark()}
                    />
                  ) : (
                    <BookmarkBorderIcon
                      className={classes.icon}
                      onClick={() => {
                        postSpeech();
                      }}
                    />
                  )}
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </Observer>
  );
};

export default SelectedSpeech;
