import { Button, Grid, Container, TextareaAutosize, styled } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../../store/store";
import { Observer } from "mobx-react-lite";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import TextTranslation from "../../Config/TextTranslation";
import TextField from "@material-ui/core/TextField";
import Colors from "../../Config/Colors";
import * as yup from "yup";
import axios from "axios";
import Url from "../../Config/Url";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Field } from "formik";
import logo from "../../images/MIE_Dark_Black.png";

const Joining = () => {
  const store = useContext(StoreContext);
  const [load, setLoad] = useState(false); // button loader state

  document.body.style.backgroundColor = Colors.theme_bg_wb[store.theme];
  document.body.style.color = Colors.theme_c_dbw[store.theme];
  // validatoin schema
  const ErrorSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(2, "too Short")
      .max(30, "Too Long"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string()
      .matches(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        "Not a Phone number"
      )
      .required("Phone is required"),
  });

  useEffect(() => {
    const ac = new AbortController();
    return () => ac.abort();
  }, []);

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'red',
      },

      '& .MuiInput-underline:after': {
        borderBottomColor: 'red',
      },
      '& .MuiOutlinedInput-input': {
        borderRadius: "7px",
        backgroundColor: Colors.grey,
        borderColor: Colors.inputBorder,
      },
    },
  })(TextField);

  const WhiteThemeTextareaAutosize = styled(TextareaAutosize)({

    color: "#202124",
    backgroundColor: Colors.grey,
    borderColor: Colors.inputBorder,
    borderRadius: '7px',

  })
  const DarkThemeTextareaAutosize = styled(TextareaAutosize)({

    color: 'white',
    border: '1px solid #303134',
    borderRadius: '3px',
    backgroundColor: '#303134',

  })
  const DarkThemeTextField = styled(TextField)({

    '& .MuiFormLabel-root': {
      color: 'white',

    },
    '& .MuiOutlinedInput-notchedOutline': {
      backgroundColor: '#303134',
    },
    '&.Mui-focused': {
      color: 'red'
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#303134',
      backgroundColor: '#303134'

    },
  });


  const WhiteThemeTextField = styled(TextField)({

    '& .MuiFormLabel-root': {
      color: "#202124"
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    }

  });

  return (
    <Observer>
      {() => (
        <>
          

          <Grid
            container
            spacing={0}
            justify="center"
            alignItems="center"
            direction="column"

          ></Grid>
          <Container component="main" maxWidth="sm" className="pt-5  align-middle" style={{
            color: Colors.theme_c_dbw[store.theme],
            backgroundColor: Colors.theme_bg_wb[store.theme]
          }}>
            <div className="col-12 col-lg-12 p-4 text-center" >
              <img src={logo} className="main_logo img-fluid img-responsive  " />
            </div>
            <h1 className="font-weight-bold text-center pt-4"
              style={
                store.langIndex == 0
                  ? { fontFamily: "Raleway, sans-serif" }
                  : store.langIndex == 1
                    ? { fontFamily: "JameelNoori" }
                    : { fontFamily: "Arabic" }
              }
            >
              Join Team of Contributors
            </h1>

            <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
              }}
              onSubmit={(value, { resetForm }) => {

                console.log(value);
                // dispatch(contactAction(value.name, value.email, value.phone, value.subject, value.description));
                // dispatch(loadingToggleAction(true));
                window.open(`https://api.whatsapp.com/send/?phone=923007711148&text=Joining%20Team%20of%20Contributors:%20Name:${value.name},%20Email:${value.email},%20WhatsApp:${value.phone}`, '_blank');
                resetForm();
              }}
              validationSchema={ErrorSchema}
              component={({ errors, touched }) =>
                <Form>
                  <div className="form-group py-3">
                    <div className="input-group">
                      <Field name="name" style={{ fontFamily: "Raleway, sans-serif" }} className={touched.name ? `form-control py-2 ${errors.name ? "invalid" : "valid"}` : `form-control py-2`} placeholder="Enter your name" type="text" />
                    </div>
                    {touched.name && errors.name && (<small className="text-danger">{errors.name}</small>)}
                  </div>

                  <div className="form-group py-3">
                    <div className="input-group">
                      <Field name="email" style={{ fontFamily: "Raleway, sans-serif" }} className={touched.email ? `form-control py-2 ${errors.email ? "invalid" : "valid"}` : `form-control py-2`} placeholder="Your Email Id" type="text" />
                    </div>
                    {touched.email && errors.email && (<small className="text-danger">{errors.email}</small>)}
                  </div>

                  <div className="form-group py-3">
                    <div className="input-group">
                      <Field name="phone" style={{ fontFamily: "Raleway, sans-serif" }} className={touched.phone ? `form-control py-2 ${errors.phone ? "invalid" : "valid"}` : `form-control`} placeholder="Enter your phone" type="text" />
                    </div>
                    {touched.phone && errors.phone && (<small className="text-danger">{errors.phone}</small>)}
                  </div>
                  <div className="text-center">
                    <button name="submit" style={{ fontFamily: "Raleway, sans-serif" }} type="submit" variant="contained" value="Submit" className="mt-2  btn p-2 m-auto btn-radius bg-danger text-light fw-bold">Submit Now</button>
                  </div>
                </Form>}
            />

          </Container>
        </>
      )
      }
    </Observer >
  );
};

export default Joining;
