import React, { useState, useContext,useEffect } from "react";
import { Form, Formik } from "formik";
import { Observer } from "mobx-react-lite";
import { StoreContext } from "../../store/store";
import { Button, Container, Snackbar } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TextTranslation from "../../Config/TextTranslation";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import Colors from "../../Config/Colors";
import MuiAlert from "@material-ui/lab/Alert";
import * as yup from "yup";
import axios from "axios";

//alert function
const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const EditProfile = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const store = useContext(StoreContext);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState("");

  let editProfileSchema = yup.object().shape({
    username: yup
      .string()
      .min(3, TextTranslation.inp_min_user_name_msg[store.langIndex]),
    cellno: yup
      .string()
      .min(12, TextTranslation.inp_min_new_cell_no_msg[store.langIndex])
      .max(14, TextTranslation.inp_max_new_cell_no_msg[store.langIndex]),
  });

  // alert close function
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    // setOpen1(false);
  };
  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
  }, []);

  return (
    <Observer>
      {() => (
        <>
          <CommonNavbar />
          <Container maxWidth="xs" className="pt-5">
            {/* edit profile alert Snackbar */}
            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={success !== 0 ? "error" : "success"}
              >
                {success !== 0
                  ? "Invalid name/phone no"
                  : "profile updated succesfully"}
              </Alert>
            </Snackbar>
            <h4
              className="text-center"
              style={
                store.langIndex == 0
                  ? { fontFamily: "Raleway, sans-serif", color: Colors.primary }
                  : store.langIndex == 1
                  ? { fontFamily: "JameelNoori", color: Colors.primary }
                  : { fontFamily: "AlQalam", color: Colors.primary }
              }
            >
              {TextTranslation.personal_info[store.langIndex]}
            </h4>
            <Formik
              initialValues={{ username: "", cellno: "" }}
              validationSchema={editProfileSchema}
              onSubmit={(values) => {
                console.log("user_name", values.username);
                console.log("cell_no", values.cellno);

                var editProfileformData = new FormData();
                editProfileformData.append("name", values.username);
                editProfileformData.append("cellno", values.cellno);

                setLoad(true);

                axios({
                  method: "post",
                  url: "https://api.minhaj.digital/edit_profile.php?",
                  data: editProfileformData,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    uuid: localStorage.getItem("@uuid"),
                  },
                })
                  .then((res) => {
                    //handle success
                    console.log(res.data);
                    if (res.data.MDE_API[0].success !== 0) {
                      //alert("Successfuly Updated");
                      setOpen(true);
                      setSuccess(0);
                      setLoad(false);
                    } else {
                      setOpen(true);
                      setSuccess(1);
                      setLoad(false);
                      //alert("Not Updated");
                    }
                  })
                  .catch((res) => {
                    //handle error
                    console.log(res);
                  });
              }}
            >
              {({ errors, handleChange, touched, handleBlur, isValid }) => (
                <Form>
                  {store.langIndex == 0 ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      className="mb-2"
                      label={
                        TextTranslation.inp_enter_new_name[store.langIndex]
                      }
                      name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.username && touched.username
                          ? errors.username
                          : null
                      }
                      error={errors.username && touched.username}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "AlQalam",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "AlQalam",
                                fontSize: 16,
                              },
                            }
                      }
                      variant="outlined"
                      className="mb-2"
                      placeholder={
                        TextTranslation.inp_enter_new_name[store.langIndex]
                      }
                      name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.username && touched.username
                          ? errors.username
                          : null
                      }
                      error={errors.username && touched.username}
                    />
                  )}
                  {store.langIndex == 0 ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="cellno"
                      label={
                        TextTranslation.inp_enter_new_cell_no[store.langIndex]
                      }
                      className="mb-2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.cellno && touched.cellno ? errors.cellno : null
                      }
                      error={errors.cellno && touched.cellno}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      inputProps={
                        store.langIndex == 1
                          ? {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "JameelNoori",
                                fontSize: 18,
                              },
                            }
                          : {
                              min: 0,
                              style: {
                                textAlign: "right",
                                color: Colors.primary,
                                fontFamily: "AlQalam",
                                fontSize: 18,
                              },
                            }
                      }
                      FormHelperTextProps={
                        store.langIndex == 1
                          ? {
                              style: {
                                textAlign: "right",
                                fontFamily: "JameelNoori",
                                fontSize: 16,
                              },
                            }
                          : {
                              style: {
                                textAlign: "right",
                                fontFamily: "AlQalam",
                                fontSize: 16,
                              },
                            }
                      }
                      variant="outlined"
                      name="cellno"
                      placeholder={
                        TextTranslation.inp_enter_new_cell_no[store.langIndex]
                      }
                      className="mb-2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.cellno && touched.cellno ? errors.cellno : null
                      }
                      error={errors.cellno && touched.cellno}
                    />
                  )}
                  <Button
                    className="mt-3 btn"
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                    }}
                    disabled={!isValid}
                  >
                    {load ? (
                      <CircularProgress
                        size={30}
                        style={{ color: Colors.white }}
                      />
                    ) : (
                      <span
                        style={
                          store.langIndex == 0
                            ? { fontFamily: "Raleway,sans-serif" }
                            : store.langIndex == 1
                            ? { fontFamily: "JameelNoori", fontSize: 18 }
                            : { fontFamily: "AlQalam", fontSize: 18 }
                        }
                      >
                        {TextTranslation.edit_profile[store.langIndex]}
                      </span>
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </Container>
        </>
      )}
    </Observer>
  );
};

export default EditProfile;
