import React, {useContext} from 'react'
import { decode } from 'html-entities';
import { Observer } from 'mobx-react-lite';
import { StoreContext } from '../store/store';
import Colors from '../Config/Colors';

const HighlightText = ({simpleText, colorText, styles}) => {

    const store = useContext(StoreContext)

    const getHighlightedText =(text, highlight)=> {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span> { parts.map((part, i) => 
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase()  ? { color : Colors.primary, 
            fontFamily: store.langIndex == 0 ? 'Raleway, sans-serif' : store.langIndex == 1 ? 'JameelNoori' : 'Arabic',
            fontSize: store.langIndex == 0 ? 16 : store.langIndex == 1 ? 18 : 18, ...styles
        } : 
            {fontFamily: store.langIndex == 0 ? 'Raleway, sans-serif' : store.langIndex == 1 ? 'JameelNoori' : 'Arabic',
            fontSize: store.langIndex == 0 ? 16 : store.langIndex == 1 ? 18 : 18, ...styles
            } }>
                { decode(part) }
            </span>)
        } </span>;
    }

    return (
        <Observer>
            {
                ()=>(
                    <div>{getHighlightedText(simpleText , colorText)}</div>
                )
            }
        </Observer>
    )
}
export default HighlightText