import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
const Survey = () => {
  // useDocumentTitle('Syeu');

  useEffect(() => {
    
    document.title = "Minhaj Islamic Encyclopedia - App Survey"
  }, [])

  return (
    <>
      <Helmet>
        <script src="https://embed-cdn.surveyhero.com/js/user/embed.43pxxb7q.js" async></script>
      </Helmet>

      <div id="surveyhero-embed-43pxxb7q"></div>
    </>


  )
}


export default Survey