import React from "react";
import { useLocalObservable } from "mobx-react-lite";
import { action } from "mobx";

export const StoreContext = React.createContext();

let temp = localStorage.getItem("@langIndex");
temp === null
  ? localStorage.setItem("@langIndex", 0)
  : localStorage.setItem("@langIndex", temp);

export const StoreProvider = (props) => {
  const store = useLocalObservable(() => ({
    // logged-in user id
    id: "",
    setId: action((e) => (store.id = e)),

    //search
    smallSearch: "",
    setSmallSearch: action((e) => (store.smallSearch = e)),

    //locParam
    locParam: "Quran",
    setlocParam: action((e) => (store.locParam = e)),
    

    // quran tab counter
    result_counter: 0,
    setResultCounter: action((e) => (store.result_counter = e)),

    //theme white and dark
    theme:0,
    setTheme:action((e)=>(store.theme = e)),

    // quran synonym counter
    syn_counter: 0,
    setSynCounter: action((e) => (store.syn_counter = e)),

    //speech tab counter
    speech_counter: 0,
    setSpeechCounter: action((e) => (store.speech_counter = e)),

    //book tab counter
    book_counter: 0,
    setBookCounter: action((e) => (store.book_counter = e)),

    // article tab counter
    article_counter: 0,
    setArticleCounter: action((e) => (store.article_counter = e)),

    // personal info
    personalInfo: [],
    setPersonalInfo: action((e) => store.personalInfo.push(e)),

    // search
    searchValue: "",
    setSearchValue: action((e) => (store.searchValue = e)),

    // linear loader
    linearLoader: false,
    setLinearLoader: action((e) => (store.linearLoader = e)),

    // language
    langIndex: localStorage.getItem("@langIndex"),
    setLangIndex: action((e) => (store.langIndex = e)),

    // did you mean
    didYouMeanExist: false,
    setDidYouMeanExist: action((e) => (store.didYouMeanExist = e)),

    // change did you mean
    changeDidYouMean: "",
    setChangeDidYouMean: action((e) => (store.changeDidYouMean = e)),

    // did you mean boolean
    didYouMeanBool: 0,
    setDidYouMeanBool: action((e)=> (store.didYouMeanBool = e)),

    //Overlay
    overlay: localStorage.getItem("@overlay"),
    // overlay:false,
    setOverlay: action((e) => (store.overlay = e)),

    // public article title
    publicArticleTitle: "",
    setPublicArticleTitle: action((e) => (store.publicArticleTitle = e)),

    // bookmark search title
    bookmarkSearchTitle: "",
    setBookmarkSearchTitle: action((e) => (store.bookmarkSearchTitle = e)),

    // for gmail
    forgEmail: null,
    setForgEmail: action((e) => (store.forgEmail = e)),

    // for quran title
    dataArr: [],
    setDataArr: action((e) => (store.dataArr = e)),

    // for from size of quran title
    from: 0,
    setFrom: action((e) => (store.from = e)),

    //for book highlighted text
    bookSentence: "",
    setBookSentence: action((e) => (store.bookSentence = e)),

   
  }));

  return (
    <StoreContext.Provider value={store}>
      {props.children}
    </StoreContext.Provider>
  );
};
