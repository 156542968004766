import React, { useContext,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Observer } from "mobx-react-lite";
import { StoreContext } from "../../store/store";
import Colors from "../../Config/Colors";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import TextTranslation from "../../Config/TextTranslation";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import QuranBookmarkTitle from "./QuranBookmarkTitle";
import SpeechBookmarkTitle from "./SpeechBookmarkTitle";
import AyatBookmarkTitle from './AyatBookmarkTitle';

;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabs: {
    backgroundColor: Colors.white,
    color: Colors.primary,
    [theme.breakpoints.up("lg")]: {
      paddingTop: "1.5%",
    },
    [theme.breakpoints.down("lg")]: {
      paddingTop: "2.5%",
    },
  },
  englishfont: {
    fontFamily: "Raleway, sans-serif",
    fontSize: 14,
  },
  urdufont: {
    fontFamily: "JameelNoori",
    fontSize: 16,
  },
  arabicfont: {
    fontFamily: "Arabic",
    fontSize: 16,
  },
}));

const UserBookmarksMain = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const classes = useStyles();
  const store = useContext(StoreContext);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    
  }, []);

  return (
    <Observer>
      {() => (
        <>
          <CommonNavbar />

          <TabContext value={value} style={{ margin: 0 }}>
            {store.langIndex == 0 ? (
              <AppBar position="static">
                <TabList
                  onChange={handleChange}
                  className={classes.tabs}
                  centered
                >
                  <Tab
                    label={
                      <div
                        className={
                          store.langIndex == 0
                            ? classes.englishfont
                            : store.langIndex == 1
                            ? classes.urdufont
                            : classes.arabicfont
                        }
                      >
                        {TextTranslation.quran[store.langIndex]}
                      </div>
                    }
                    value="1"
                  />
                  <Tab
                    label={
                      <div
                        className={
                          store.langIndex == 0
                            ? classes.englishfont
                            : store.langIndex == 1
                            ? classes.urdufont
                            : classes.arabicfont
                        }
                      >
                        {TextTranslation.speeches[store.langIndex]}
                      </div>
                    }
                    value="2"
                  />
                    <Tab
                    label={
                      <div
                        className={
                          store.langIndex == 0
                            ? classes.englishfont
                            : store.langIndex == 1
                            ? classes.urdufont
                            : classes.arabicfont
                        }
                      >
                        ayat
                        {/* {TextTranslation.quran[store.langIndex]} */}
                      </div>
                    }
                    value="3"
                  />
                </TabList>
              </AppBar>
            ) : (
              <AppBar position="static">
                <TabList
                  onChange={handleChange}
                  className={classes.tabs}
                  centered
                >
                  <Tab
                    label={
                      <div
                        className={
                          store.langIndex == 0
                            ? classes.englishfont
                            : store.langIndex == 1
                            ? classes.urdufont
                            : classes.arabicfont
                        }
                      >
                        {TextTranslation.speeches[store.langIndex]}
                      </div>
                    }
                    value="2"
                  />
                  <Tab
                    label={
                      <div
                        className={
                          store.langIndex == 0
                            ? classes.englishfont
                            : store.langIndex == 1
                            ? classes.urdufont
                            : classes.arabicfont
                        }
                      >
                        {TextTranslation.quran[store.langIndex]}
                      </div>
                    }
                    value="1"
                  />
                  <Tab
                    label={
                      <div
                        className={
                          store.langIndex == 0
                            ? classes.englishfont
                            : store.langIndex == 1
                            ? classes.urdufont
                            : classes.arabicfont
                        }
                      >
                        ayat
                        {/* {TextTranslation.speeches[store.langIndex]} */}
                      </div>
                    }
                    value="3"
                  />
                </TabList>
              </AppBar>
            )}
            <TabPanel value="1">
              <QuranBookmarkTitle />
            </TabPanel>
            <TabPanel value="2">
              <SpeechBookmarkTitle />
            </TabPanel>
            <TabPanel value="3">
              <AyatBookmarkTitle />
            </TabPanel>
          </TabContext>
        </>
      )}
    </Observer>
  );
};

export default UserBookmarksMain;
