import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Snackbar,
} from "@material-ui/core";
import { useParams,useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../../../store/store";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextTranslation from "../../../../Config/TextTranslation";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import BookmarkSharpIcon from "@material-ui/icons/BookmarkSharp";
import Colors from "../../../../Config/Colors";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import EngText from "../../../../CustomComponents/EngText";
import UrduText from "../../../../CustomComponents/UrduText";
import ArabicText from "../../../../CustomComponents/ArabicText";
import HighlightText from "../../../../CustomComponents/HighlightText";

import "./Speeches.css"
import Url from "../../../../Config/Url";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 350,
  },
  loader: {
    color: Colors.primary,
    alignItems: "center",
  },
  icon: {
    fontSize: 20,
    "&:hover": {
      color: Colors.primary,
      cursor: "pointer",
    },
  },
}));

const Speeches = ({ searchValue, speechCounter }) => {
  let history = useHistory();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const getParamFromMain = useParams();
  const [speech, setSpeech] = useState([]); // speech title state
  const [loader, setLoader] = useState(true); // loader state
  const [loadMore, setLoadMore] = useState(); // show more state
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [from, setFrom] = useState(0);
  const [videoId, setVideoId] = useState([]); // video id state
  const [startTime, setStartTime] = useState([]); // video start time state
// console.log('getParamFromMain',getParamFromMain);
  // copy to clipboard function
  const CopyData = (title, video_id, id, starttime) => {
    return `https://minhaj.digital/selectedresult/Speech/${title}/${video_id}/${id}/${parseInt(
      starttime
    )}`;
  };

  // alert function
  const Alert = (props) => (
    <MuiAlert elevation={6} variant="filled" {...props} />
  );

  


  // alert close function
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {

      return;
    }
    setOpen(false);
  };

  // get speeches API
  const getSpeeches = () => {
    fetch(
      "https://e17f2c0260644627914ab12ab4053b29.us-east-1.aws.found.io:9243/encyclopedia_transcripts/_search",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa("elastic:fEvbnlzQag9r6Bgcd2VuXXHf"),
        },
        body: JSON.stringify({
          from: from,
          size: 10,
          query: {
            query_string: {
              query: "*" + searchValue + "*",
            },
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("Video Result", res);
        console.log("from", from);
        {
          res.hits.total.value > 10 ? setLoadMore(true) : setLoadMore(false);
        }
        store.setSpeechCounter(res.hits.total.value);
        setSpeech([...speech, ...res.hits.hits]);
        setFrom(from + 10);
        setLoader(false);
      });
  };

  // get speech bookmark
  const getBookmarkedData = () => {
    if (store.id == null) {
      setStartTime([]);
      setVideoId([]);
    } else {
      var data = new FormData();
      data.append("title", store.searchValue);
      data.append("lang_id", store.langIndex);
      axios({
        method: "post",
        url: `${Url.link}/get_speeches_bookmarks_for_result.php`,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          uuid: store.id,
        },
      })
        .then((response) => {
          console.log(
            "get bookmark data console",
            response.data.MDE_API[0].MSG
          );
          setStartTime(response.data.MDE_API[0].MSG.start_time);
          setVideoId(response.data.MDE_API[0].MSG.video_id);
        })
        .catch((error) => {
          console.log("history" + error);
        });
    }
  };

  // post speech api
  const postSpeech = (video_id, start_time) => {
    if (store.id == null) {
      return history.push("/login");
    } else {
      var data = new FormData();
      data.append("title", store.searchValue);
      data.append("video_id", video_id);
      data.append("start_time", start_time);
      data.append("lang_id", store.langIndex);

      axios({
        method: "post",
        url: "https://api.minhaj.digital/store_speeches_bookmark.php",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          uuid: store.id,
        },
      })
        .then((response) => {
          if (response.data.MDE_API[0].success == 0) {
            alert("already bookmarked");
            getBookmarkedData();
            getSpeeches();
          } else {
            alert("bookmarked successfully");
            getBookmarkedData();
            getSpeeches();
          }
        })
        .catch((error) => {
          console.log("history" + error);
        });
    }
  };

  // delete bookmark api
  const deleteBookmark = (video_id, start_time) => {
    var data = new FormData();
    data.append("title", store.searchValue);
    data.append("video_id", video_id);
    data.append("start_time", start_time);
    data.append("lang_id", store.langIndex);

    axios({
      method: "post",
      url: "https://api.minhaj.digital/delete_speeches_bookmark.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data", uuid: store.id },
    })
      .then((response) => {
        if (response.data.MDE_API[0].success == 1) {
          //alert("deleted successfully");
          window.confirm("Are you sure to delete?");
          getBookmarkedData();
          getSpeeches();
        } else {
          alert("Error while deleting bookmark !");
          getBookmarkedData();
          getSpeeches();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    getSpeeches();
    getBookmarkedData();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className="text-center">
      {!loader ? (
        <>
          {speech.length < 1 ? (
            <div className="text-center mt-5 pt-5">
              {store.langIndex == 0 ? (
                <EngText styles={{ textAlign: "center" }}>
                  <HighlightText
                    simpleText={`${TextTranslation.no_resultTab_found} "${searchValue}" ${TextTranslation.speech_resultEnd}`}
                    colorText={searchValue}
                  />
                </EngText>
              ) : store.langIndex == 1 ? (
                <UrduText styles={{ textAlign: "center" }}>
                  <HighlightText
                    simpleText={`آپ کے تلاش کردہ لفظ "${searchValue}" کے مطابق "خطابات سیکشن" میں کوئی نتائج نہیں ملے`}
                    colorText={searchValue}
                  />
                </UrduText>
              ) : (
                <ArabicText styles={{ textAlign: "center" }}>
                  <HighlightText
                    simpleText={`لا توجد نتائج مقابل كلمة "${searchValue}" من "خطابات"`}
                    colorText={searchValue}
                  />
                </ArabicText>
              )}
            </div>
          ) : (
            <>
              <div
                style={{
                  textAlign: "center",
                  paddingBottom: 10,
                }}
              >
                {store.langIndex == 0 ? (
                  <EngText styles={{ textAlign: "center" }}>
                    <HighlightText
                      simpleText={`${speechCounter} ${TextTranslation.resultStart} "${searchValue}" ${TextTranslation.speech_resultEnd}`}
                      colorText={searchValue}
                    />
                  </EngText>
                ) : store.langIndex == 1 ? (
                  <UrduText styles={{ textAlign: "center" }}>
                    <HighlightText
                      simpleText={`آپ کے تلاش کردہ لفظ "${searchValue}" کے مطابق "خطابات سیکشن" میں ${speechCounter} نتائج ملے ہیں `}
                      colorText={searchValue}
                    />
                  </UrduText>
                ) : (
                  <ArabicText styles={{ textAlign: "center" }}>
                    <HighlightText
                      simpleText={`تم العثور على ${speechCounter} نتيجة مقابل كلمة "${searchValue}" من "خطابات"`}
                      colorText={searchValue}
                    />
                  </ArabicText>
                )}
              </div>

              <Grid container justify="center">
                <Snackbar
                  open={open}
                  autoHideDuration={1000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity="success">
                    copied
                  </Alert>
                </Snackbar>

                {speech.map((data, index) => (
                  <Grid
                    item
                    xs={10}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={3}
                    className="mb-4 d-flex"
                    key={index}
                    style={{ justifyContent: "space-around" }}
                  >
                    <Card className={classes.root}>
                      <CardActionArea key={index}>
                        <CardMedia
                          component="iframe"
                          alt=""
                          height="200"
                          image={`https://www.youtube.com/embed/${
                            data._source.youtube_id
                          }?start=${parseInt(data._source.start_time)}`}
                          title=""
                          allowFullScreen={true}
                        />

                        <CardContent>
                          <a
                            href={`/selectedresult/Speech/${
                              data._source.video_title
                            }/${data._source.video_id}/${parseInt(
                              data._source.start_time)}/${data._source.youtube_id}`}
                            target="_blank"
                          >
                            <Typography
                              gutterBottom
                              variant="caption"
                              component="h2"
                         >
                              {data._source.video_title}
                            </Typography>
                          </a>
                        </CardContent>
                      </CardActionArea>

                      <CardActions
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* copy to clipboard */}
                        <CopyToClipboard
                          text={CopyData(
                            data._source.video_title,
                            data._source.video_id,
                            data._source.youtube_id,
                            data._source.start_time
                          )}
                          onCopy={() => {
                            setCopied(true);
                            setOpen(true);
                          }}
                        >
                          <FileCopyOutlinedIcon className={classes.icon} />
                        </CopyToClipboard>

                        {/* bookmark icon */}
                        {startTime.includes(data._source.start_time) &&
                        videoId.includes(data._source.video_id) ? (
                          <BookmarkSharpIcon
                            className={classes.icon}
                            style={{ color: Colors.primary }}
                            onClick={() =>
                              deleteBookmark(
                                data._source.video_id,
                                data._source.start_time
                              )
                            }
                          />
                        ) : (
                          <BookmarkBorderIcon
                            className={classes.icon}
                            onClick={() =>
                              postSpeech(
                                data._source.video_id,
                                data._source.start_time
                              )
                            }
                          />
                        )}
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {loadMore == false ? null : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <button
                onClick={() => {
                  getSpeeches();
                  setFrom(from + 10);
                }}
                style={{
                  backgroundColor: Colors.primary,
                  color: Colors.white,
                  border: "none",
                  borderRadius: "5px",
                  height: 40,
                }}
              >
                <div
                  style={
                    store.langIndex == 0
                      ? { fontFamily: "Raleway, sans-serif" }
                      : store.langIndex == 1
                      ? { fontFamily: "JameelNoori", fontSize: 18 }
                      : { fontFamily: "AlQalam", fontSize: 18 }
                  }
                >
                  {TextTranslation.show_more[store.langIndex]}
                </div>
              </button>
            </div>
          )}
        </>
      ) : (
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          style={{ minHeight: "100vh" }}
        >
          <CircularProgress
            className={classes.loader}
            size={50}
            thickness={3}
          />
        </Grid>
      )}
    </div>
  );
};

export default Speeches;
