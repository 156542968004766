import { Grid, Card, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect, useContext} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../store/store";
import { Observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import Colors from "../../Config/Colors";
import axios from "axios";
import lodash from "lodash";
import "./QuranBookmarkTitle.css";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";
import EngText from "../../CustomComponents/EngText";
import UrduText from "../../CustomComponents/UrduText";
import ArabicText from "../../CustomComponents/ArabicText";
import Url from "../../Config/Url";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 245,
  },
  loader: {
    color: Colors.primary,
  },
}));

const QuranBookmarkTitle = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let history = useHistory();
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [title, setTitle] = useState([]);
  const [loader, setLoader] = useState(true);

  const postBookmarkTitle = () => {
    axios({
      method: "post",
      url: `${Url.link}/get_bookmarks_multiref.php`,
      headers: {
        "Content-Type": "multipart/form-data",
        uuid: localStorage.getItem("@uuid"),
      },
    }).then((res) => {
      setTitle(res.data.MDE_API[0].MSG);
      setLoader(false);
    });
  };
  let data = lodash.groupBy(title, "search_title");
  

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href= 'https://download.minhaj.app/'; 
    }
    const ac = new AbortController();
    postBookmarkTitle();
    return () => ac.abort();
  }, []);

  return (
    <Observer>
      {() => (
        <>
      
          {loader ? (
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              directioin="column"
              style={{ minHeight: "80vh" }}
            >
              <Grid>
                <CircularProgress
                  className={classes.loader}
                  size={50}
                  thickness={3}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center" justify="center">
              <Grid item xs={10} sm={10} md={10} lg={7} xl={6}>
                <div className="text-right mb-2">
                  <EngText styles={{textAlign: 'right'}}>
                    Total Bookmarked Topics: {Object.keys(data).length}
                  </EngText>
                </div>
                {Object.keys(data).map((key, index) => (
                  <Card className="bookmark-card mb-2" key={index} raised={true}
                  onClick={() => {
                      history.push(`/quranbookmark/result/${key}`);
                      store.setBookmarkSearchTitle(key);
                    }}>
                      {
                       data[key][0].lang_id == 1 ? 
                       <EngText styles={{margin: "15px 10px"}}>{key}</EngText>
                       : data[key][0].lang_id == 2 ?
                       <UrduText styles={{margin: "15px 10px"}}>{key}</UrduText> 
                       : <ArabicText styles={{margin: "15px 10px"}}>{key}</ArabicText>
                      }
                      </Card>                
                ))}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Observer>
  );
};

export default QuranBookmarkTitle;
