import React from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import CommonNavbar from "../../Navbars/CommonNavbar/CommonNavbar";

const PageNotFound = () => {
  return (
    <div>
      <CommonNavbar />
      {/* if you enter wrong URL then it's go to this page */}
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        style={{ minHeight: "80vh" }}
      >
        <Grid items>
          <h3 className="text-center">Page Not Found !</h3>
          <h6 className="text-center">May be you entered wrong url</h6>
          <Link to="/">
            <div className="text-center">
              <small>go to home</small>
            </div>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageNotFound;
