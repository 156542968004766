import React from "react";
import { decode } from "html-entities";

var persianDigits = "۰۱۲۳۴۵۶۷۸۹";
var persianMap = persianDigits.split("");

const QuranicText = ({ children, ayat, styles }) => {
  return (
    <div>
      <h4 style={{ fontFamily: "AlQalam", textAlign:'right', lineHeight:1.5, ...styles }}>
        {decode(children)
        .replace("<img src=https://minhaj.digital/beta.version/en/images/waqf-lazim_mim_sm.png>",
          decode("&#x6D8"))
        .replace("<img src=http://www.irfan-ul-quran.com/quran/images/waqf-lazim_mim_sm.png>",
          decode("&#x6D8"))
          .replace(
              "&lt;img src=&quot;http://www.irfan-ul-quran.com/quran/images/waqf-lazim_mim_sm.png&quot; /&gt;",
              decode("&#x6D8")
            )
          }
          &nbsp;
          <span style={{fontFamily: "AlQalam1"}}>
            {decode("&#1757;") + ayat
            .toString()
            .replace(/\d/g, function (m) {
                return persianMap[parseInt(m)];
              })
              }
          </span>
      </h4>
    </div>
  );
};
export default QuranicText;
